import { BasketService, HttpClient } from 'pz-core';
import AddToCartButton from '../button-add-to-cart';
import store from "shop-packages/redux/store";
import {
  setBasket,
} from "shop-packages/redux/basket/reducer";

const trans = {
  addToCart: gettext('Sepete Ekle')
};

export default class Action {
  constructor($card, productItem) {
    this.productItem = productItem;
    this.$card = $card;
    this.$action = $card.querySelector('.js-action');
    this.$quantity = $card.querySelector('.product-amount__quantity');
    this.data = null;
    this.isSelectBeden = false;
    this.dimensions = null;
    this.query = new URLSearchParams();
    this.render();
  }



  render() {
    this.$action.innerHTML = `
      <div class="action__container">
        <div class="action__trigger">
          <div class="action__loading js-action-loading"></div>
        </div>
        <div class="action__content js-action-content">
        </div>
      </div>
    `;

    this.$card?.setAttribute('data-render', true);

    this.$card?.addEventListener('mouseover', () => {
      const isDataFetch = this.$card.getAttribute('data-fetch') !== 'true';

      if (isDataFetch) {
        !this.query.has(this.$card.dataset.key) && this.query.set(this.$card.dataset.key, this.$card.dataset.value);
        this.$card.setAttribute('data-fetch', true);
        this.fetch();
      }
    });
  }

  renderContent() {
    const content = [];
    const variants = [];
    this.dimensions = this.data.variants?.filter(
      (x) => ['integration_beden1'].includes(x.attribute_key)
    );

    this.dimensions.forEach((variant) => {
      const options = variant.options;

      if (options.length > 0) {
        variants.push({
          key: variant.attribute_key,
          label: variant.attribute_name,
          options
        });
      }
    });

    const $dimensions = variants.map((variant) => `
      <div class="variant">
        <div class="variant__options">
          ${variant.options.map((option) => {
            const classList = [];

            classList.push('variant__option');
            classList.push('js-variant-option');

            if (!option.is_selectable) {
              classList.push('-disabled');
            }

            if (option.is_selected) {
              classList.push('-selected');
            }

            return `
              <div class="${classList.join(' ')} ${!option.is_selected && 'js-add-to-cart'}" data-quantity="1" data-product="${option.product.pk}" data-sku="${option.product.sku}" data-key="${variant.key}" data-value="${option.value}">
                ${option.label}
              </div>
            `;
          }).join('')}
        </div>
      </div>
    `).join('');

    content.push($dimensions);
    if (window.innerWidth > 768 || this.dimensions.length === 0) {

      content.push(`
        <div class="add-to-cart js-desktop-add-to-cart action__content" data-product="${this.data.product.pk}" data-sku="${this.data.product.sku}" data-quantity="1">
          Sepete Ekle
        </div>
      `);
    }

    content.push(`
        <div class="action__error js-action-error">
        </div>
    `);
    this.dimensions.map((dimension) => {
      dimension.options.map((option) => {
        if (option.is_selected) {
          this.isSelectBeden = true;
          window.innerWidth < 768 && this.$quantity.classList.add('show-amount');
        }
      })
    })
    if ((window.innerWidth < 768) || (this.isSelectBeden || this.dimensions.length === 0)) {
      this.renderQuantityElement();
    }
    this.$action.querySelector('.js-action-content').innerHTML = content.join('');
    

    this.$action.querySelectorAll('.js-variant-option:not(.-disabled)').forEach(($option) => {
      $option.addEventListener('click', () => {
        if ($option.classList.contains('-selected')) {
          this.query.delete($option.dataset.key);
        } else {
          this.query.set($option.dataset.key, $option.dataset.value);
        }

        this.fetch();
      });
    });

    this.quantityAction();
    
    if (this.dimensions.length !== 0) {
      if (this.isSelectBeden) {
        this.handleAddToCartClick();
      }
      else {
        window.innerWidth < 768 && this.$quantity.classList.remove('show-amount');
      }
    }
    else {
      this.handleAddToCartClick();
    }

    if (window.innerWidth < 768) {
      this.dimensions.length !== 0 && this.handleAddToCartClick();

      $('.js-desktop-add-to-cart').each((_, el) => {
        el.addEventListener('click', () => {
          this.$quantity.classList.add('show-amount');
        })
      })

    }

    
  }

  handleAddToCartClick = async () => {

    if (window.innerWidth < 768 && this.dimensions.length !== 0) {
    const $addToCart = $('.js-add-to-cart');
      $addToCart.each((_, el) => {
        AddToCartButton($(el));
      })
    }
    else  {
      const $addToCart = $('.js-desktop-add-to-cart');
        $addToCart.each((_, el) => {
          AddToCartButton($(el));
        })
      }
  }


  showError(message) {
    const $error = this.$action.querySelector('.js-action-error');

    $error.innerHTML = message;
    this.$action.classList.add('-has-error');
  }

  renderQuantityElement() {
    let quantityVal = 1;
      $('.dory-item-quantity').each((_, item) => {
        if (item.dataset.pk == this.data.product.pk){
              quantityVal = item.dataset.quantity;
            }
      })
      this.$quantity.innerHTML = `
            <button class="js-product-amount-item-quantity-decrease-button">
              <i class="icon icon-minus"></i>
              </button>
            <div class="js-product-amount-item-quantity" data-pk="${this.data.product.pk}">
              ${window.innerWidth < 768 ? quantityVal : 1}
            </div>
            <button class="js-product-amount-item-quantity-increment-button">
              <i class="icon icon-plus"></i>
            </button>
      `;
  }

  quantityAction() {
    if ( window.innerWidth < 768) {
      const $increment = this.$quantity.querySelector('.js-product-amount-item-quantity-increment-button');
      $increment.addEventListener('click', async () => {
        $('.dory-item-quantity').each((_, item) => {
            const {quantity, pk} = item.dataset;
            if (quantity < this.data.product.stock && pk == this.data.product.pk){
                  const value = +quantity + 1;
                  this.quantityChange({pk, value}, this.data.product.attributes, $(item), $($increment))
                }
          })
        });
        const $decrease = this.$quantity.querySelector('.js-product-amount-item-quantity-decrease-button');
        $decrease.addEventListener('click', () => {
          $('.dory-item-quantity').each((_, item) => {
            const {quantity, pk} = item.dataset;
            if (quantity < this.data.product.stock && pk == this.data.product.pk){
                  const value = +quantity - 1;
                  this.quantityChange({pk, value}, this.data.product.attributes, $(item), $($decrease))
                }
          })
        });
    }
    else {
      if (this.isSelectBeden || this.dimensions.length === 0) {
        const $data = $('.add-to-cart');
        this.$quantity.querySelector('.js-product-amount-item-quantity-increment-button').addEventListener('click', () => {
          const $quantity = this.$quantity.querySelector('.js-product-amount-item-quantity');
          const quantity = parseInt($quantity.innerHTML);
          $data.each((_, item) => {
              if (quantity < this.data.product.stock) {
                $quantity.innerHTML = quantity + 1;
                    if (item.dataset.sku === this.data.product.sku){
                      item.dataset.quantity = quantity + 1;
                    }
                }
          })
        });
        this.$quantity.querySelector('.js-product-amount-item-quantity-decrease-button').addEventListener('click', () => {
          const $quantity = this.$quantity.querySelector('.js-product-amount-item-quantity');
          const quantity = parseInt($quantity.innerHTML);
          $data.each((_, item) => {
            if (quantity > 1) {
              $quantity.innerHTML = quantity - 1;
                  if (item.dataset.sku === this.data.product.sku){
                    item.dataset.quantity = quantity - 1;
                  }
              }
          })
        });
      }
    }
  }

  async quantityChange({ pk, value }, attributes, $item, $actionButton) {
    try {
        $actionButton.addClass('loading pz-button__action');
    
        if ( value < 1 ) {
          $actionButton.removeClass('loading pz-button__acition');
          throw new Error('En az 1 adet ürün seçmelisiniz.');
        }
        await BasketService.setQuantity({ product: pk, quantity: value }, attributes).then((response) => {
          store.dispatch(setBasket(response.data));
        });

    
        $item.attr('data-quantity', value);
        $actionButton.removeClass('loading pz-button__acition');
        this.$quantity.querySelector('.js-product-amount-item-quantity').innerHTML = value;
  
    } catch (error) {
        console.error(error);
    }
  }



  async fetch() {
    this.$action.classList.remove('-has-error');
    this.$action.classList.remove('-ready');
    this.$action.querySelector('.js-action-loading').innerHTML = '<span class="pz-button__action loading"></span>';

    const params = this.query.toString();

    const url = `${this.$card.dataset.url}${params? `?${params}` : ''}`;

    const { data } = await HttpClient.get(`${url}`);

    this.data = data;

    this.renderContent();

    this.$action.classList.add('-ready');
  }
}