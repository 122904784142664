import { ApiClient } from 'shop-packages';

import accordion from '../components/accordion';
import * as actionTypes from "shop-packages/analytics/action-types";
import { pushEvent } from "shop-packages/analytics";
import { mobile, setAnalyticsProductToStorage } from '../utils';
import '../assets/img/mobile-filter.png';
import AddToCartButton from '../components/button-add-to-cart';
import ProductItem from '../components/product-item';

export default class Listing {
  constructor() {
    this.container = $(document.getElementById('ListPage'));
    this.$filterText = this.container.find('.js-filter-input-text');
    this.selectedFilters = [];
    this.selectedSorter = '';
    this.selectedGrid = '';
    this.page = parseInt(this.getParams().page) || 1;
    this.totalPage = $('.js-infinite-pagination').data('total');
    this.fetchPass = true;
    this.init();
    this.infiniteScroll();
    this.onClickReadMore();
    this.timeStamp = 0;

    this.container.find('.js-product-wrapper').click(e => {
      const productPk = $(e.currentTarget).data("pk");
      this.pushAnalyticsData( productPk , actionTypes.productClicked);
    });

    window.onpopstate = history.onpushstate = () => {
      this.fetch(location.pathname+location.search);
    }
  }

  init() {
    this.initCollectionBannerSliders();

    new ProductItem();
    this.addToCart();
    this.totalPage = $('.js-infinite-pagination').data('total');

    this.observeProducts();
    this.$filterText.text().trim() === '' && this.$filterText.text('Filtreler');
    this.productsWrapper = $(document.getElementById('ListProductWrapper'));
    this.listProductFavouriteGuestElem = $('.js-product-item-favourite-guest');
    $('body').removeClass('overflow-hidden');

    this.listProductFavouriteGuestElem.on('click', this.listFavouriteGuestAction.bind(this));

    accordion();

    this.getSelected();

    $('.js-fast-buy').on('click', (e) => {
      e.preventDefault();
      e.stopPropagation();

    });

    document.addEventListener('click', (e) => {
      const targetElement = e.target;
      const isClickedProduct = targetElement.closest('.js-product-wrapper');
      if (!isClickedProduct) {
        const allFastElements = document.querySelectorAll('.js-add-fast-buy-mobile');
        allFastElements.forEach((el) => {
          el.classList.remove('-hidden');
          const fastBuyMobile = el.closest('.js-fast-buy');
          fastBuyMobile && (fastBuyMobile.classList.remove('-active'))
          fastBuyMobile.querySelector('.js-fast-buy-sizes') && (fastBuyMobile.querySelector('.js-fast-buy-sizes').classList.remove('-active')); 
        });
      }
    });

    document.querySelectorAll('.js-add-fast-buy-mobile').forEach((el) => {
      el.addEventListener('click', (e) => {
        el.classList.add('-hidden');
        const fastBuyMobile = el.closest('.js-fast-buy');
        fastBuyMobile && (fastBuyMobile.classList.add('-active'))
        fastBuyMobile.querySelector('.js-fast-buy-sizes') && (fastBuyMobile.querySelector('.js-fast-buy-sizes').classList.add('-active')); 
      });
    });

    this.$sorterMobile = $(document.getElementById('ListSorterMobileContainer'));
    $(document.getElementById('ListSorterMobileButton')).click(() => {
      this.showMobileSorter();
    });

    $(document.getElementById('ListSorterMobileCloser')).click(() => {
      this.hideMobileSorter();
    });

    $(document.getElementsByClassName('js-clear-filters')).click(() => {
      this.clearFilters();
    });

    this.$filtersMobileContainer = $(document.getElementById('ListFacetsMobileContainer'));
    this.$filtersMobileForm = $(document.getElementById('ListFacetsForm'));
    this.$filtersMobileForm.submit(this.applyFilters.bind(this));

    $(document.getElementById('ListFiltersForm')).submit(this.applyFilters.bind(this));

    $(document.getElementById('ListFacetsButton')).click(() => {
      this.showMobileFilters();
    });

    $(document.getElementById('ListFacetsApplyButton')).click(() => {
      this.$filtersMobileForm.submit();
    });

    $(document.getElementsByClassName('js-filter-back')).click(() => {
      $('#ListFacetsMobileContainer').removeClass('m-open-sub-filters');
      $('.js-mobile-facet-wrapper').removeClass('active');
      $('.js-filter-big-title').text('Filtreler');
    });
    
    $(document.getElementsByClassName('js-remove-filter')).click((e) => {
      const parent = e.target.parentElement;
      const name = parent.getAttribute('data-name');
      const value = parent.getAttribute('data-value');
      this.removeFilter(name, value);
    });
    
    $(document.getElementById('ListFacetsMobileCloser')).click(() => {
      this.hideMobileFilters();
    });

    $('.dropdown-menu').on('click.bs.dropdown', (e) => {
      e.stopPropagation();
    });

    $(document).on('click', '.js-mobile-facet-wrapper', function () {
      $('#ListFacetsMobileContainer').addClass('m-open-sub-filters');
      $('.js-mobile-facet-wrapper').removeClass('active');
      
      var $this = $(this);
      $this.addClass('active');
      const filterTitle = $this.find('.js-filter-title').attr('data-key');
      filterTitle && $('.js-filter-big-title').text(filterTitle);
    });

    $(document).on('click', '.js-checkbox', function (e) {
      var $this = $(this);
      if (e.target.checked) {
        $this.addClass('selected');
      }else {
        $this.removeClass('selected');
      }
      const selectedSubFilter = $('#ListFacetsForm').find('.active').find('.selected');
      selectedSubFilter && $('.js-filter-apply-button').text(selectedSubFilter.length ? selectedSubFilter.length + ' Filtreyi Uygula' : 'Filtreyi Uygula');
    });

    $(document.getElementById('ListMobileFiltersClearButton')).click(() => {
      const item = $('#ListFacetsMobileContainer').find('.active');
      if ( item.find('.selected')) {
        item.find('.selected').removeClass('selected');
      }
    });
    
    this.container.find('.js-category-choice').click(e => {
      e.preventDefault();

      const $el = $(e.currentTarget);
      const href = $el.attr('href');
      this.page = 1;
      history.pushState(null, document.title, href);
      this.fetch(href);
    });

    $(document.getElementsByClassName('js-analytic-data-filter')).click((e) => {
      this.pushAnalyticsFilter(e);
    });

    this.fetchPass = true;

    this.container.find('.js-sorter').change(e => {
      const value = $(e.currentTarget).val();

      if (value === 'default') {
        this.selectedSorter = '';
      } else {
        this.selectedSorter = value;
      }

      this.page = 1;

      this.fetchProducts();
      this.hideMobileSorter();
    });

    this.selectedGrid = this.container.find('.js-grid-item.active').attr('data-grid');

    this.scrollTop();
    this.searchResultVlEvent();
  }

  initCollectionBannerSliders() {
   $(".collection--banners").lightSlider({
      item: 6,
      loop:false,
      pager: false,
      slideMargin:1.5,
      responsive: [
        {
          breakpoint: 1300,
          settings: {
            item:4.5,
          }
        },
        {
          breakpoint: 1024,
          settings: {
            item:3.5,
          }
        },
        {
          breakpoint: 768,
          settings: {
            item: 3.5,
          }
        }
      ]
    });
  }

  addToCart() {
    const $addToCart = this.container.find('.js-add-to-cart');

    if (!$addToCart.length) {
      return;
    }
    $addToCart.each((_, el) => {
      const addToCartButton = AddToCartButton($(el));

      addToCartButton.success = (response) => {
      const { data : { basket } = {} } = response;

      if(this.mobile){
        $('.js-mini-basket-mobile').fadeIn('500');
      }else{
        $('.js-mini-basket-full-screen-content').css('display', 'block');
      }
      
      const htmlBodySelector = $('html,body');
      htmlBodySelector.addClass('overflow-hidden');

      $('.analytics-data').each(function() {
        const analyticsData = JSON.parse($(this).text());
        if (!analyticsData.type) return;
        if(analyticsData.type === actionTypes.productViewed){
          const productData = {
            type : actionTypes.productAdded ,
            payload : {
              products : [{
                ...analyticsData.payload.products[0],
                quantity : 1,
                cart_id : basket.pk
              }]
            }
          };
          pushEvent(productData)
        }
      });

      let cartProducts = [] ;
      basket.basketitem_set.forEach((item) => {
        let obj = {
          cart_id: basket.pk,
          itemgroup_id: item.product.attributes.integration_renk_kodu && item.product.attributes.integration_beden1 ? item.product.base_code + item.product.attributes.integration_renk_kodu + item.product.attributes.integration_beden1 : item.product.base_code,
          productPK: item.product.pk,
          sku: item.product.sku,
          quantity: item.quantity,
          price: item.product.price,
          variantid: item.product.attributes.filterable_renk
        };
        cartProducts.push(obj)
      });

      const cartDetailData = {
        type : actionTypes.cartDetailEvent,
        payload : cartProducts
      };

      pushEvent(cartDetailData);

      try {
        const productsWrapper = $(el).closest('.product-item');
        const productPk = $(el).data('product');
        const productAnalyticsData = JSON.parse(productsWrapper.find('.js-product-wrapper-analytics').text());
        productAnalyticsData.index = Number(productsWrapper.data('index'));
        productAnalyticsData.price = +productAnalyticsData.price;
        productAnalyticsData.discount = +productAnalyticsData.discount;

        window.dataLayer.push({
          event: 'add_to_cart',
          ecommerce:{ currency: "TRY", value:productAnalyticsData.item_last_price, items:[productAnalyticsData] }
        });

        const data = {
          pk:productPk,
          idx:productAnalyticsData.index,
          basecode:productAnalyticsData.item_id,
          listIdx:productAnalyticsData.item_list_id,
          listName:productAnalyticsData.item_list_name
        };
        setAnalyticsProductToStorage(data);
      } catch (error) {
        console.log(error)
      }
    };
  });

    const $mobilBasketPopupClose = $('.js-mini-basket-mobile-continue');
    $mobilBasketPopupClose.on('click', () => {
      const htmlBodySelector = $('html,body');

      htmlBodySelector.removeClass('overflow-hidden');
      $('.js-mini-basket-mobile').fadeOut('500');
    });
  }

  observeProducts() {
    const products = document.querySelectorAll('.js-product-wrapper');
    const options = {
      rootMargin: '0px',
      threshold: 0.2,
    };
    const observer = new IntersectionObserver((entries) => {
      let count = 0;
      entries.forEach(entry => {
        if (entry.isIntersecting && !entry.target.dataset.isObserved) {
          entry.target.dataset.isObserved = true;
          const productsArray = Array.from(products);
          const start = productsArray.indexOf(entry.target);
          const end = start + 4;
          const items = productsArray.slice(start, end).map((product) => {
            const analyticsProduct = product.querySelector('.js-product-wrapper-analytics');
            const data = JSON.parse(analyticsProduct.textContent);
            data.index = +product.dataset.index;
            data.discount = +data.discount;
            data.price = +data.price;
            return data;
          });
          if (count % 4 == 0) {
            window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push({
              'event': 'view_item_list',
              'ecommerce': {
                'item_list_id':items[0].item_list_id,
                'item_list_name':items[0].item_list_name,
                items
              },
            });
          }
          count++;
        }
      });
    }, options);
    products.forEach(product => {
      observer.observe(product);
      product.addEventListener('click', () => {
        const index = product.dataset.index;
        const basecode = product.dataset.basecode;
        const pk = product.dataset.pk;
        const item_list_id = product.dataset.listurl;
        const item_list_name = product.dataset.listname;
        const data = {
          pk,
          index,
          basecode,
          listIdx:item_list_id,
          listName:item_list_name
        };
        const analyticsProducts = JSON.parse(localStorage.getItem('analyticsProducts')) || [];
        
        const existingIndex = analyticsProducts && analyticsProducts.findIndex(product => product.basecode == basecode);
        if (existingIndex > -1) {
          analyticsProducts.splice(existingIndex, 1, data);
        } else {
          analyticsProducts.push(data);
        }
        localStorage.setItem('analyticsProducts', JSON.stringify(analyticsProducts));

        const itemObj = JSON.parse(product.querySelector(".js-product-wrapper-analytics").innerHTML);
        const items = [Object.fromEntries(Object.entries(itemObj))];
        items[0].index = +index;
        items[0].price = +items[0].price;
        items[0].discount = +items[0].discount;
          window.dataLayer.push({ ecommerce: null });
          window.dataLayer.push({
            'event': 'select_item',
            'ecommerce': {
              'item_list_id':items[0].item_list_id,
              'item_list_name':items[0].item_list_name,
              items
            },
        });
        setAnalyticsProductToStorage(items[0]);
      });
    });
  }

  listFavouriteGuestAction(e){
    e.preventDefault();
    e.stopPropagation();

    let url = $(e.target).attr('data-url');

    window.location.href = url;
  }


  onClickReadMore(){
    let $readMore = $('.js-list-description-text-wrapper .js-read-text');
    let $readLess = $('.js-list-description-text-wrapper .js-read-less');
    let $readText = $('.js-list-description-text-wrapper .js-list__description__text');
    let $readTextSpecial = $('.js-list-description-text-wrapper .js-list__description__text-special');
    
    $('.js-list-description-text-wrapper .js-read-more').click(function(e){
      e.preventDefault();
      $readTextSpecial.toggleClass('show');
      $readText.toggleClass('show');
      $readMore.toggleClass('show');
      $readLess.toggleClass('show');
    })    
  }

  showMobileSorter() {
    $('html, body').stop().scrollTop(0);
    this.$sorterMobile.show();
    $('body').addClass('overflow-hidden');
  }

  hideMobileSorter() {
    this.$sorterMobile.hide();
    $('body').removeClass('overflow-hidden');
  }
  
  showMobileFilters() {
    $('html, body').stop().scrollTop(0);
    this.$filtersMobileContainer.show();
    $('body').addClass('overflow-hidden');
  }

  hideMobileFilters() {
    this.$filtersMobileContainer.hide();
    this.$filtersMobileForm.trigger('reset');
    $('body').removeClass('overflow-hidden');
  }

  
  addFilter(name, value) {
    this.selectedFilters.push({ name, value });
    this.page = 1;
    this.fetchProducts();
  }


  pushAnalyticsData(productPk , actionType){
    $('.analytics-data').each(function() {
      const analyticsData = JSON.parse($(this).text());
      if (!analyticsData.type) return;
      if (analyticsData.type === actionTypes.productListViewed){
        const products = analyticsData.payload;
        $.each( products , function(index , value){
          for(const key in value){
            if (productPk == value[key]){
              const analyticsData = {
                type : actionType,
                payload : {
                  products : [value]
                }
              };
              pushEvent(analyticsData);
              return;
            }
          }
        })
      }
    });
  }
  
  pushAnalyticsFilter(item) {
    window.dataLayer.push({
      event: 'gtm.Filter',
      category: 'Filter',
      label: item.target.value,
      action: item.target.attributes.facet.value
    });
  }

  pushAnalyticsSorter(item) {
    window.dataLayer.push({
      event: 'gtm.Sorting',
      category: 'Sorting',
      action: item
    });
  }


  removeFilter(name, value) {
    this.selectedFilters = this.selectedFilters.filter(selectedFilter => {
      return !(selectedFilter.name === name && selectedFilter.value === value)
    });
    this.page = 1;
    this.fetchProducts(1);
  }

  getSelected() {
    this.selectedFilters = [];
    this.selectedSorter = '';

    this.container.find('.js-filter-choice:checked').each((_, el) => {
      const $el = $(el);
      $el.parents('.accordion-item').addClass('active');
      const name = $el.attr('name');
      const value = $el.val();

      this.selectedFilters.push({ name, value });
    });

    const sorterValue = this.container.find('.js-sorter:checked').val();
    if (sorterValue !== 'default') {
      this.selectedSorter = sorterValue;
    }
  }

  clearFilters() {
    this.selectedFilters = [];
    this.page = 1;
    this.fetchProducts(1);
  }

  applyFilters(e) {
    e.preventDefault();
    const form = $(e.target);

    this.selectedFilters = form.serializeArray();
    this.page = 1;
    this.fetchProducts(1);
  }

  getQuery(options) {
    let params = [];
    let query;
    const searchText = this.getParams().search_text;

    if (location.pathname === '/list/') {
      const selectedCategory = this.container.find('.js-category-choice[data-checked=true]');

      if (selectedCategory.length) {
        const categoryData = selectedCategory.first().data();
        params.push(
          $.param({[categoryData.name]: categoryData.value})
        )
      }
    }

    for (let filter of this.selectedFilters) {
      params.push(
        $.param({[filter.name]: filter.value})
      );
    }

    if (this.selectedSorter) {
      params.push(
        $.param({sorter: this.selectedSorter})
      );
      this.pushAnalyticsSorter(this.selectedSorter);
    }

    if (this.selectedGrid) {
      params.push(
        $.param({grid: this.selectedGrid})
      );
    }

    if (this.page && this.page !== 1) {
      if (options?.page) {
        params.push(
          $.param({page: options.page})
        );
      } else {
        params.push(
          $.param({page: this.page})
        );
      }
    }

    if(searchText !== undefined && searchText !== ''){
      query = !params.length ?
      location.pathname :
      `${location.pathname}?search_text=${searchText}&${params.join('&')}`;
    }else{
      query = !params.length ?
      location.pathname :
      `${location.pathname}?${params.join('&')}`;
    }

    return query;
  }

  fetchProducts(e) {
    const query = this.getQuery();

    history.pushState(null, document.title, query);
    this.fetch(query, e);
  }

  async infiniteFetch(query, mode) {
    const response = await ApiClient.misc.fetchPageHtml(query);

    if (mode == 'prev') {
      $('.js-pagination-prev').removeClass('loading');
    } else {
      $('.js-infinite-pagination').removeClass('loading');
    }

    const productCount = $('.js-product-wrapper').length;
    const result = $(response).find('#ListProductWrapper').html();

    if (mode == 'prev') {
      $(result).find('.js-infinite-pagination').remove();
      this.container.find('#ListProductWrapper').prepend(result);
    } else {
      this.container.find('#ListProductWrapper').append(result);
    }

    const event = new CustomEvent('productListPush', {detail: $(response).find('.analytics-data')});
    document.querySelector('#ListProductWrapper').dispatchEvent(event);

    this.container.before($(response).find('.js-base-content .analytics-data'));
    this.container.find('.js-product-wrapper')
      .slice((mode == 'prev' ? 0 : productCount), (mode == 'prev' ? productCount : this.container.find('.js-product-wrapper').length))
      .click(e => {
        const productPk = $(e.currentTarget).data("pk");
        this.pushAnalyticsData( productPk , actionTypes.productClicked);
    });

    this.init();
  }

  async fetch(query, e) {
    const response = await ApiClient.misc.fetchPageHtml(query);

    const result = $(response).find('#ListPage').html();
    this.container.html(result);
    this.init();

    if( e === 1 && mobile() && this.selectedFilters.length ) {
      this.showMobileFilters();
    }
  }

  scrollTop() {
    const scrollTopButton = $('.js-scroll-top-button');
    window.onscroll = function() {scrollFunction()};
    
    function scrollFunction() {
      if (document.body.scrollTop > 40 || document.documentElement.scrollTop > 40) {
        scrollTopButton.removeClass('invisible');
        scrollTopButton.addClass('visible');
      } else {
        scrollTopButton.addClass('invisible');
        scrollTopButton.removeClass('visible');
      }
    }

    scrollTopButton.on('click', function() {
      window.scrollTo({top: 0, behavior: 'smooth'});
    });
    
  }

  infiniteScroll() {
    const prevbtn = $('.js-prev-page button');

    if (this.page <= 1) {
      prevbtn.hide();
    }

    prevbtn.on('click', () => {
      if (this.page > 1) {
        const prev = prevbtn.data('page');
        const href = this.getQuery({page: prev});

        if (prev > 1) {
          prevbtn.data('page', prev - 1);
        } else {
          prevbtn.hide();
        }

        $('.js-pagination-prev').addClass('loading');
        this.infiniteFetch(href, 'prev');
      }
    });
    
    $(window).on('scroll', () => {
      if (!this.timeStamp > 0) {
        this.timeStamp++;
        return false;
      }
      
      const pagination = document.querySelector('.js-infinite-pagination');
      const list = document.getElementById('ListProductWrapper'); 
      if (pagination && this.fetchPass && this.elementInViewport(list) && this.viewportArea(pagination, 2000) ) {
        this.fetchPass = false;
        if (this.page + 1 <= this.totalPage && this.totalPage > 1) {

          if(this.selectedFilters.length > 0){
            const fetchedDataElements = Array.from(list.children)
            if (fetchedDataElements[fetchedDataElements.length - 1].classList.contains('list__empty__product')) {
              return false;
            }
          }
          if(mobile()) {
            this.page++
            pagination.classList.add('loading');
            const href = this.getQuery();
            this.infiniteFetch(href);
            return false;
          }

          this.page = (parseInt(this.getParams().page) || 1) + 1;
          pagination.classList.add('loading');
          const href = this.getQuery();
          history.pushState(null, document.title, href);
          this.infiniteFetch(href);
        }   
      }
    });
  }

  viewportArea(elem) {
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();

    var elemTop = $(elem).offset().top;
    var elemBottom = elemTop + $(elem).height();

    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
  }

  elementInViewport(el) {
    const rect = el.getBoundingClientRect();
    const height = el.offsetHeight;
    const width = el.offsetWidth;

    if (rect.top >= -height 
      && rect.left >= -width
      && rect.right <= (window.innerWidth || document.documentElement.clientWidth) + width
      && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) + height) {
        return true;
    }

    return false;
  }

  getParams() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return Object.fromEntries(urlSearchParams.entries());
  }

  searchResultVlEvent() {
    const totalCount = document.querySelector('.js-total-count').getAttribute('data-total-count');
    const searchResult = this.getParams().search_text;
    window.dataLayer.push({ 
      event: "vlEvent", 
      vl_label: "VL-SearchResultView", 
      vl_search: { 
        word: searchResult,
        count: totalCount ? totalCount : 0
      }})
  }
}