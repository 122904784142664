import $ from 'jquery';
import { thumbnail } from 'shop-packages/utils';

export default function anonymousOrder() {
  /* eslint-disable indent */
  $('.js-order-tracking-form').on('submit', function (e) {
    e.preventDefault();
    const emailVal = $('.js-input-email').val();
    const orderNoVal = $('.js-input-order-no').val();

    const endpoint = `/orders/anonymous/`;
    fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify({
        "number": orderNoVal,
        "email": emailVal
      }),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': $(window.GLOBALS.csrf_token).val()
      }
    })
      .then(ret=>ret.json())
      .then(response => {
        if (response.length > 0) {
          $('.js-order-tracking-error').text('');
          $('.page-order-tracking').css('display', 'none');
          $('js-page-order-tracking-detail').css('display', 'initial');

          var template = `<div class="account-content" id="orderDetailWrapper" data-id="{{ data.id }}">
                <h2 class="order-detail__title font-weight-bold text-center text-sm-left mb-5 mb-sm-3">
                ${gettext('Sipariş Takibi')}
                </h2>`;
          var date;

          for (var item in response) {
            date = response[item].created_date.split('T')[0];
            var splitDate = date.slice(8, 10) + '.' + date.slice(5, 7) + '.' + date.slice(0, 4);
            var orderItemCount = response[item].orderitem_set.length;
            template += `
                    <div class="order-detail__top">
                    <div class="order-timeline__icon d-flex justify-content-between mb-2 mx-auto mt-sm-4 overflow-hidden">
                        <span class="${response[item].status.value >= '400' ? 'active' : ''}"></span>
                        <span class="${response[item].status.value >= '500' ? 'active' : ''}"></span>
                        <span class="${response[item].status.value >= '550' ? 'active' : ''}"></span>
                        </div>
                        <ol class="order-timeline d-flex justify-content-between align-items-center px-3 mb-4 mx-sm-auto">
                            <li class="${response[item].status.value >= '400' ? 'active' : ''}">
                                ${gettext('Sipariş Alındı')}
                            </li>
                            <li class="${response[item].status.value >= '500' ? 'active' : ''}">
                                ${gettext('Gönderildi')}
                            </li>
                            <li class="${response[item].status.value >= '550' ? 'active' : ''}">
                                ${gettext('Teslim Edildi')}
                            </li>
                        </ol>
                        
                        <div class="${response[item].tracking_url ? 'button-wrapper mx-auto' : 'd-none'}">
                            <a
                            class="button d-flex justify-content-center align-items-center"
                            target="_blank"
                            href="${response[item].tracking_url ? response[item].tracking_url : ''}"
                            >
                            ${gettext('Kargoyu Takip Et')}
                            </a>
                        </div>
                        <span class="${response[item].tracking_url ? 'button-border d-block w-100' : 'd-none'}"></span>
                        </div>
                        <div class="order-detail__return-code font-weight-bold">Mng Kargo Ücretsiz İade Kodunuz: <span>322060190</span></div>
                        <div class="d-sm-flex pt-sm-4">
                            <div class="order-detail__mid d-sm-flex">
                            <div class="order-detail__mid--container js-order-tab">
                                <a
                                href="#"
                                class="order-detail__mid__title d-flex align-items-center justify-content-between py-4 font-weight-bold text-reset"
                                data-title="detail"
                                >
                                ${gettext('Sipariş Detayı')}
                                <i class="icon-chevron_down d-sm-none"></i>
                                </a>
    
                                <div class="order-detail__mid__content d-none d-sm-block" data-content="detail">
                                <p class="mb-1">
                                    ${gettext('Sipariş Tarihi')} :
                                </p>
                                <p class="semi-bold">
                                    ${splitDate}
                                </p>
    
                                <p class="mb-1">
                                    ${gettext('Sipariş No')} :
                                </p>
                                <p class="semi-bold">
                                    ${response[item].number}
                                </p>
    
                                <p class="mb-1">
                                    ${gettext('Ürün Adedi')} :
                                </p>
                                <p class="semi-bold">

                                    ${orderItemCount}
                                </p>
    
                                <p class="mb-1">
                                    ${gettext('Tutar')} :
                                </p>
                                <p class="semi-bold">
                                    ${response[item].amount} ${response[item].currency.label}
                                </p>
                                </div>
                            </div>
    
                            <div class="order-detail__mid--container pr-sm-5 js-order-tab">
                                <a
                                href="#"
                                class="order-detail__mid__title d-flex justify-content-between align-items-center py-4 font-weight-bold text-reset"
                                data-title="address"
                                >
                                    ${gettext('Adres Bilgileri')}
                                <i class="icon-chevron_down d-sm-none"></i>
                                </a>
    
                                <div class="order-detail__mid__content d-none d-sm-block" data-content="address">
                                <p class="mb-1">
                                    ${gettext('Gönderim Adresi')} :
                                </p>
    
                                <p class="semi-bold mb-0">
                                    ${response[item].shipping_address.first_name} ${response[item].shipping_address.last_name}
                                </p>
    
                                <p class="semi-bold">
                                    ${response[item].shipping_address.line} 
                                    ${response[item].shipping_address.district ? response[item].shipping_address.district.name : ''}
                                    ${response[item].shipping_address.township ? response[item].shipping_address.township.name : ''} 
                                    ${response[item].shipping_address.city ? response[item].shipping_address.city.name : ''}
                                </p>
    
                                <p class="mb-1">
                                    ${gettext('Fatura Adresi')} :
                                </p>
    
                                <p class="semi-bold mb-0">
                                    ${response[item].shipping_address.first_name} ${response[item].shipping_address.last_name}
                                </p>
    
                                <p class="semi-bold">
                                    ${response[item].billing_address.line} 
                                    ${response[item].billing_address.district ? response[item].billing_address.district.name : ''}
                                    ${response[item].billing_address.township ? response[item].billing_address.township.name : ''} 
                                    ${response[item].billing_address.city ? response[item].billing_address.city.name : ''}
                                </p>
                                </div>
                            </div>
                            </div>
    
                            <div class="order-detail__bottom">
                            <h3 class="order-detail__bottom__title d-flex justify-content-between align-items-end font-weight-bold py-4 mb-0">
                                    ${gettext('Aldığınız Ürünler')}
                                <span class="font-weight-normal">
                                    ${orderItemCount} 
                                    ${gettext('Adet')}
                                </span>
                            </h3>`;
            for (var order_item in response[item].orderitem_set) {
              template += `<div class="order-detail__bottom__content">
                                    <div class="d-flex order-item pb-2 mb-2">
                                        <img loading="lazy" src="${thumbnail(response[item].orderitem_set[order_item].product.image, 'basket-list')}" 
                                        class="mr-2">
    
                                        <div>
                                        <p class="mb-0 order-item__name">
                                            ${response[item].orderitem_set[order_item].product ?
                  response[item].orderitem_set[order_item].product.name : ''}
                                        </p>
    
                                        <p class="${response[item].orderitem_set[order_item].product.attributes.integration_color ? 'mb-0' : 'd-none'}">
                                        ${gettext('Renk')} : 
                                        ${response[item].orderitem_set[order_item].product.attributes ?
                  response[item].orderitem_set[order_item].product.attributes.integration_color : ''}
                                        </p>
    
                                        <p class="${response[item].orderitem_set[order_item].product.attributes.integration_size ? 'mb-0' : 'd-none'}">
                                        ${gettext('Beden')} : 
                                        ${response[item].orderitem_set[order_item].product.attributes ?
                  response[item].orderitem_set[order_item].product.attributes.integration_size : ''}
                                        </p>
    
                                        <p class="mb-0 order-item__code">
                                            ${gettext('Ürün kodu')} : 
                                            ${response[item].orderitem_set[order_item].product ?
                  response[item].orderitem_set[order_item].product.base_code : ''}
                                        </p>
                                        </div>
    
                                    </div>`;
            }
            template += `
                                    <div class="order-item__subtotal pb-2 mb-2">
                                    <div class="w-100 d-flex justify-content-between">
                                        <span>
                                            ${gettext('Ürünlerin Toplamı')}
                                        </span>
    
                                        <span>
                                            ${response[item].amount_without_discount} ${response[item].currency.label}
                                        </span>
                                    </div>`;
            if (response[item].discountitem_set) {
              for (var discount in response[item].discountitem_set) {
                template += `
                                    <div class="w-100 d-flex justify-content-between">
                                    <span>
                                        ${response[item].discountitem_set[discount].name}
                                    </span>
    
                                    <span>
                                        - ${response[item].discountitem_set[discount].amount} ${response[item].discountitem_set[discount].currency.label}
                                    </span>
                                    </div>
                                    `;
              }
              template += `
                            </div>
    
                                    <div class="w-100 d-flex justify-content-between mb-4 order-item__total">
                                    <span>
                                        ${gettext('TOPLAM')}
                                    </span>
    
                                    <span>
                                        ${response[item].amount} ${response[item].currency.label}
                                    </span>
                                    </div>
                                </div>
                                </div>
                            </div>`;
            }
          }
          $('.js-order-tracking-detail').html(template);

          tabMenu();
        } else {
            if (response.non_field_errors) {
                $('.js-order-tracking-error').text(response.non_field_errors[0]);
            } else {
                $('.js-order-tracking-error').text(gettext('Üyeliğiniz mevcut olduğu için üyelik girişi yaptıktan sonra Hesabım - Siparişlerim kısmından siparişinizin durumunu kontrol edebilirsiniz.'));
            }
        }
      })
      .catch(() => {
            $('.js-order-tracking-error').text(gettext('Bir hata oluştu. Lütfen sonra tekrar deneyiniz.'));
      })
  });
}