class Element {
  constructor($element) {
    this.$element = $element;
  }

  setEvent(eventName, fn) {
    this.$element.on(eventName, fn);
  }

  removeEvent(eventName) {
    this.$element.off(eventName);
  }
}

export default Element;