import { ApiClient } from 'shop-packages';
import ActionForm from '../../components/form-action';

export default class ChangePassword {
  constructor(){
    this.$formContainer = $(document.getElementById('changePasswordFormWrapper'));
    this.$form = this.$formContainer.find('.js-change-password-form');
    this.$formMessage = this.$formContainer.find('.js-form-message');

    const form = ActionForm(this.$form);

    form.onError = err => {
      form.showErrors(err);
    };

    this.$form.submit(this.onFormSubmit.bind(this));
  }

  showPasswordError(errorJSON) {

    if(errorJSON.old_password && errorJSON.old_password[0].indexOf('boş') < 0) {
      $('p.old-password-error').text(errorJSON.old_password[0]);
      $('p.old-password-error').removeClass('d-none');
    }
    if(errorJSON.new_password1 && errorJSON.new_password1[0].indexOf('boş') < 0) {
      $('p.new-password1-error').text(errorJSON.new_password1[0]);
      $('p.new-password1-error').removeClass('d-none');
    }
    if(errorJSON.new_password2 && errorJSON.new_password2[0].indexOf('boş') < 0) {
      $('p.new-password2-error').text(errorJSON.new_password2[0]);
      $('p.new-password2-error').removeClass('d-none');
    }
  }

  async onFormSubmit(e) {
    e.preventDefault();

    $('.password-input-wrapper p').text('');
    const formData = $(e.target).serializeArray();
    const passwordValues = formData.map(({value}) => value);
    try {
      await ApiClient.user.changePassword(...passwordValues);
      this.$formMessage.removeClass('d-none');
      setTimeout(() => this.$formMessage.addClass('d-none'), 3000);

    } catch (error) {
      this.showPasswordError(error.responseJSON)
    }
  }
};