import {mobile} from "../../utils";

class Blog {
  constructor() {
    this.blogEventListers();

    if (mobile()){
      this.blogMenuToggle()
    }
  }

  blogMenuToggle = () => {
    const title = $('.js-blog-title');
    const body = $('.js-blog-body');

    title.on('click' , function () {
      body.toggleClass('d-none')
    })
  };

  /**
   * Blog pagination
   */
  blogGoToPreviousPage() {
    const $element = $('.card-blog');
    const current = Number($('.pages > .current').data('now'));
    const show = Number($('.blog__pagination').data('show'));

    if (current <= 1) {
      return false;
    }

    $element
      .removeClass('active')
      .filter(function () {
        return (
          current - 1  !== 1 ?
            $(this).data('item') >= (current - 1) * show
            && $(this).data('item') < current * show
            : $(this).data('item') <= (current - 1) * show
        );
      })
      .addClass('active');

    $('.pages > .current')
      .data('now', current - 1)
      .attr('data-now', current - 1)
      .text(current - 1);

    $('html, body').animate({
      scrollTop: 100,
    });

    return false;
  }

  blogGoToNextPage() {
    const $element = $('.card-blog');
    const current = Number($('.pages > .current').data('now'));
    const show = Number($('.blog__pagination').data('show'));

    if (current >= Number($('.blog__pagination').data('max'))) {
      return false;
    }

    $element
      .removeClass('active')
      .filter(function () {
        return (
          $(this).data('item') > current * show
          && $(this).data('item') <= (current + 1) * show
        );
      })
      .addClass('active');

    $('.pages > .current')
      .data('now', current + 1)
      .attr('data-now', current + 1)
      .text(current + 1);

    $('html, body').animate({
      scrollTop: 100,
    });

    return false;
  }

  blogEventListers() {
    $('.blog__pagination .previous > a').on('click', this.blogGoToPreviousPage);
    $('.blog__pagination .next > a').on('click',this.blogGoToNextPage);
  }

}

export default Blog;