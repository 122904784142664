import {
  ApiClient
} from 'shop-packages';
import {
  fetchFavouriteStatus
} from 'shop-packages/api/favourites';

class ProductItem {
  constructor() {
    this.init();
  }

  init() {
    this.addClickEventToFavouriteButtons();
    this.addHoverEffectToProductImages();
    if (window.GLOBALS.userLoggedIn && !window.GLOBALS.page?.includes('product')) {
      this.showFavourites();
    }
  }

  addClickEventToFavouriteButtons() {
    $('.js-add-to-favourites').off('click');
    $('.js-add-to-favourites').on('click', function (e) {
      e.preventDefault();
      e.stopPropagation();
      const productDetailFavouriteBtn = document.getElementById('js-product-detail-favourite');
      const btn = productDetailFavouriteBtn ? $('.js-add-to-favourites') : $(this);
      const product = btn.closest('.js-product-wrapper');

      if (window.GLOBALS.userLoggedIn) {
        if (btn.hasClass('active')) {
          ApiClient.favorites.removeFavourite(product.attr('data-favourite-pk')).then(() => {
            product.removeAttr('data-favourite-pk');
            btn.removeClass('active');
          }).catch(() => {
          })
        } else {
          ApiClient.favorites.addFavourite(product.data('pk')).then((response) => {
              if (response.data.product && response.data) {
                const favoritePk = response.data.pk;
                product.attr('data-favourite-pk', favoritePk);
                btn.addClass('active');
                try {
                  const productsWrapper = $(e.target).closest('.product-item');
                  const productAnalyticsData = JSON.parse(productsWrapper.find('.js-product-wrapper-analytics').text());
                  productAnalyticsData.index = +productsWrapper.data('index');
                  productAnalyticsData.discount = +productAnalyticsData.discount;
                  productAnalyticsData.price = +productAnalyticsData.price;

                  window.dataLayer.push({
                    event: 'add_to_wishlist',
                    ecommerce: {
                      currency: "TRY",
                      value: productAnalyticsData.item_last_price,
                      items: [productAnalyticsData]
                    }
                  });
                } catch (error) {
                  //
                }
              }
            }).catch(() => {
            });
        }
      } else {
        window.location.href = `/users/login/?next=${window.location.pathname}`;
      }
    });
  }

  showFavourites() {
    const products = document.querySelectorAll('.js-product-wrapper:not([data-favourite-pk])');

    const productsPk = Array.from(products).map((productElement) => {
      const productPk = productElement.getAttribute('data-pk');

      productElement.setAttribute('data-favourite-pk', productPk);

      return productPk;
    });

    !window.GLOBALS.page?.includes('favourties') && productsPk.length &&
      fetchFavouriteStatus(productsPk).then((response) => {

        if (response.data.results?.length) {
          response.data.results.forEach((item) => {
            $('.js-add-to-favourites').each((_, el) => {
              const productPk = $(el).closest('.js-product-wrapper').data('pk');

              if (item.product.pk == productPk) {
                $(el).closest('.js-product-wrapper').attr('data-favourite-pk', item.pk);
                $(el).addClass('active');
              }
            });
          });
        }
      });
  }

  addHoverEffectToProductImages() {
    const productWrappers = document.querySelectorAll('.js-product-image-wrapper');

    productWrappers.forEach(wrapper => {
        const firstImage = wrapper.querySelector('.js-img-firstly');
        const secondImage = wrapper.querySelector('.js-img-secondary');


        if (firstImage && secondImage) {
          secondImage.style.opacity = 0;
          secondImage.style.transition = 'opacity 0.5s ease-in-out, transform 0.5s ease-in-out';

          const setImageStyles = (firstOpacity, secondOpacity, scale) => {
              firstImage.style.opacity = firstOpacity;
              secondImage.style.opacity = secondOpacity;
              secondImage.style.transform = scale;
          };

          wrapper.addEventListener('mouseover', () => setImageStyles(0, 1, 'scale(1.05)'));
          wrapper.addEventListener('mouseout', () => setImageStyles(1, 0, 'scale(1)'));
        }
    });
  }

}

export default ProductItem;
