import {
  objectAssign
} from '../index';

/** Parameters
 * @param {String} value (Required)
 * Raw price as string or integer.
 *
 * @param {Object} options (Optional)
 * Price formatting options. Shouldn't use if you dont need any custom formattings.
 *
 * @param {Object} options.format (Optional)
 * Main formatting options.
 *
 * @param {Boolean} options.format.fixed (Optional - Default: true)
 * Adds minuscule with comma.
 * e.g.: 000 --> 000,00
 *
 * @param {Boolean} options.format.useThousandSeperator (Optional - Default: true)
 * Adds thousand seperator. The {options.format.thousandSeperator} option is using as seperator character.
 * e.g.: 00000 --> 0.000
 *
 * @param {String} options.format.thousandSeperator (Optional - Default: '.')
 * Sets thousand seperator character.
 *
 * @param {Object} options.negative (Optional)
 * Negative price options.
 *
 * @param {Boolean} options.negative.useNegative (Optional - Default: false)
 * Adds minus before price for use negative price.
 * e.g.: 000 --> -000
 *
 * @param {Boolean} options.negative.useNegativeSpace (Optional - Default: false)
 * Adds space between minus and price.
 * e.g.: -000 --> - 000
 *
 * @param {Object} options.currency (Optional)
 * Currency options.
 *
 * @param {String} options.currency.currencyCode (Optional - Default: 'TL')
 * Sets currency code.
 * i.e.: TL, EUR, USD, CZK, GBP, AZN, JPY
 *
 * @param {String} options.currency.currencySymbol (Optional - Default: '₺')
 * Sets currency symbol. All font based currencies can be useable.
 * i.e.: ₺, €, $, Kč, £, ₼, ¥
 *
 * @param {Boolean} options.currency.useCurrencySymbol (Optional - Default: false)
 * Sets currency as symbol instead code.
 * e.g.: 000TL --> 000₺
 *
 * @param {Boolean} options.currency.useCurrencyAfterPrice (Optional - Default: true)
 * Sets currency position according to price.
 * e.g.: TL000 --> 000TL
 *
 * @param {Boolean} options.currency.useCurrencySpace (Optional - Default: true)
 * Adds space between currency and price.
 * e.g.: 000TL --> 000 TL
*/
export const formatPrice = (value, options = {
}) => {
  if (!value) {
    return null;
  }
  const optionValues = {
    format: {
      fixed: true,
      useThousandSeperator: true,
      thousandSeperator: '.'
    },
    negative: {
      useNegative: false,
      useNegativeSpace: false
    },
    currency: {
      currencyCode: 'TL',
      currencySymbol: '₺',
      useCurrencySymbol: false,
      useCurrencyAfterPrice: true,
      useCurrencySpace: true
    },
    priceFormatter: (price) => {
      return new Intl.NumberFormat('tr-TR', {
        minimumFractionDigits: 2,
      }).format(price);
    },
  };
  objectAssign(optionValues, window.GLOBALS.PRICE_FORMAT_OPTIONS, options);
  const _currency =
    optionValues.currency.useCurrencySymbol ?
      optionValues.currency.currencySymbol :
      optionValues.currency.currencyCode;
  let _price =
    optionValues.format.fixed ?
      optionValues.priceFormatter(value) :
      value.toString();
  _price =
    optionValues.format.fixed &&
    optionValues.format.useThousandSeperator &&
    optionValues.format.thousandSeperator == '.' ?
      _price.replace('.', ',') :
      _price;
  _price =
    optionValues.format.useThousandSeperator ?
      _price.replace(/\B(?=(\d{3})+(?!\d))/g, optionValues.format.thousandSeperator) :
      _price;
  _price =
    optionValues.currency.useCurrencyAfterPrice ?
      optionValues.currency.useCurrencySpace ?
        _price + ' ' + _currency :
        _price + _currency :
      optionValues.currency.useCurrencySpace ?
        _currency + ' ' + _price :
        _currency + _price;
  _price =
    optionValues.negative.useNegative ?
      optionValues.negative.useNegativeSpace ?
        '- ' + _price :
        '-' + _price :
      _price;
  return _price;
};
