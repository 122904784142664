import URL from 'shop-packages/api/urls';
export default function selectOptionData() {

  let subjects;

  $.ajax({
    url: `${URL.CONTACT_US_SUBJECTS}`,
    method: 'get',
    async: false,
    success(data) {
      subjects = data;
      for (let subject of subjects) {
        if (subject.is_order_needed == false) {
          $('.js-select-subject-type').append(`<option data-order="${subject.is_order_needed}" value="${subject.id}">${subject.text}</option`);
        }
      }
    }
  });
}