import 'lightslider'


const sliderWrappers = $('.js-flatpage-slider');

sliderWrappers.each((_, slider) => {
  const $slider = $(slider);
  const length = $slider.attr('data-length');

  if (length > 3) {
    $slider.lightSlider({
      item: 4,
      loop:false,
      autoWidth:false,
      enableDrag: true,
      pager:false,
      slideMargin:40,
      controls:false,
      prevHtml: '<i class="icon-chevron-left"></i>',
      nextHtml: '<i class="icon-chevron-right"></i>',
      responsive: [{
        breakpoint: 1024,
        settings: {
          item: 3,
          slideMargin: 10
        }
      }]
    })
  } else {
    const produtItems = $slider.find('.js-collection-product');
    produtItems.each((_, item) => {
      $(item).addClass('non-slider-item');
    })
    $slider.each((_, item) => {
      $(item).addClass('non-slider-item-two');
    })
  }
})

  let $listDescriptionWrapper = $('.js-seo-description-text-wrapper');
  if($($listDescriptionWrapper).length > 0){
    let $maxLength = 350;
    let $fullContent = $($listDescriptionWrapper).html();
    if($fullContent.length>$maxLength) {
      let short_content =  $fullContent.substr( 0, $maxLength );
      let long_content = $fullContent;
      let short_html = `<span class="short_content">${short_content}</span>...&nbsp;&nbsp;<a href="javascript:;" class="js-read-more read-more">Devamını Oku</a>`;
      let long_html = `<span class="short_content">${long_content}</span><a href="javascript:;" class="js-read-more read-more">Devamını Gizle</a>`;
      $listDescriptionWrapper.html(short_html);

      $(document).on('click','.js-read-more',function(){  
        if($listDescriptionWrapper.hasClass('show')) {
          $listDescriptionWrapper.html(short_html);
        }
        else $listDescriptionWrapper.html(long_html); {
          $listDescriptionWrapper.toggleClass('show');
        }
      })
    }
  }