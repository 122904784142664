export default class OrdersDetail{
  constructor(){
    this.buttons = document.querySelectorAll('.js-button-cancellation');

    this.bindEvents();
  }

  bindEvents() {
    this.buttons.forEach((button) => {
      button.addEventListener('click', this.onCancellationButtonClick);
    });
  }

  onCancellationButtonClick(e) {
    this.$container = $(document.getElementById('orderDetailWrapper'));
    this.orderID = this.$container.attr('data-id');

    window.location.pathname = `/users/orders/${this.orderID}/cancellation`;
  }
}