
import { basketReducer } from "shop-packages/redux/basket/selectors";
import observe from "shop-packages/redux/connectSelector";

export default class BasketGiftPackage {
	constructor() {
		this.init();
	}

	init() {
		this.checkbox = document.querySelector('#basket-gift-package__checkbox');
		
		if (!this.checkbox) {
			delete localStorage.giftProductAdded;
			return;
		};

		this.priceWrapper = document.querySelector('.js-basket-summary-gift-package');
		this.priceElement = this.priceWrapper.querySelector('.js-basket-summary-gift-package-price');
		this.checkbox.addEventListener('change', this.toggleGiftPackage.bind(this));
		
		if (localStorage.giftProductAdded == 'true') {
			this.checkbox.checked = true;
			this.add();
		}
	}

	toggleGiftPackage() {
		this.checkbox.checked ? this.add() : this.remove();
	}

	async add() {
		const productSku = this.checkbox.dataset.giftProductSku;
		if (!productSku) return;

		const product = await this.getProduct(productSku);
		if (!product) return;

		this.priceElement.innerHTML = product.price;
		this.priceWrapper.classList.remove('hidden');
		this.updateCartTotal(product.price);
		localStorage.giftProductAdded = 'true';
		localStorage.giftProductSku = productSku;
	}

	remove() {
		this.priceWrapper.classList.add('hidden');
		this.updateCartTotal();
		delete localStorage.giftProductAdded;
	}

	updateCartTotal(giftProductPrice = 0) {
		this.cartTotalPrice = document.querySelector('.js-basket-summary-total-amount');
		observe(basketReducer).subscribe(({ basket, pending }) => {
			if (!basket || pending) return;
			const { total_amount } = basket;
			this.cartTotalPrice.innerHTML = (+total_amount + +giftProductPrice).toFixed(2);
		});
	}

	async getProduct(sku) {
		try {
			const response = await fetch(`/misc-product/${sku}/`);
			if (!response.ok) throw new Error('Gift package product not found');
			const data = await response.json();
			return data;
		} catch (error) {
			console.log(error)
		}
	}
}
