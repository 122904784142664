import 'lightslider'

const sliderWrappers = $('.js-landingpage-slider');

sliderWrappers.each((_, slider) => {
  const $slider = $(slider);
  
  $slider.lightSlider({
    item: 3,
    loop:false,
    autoWidth:false,
    enableDrag: true,
    pager:false,
    slideMargin:40,
    controls:true,
    prevHtml: '',
    nextHtml: '',
    responsive: [{
      breakpoint: 567,
      settings: {
        item: 2,
        slideMargin: 10
      }
    }]
  })
})