import {
  selectSummaryData
} from 'shop-packages/redux/checkout/selectors';

import observe, { getValue } from 'shop-packages/redux/connectSelector';
import { templateRenderer } from '../../utils';

class Summary {
  observers = [];

  constructor() {
    this.summaryItemTemplate = $(document.getElementById('CheckoutSummaryItem')).html();

    this.$summaryContainer = $(document.getElementById('CheckoutSum'));

    this.initObservers();
  }

  onSummaryUpdate({ shippingAmount, totalAmount, basket,  pre_order }) {
    let summaryHtml = '';

    if (basket.total_product_amount) {
      summaryHtml += templateRenderer(this.summaryItemTemplate, {
        label: 'Ara Toplam',
        value: `${basket.total_product_amount} TL`,
        border: 'border-bottom',
      });
    }

    if (basket.discounts && basket.discounts.length) {
      const discounts = basket.discounts;
      const groupedDiscounts = [];
      const distinctDiscounts = [...new Set(discounts.map((item) => item.description))];
      distinctDiscounts.forEach((e) => {
        const sameDiscounts = discounts
        .filter((i) => e === i.description)
        .map((i) => ({...i, discount: parseFloat(i.discount),}));
        const sumDiscount = sameDiscounts
        .reduce((a,b) => ({ description: e, discount: parseFloat(a.discount) + parseFloat(b.discount)}));
        groupedDiscounts.push({ description: e, discount: sumDiscount.discount.toFixed(2)});
      })
      for (const discount of groupedDiscounts) {
        summaryHtml += templateRenderer(this.summaryItemTemplate, {
          label: discount.description,
          value: discount.discount !== '0.00' ? `-${discount.discount} TL` : '',
          border: '',
        });
      }
    }

    if (basket.total_discount_amount && basket.total_discount_amount !== '0.00') {
      summaryHtml += templateRenderer(this.summaryItemTemplate, {
        label: 'İndirim Toplamı',
        value: `${basket.total_discount_amount} TL`,
        border: 'border-bottom',
      });
    }

    if (shippingAmount) {
      summaryHtml += templateRenderer(this.summaryItemTemplate, {
        label: 'Kargo Bedeli',
        value: `${shippingAmount} TL`,
      });
    }

    summaryHtml += `<div class="checkout__gift-package hidden">${
      templateRenderer(this.summaryItemTemplate, {
        label: 'Hediye Paket Ücreti',
        value: ` `,
      })
    }</div>` ;

    if (totalAmount) {
      summaryHtml += `<div class="checkout__total-amount">${ templateRenderer(this.summaryItemTemplate, {
        label: 'Toplam (KDV Dahil)',
        value: `${totalAmount} TL`,
        border: 'border-bottom',
      }) }</div>`;
    }

    this.$summaryContainer.html(summaryHtml);

    const giftPackagePrice = pre_order?.gift_box?.price;
    if (giftPackagePrice) {
      const checkoutGiftElementWrapper = document.querySelector('.checkout__summary .checkout__gift-package');
      const checkoutGiftElementPrice = checkoutGiftElementWrapper?.querySelector('.checkout-summary-item__value');
      if (checkoutGiftElementWrapper) {
        checkoutGiftElementWrapper.classList.remove('hidden');
      }
      if (checkoutGiftElementPrice) {
        checkoutGiftElementPrice.innerText = `${giftPackagePrice} TL`;
      }
    }

  }

  initObservers() {
    this.observers = [
      observe(selectSummaryData).subscribe(this.onSummaryUpdate.bind(this)),
    ];
  }
}

export default Summary;