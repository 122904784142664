import 'lightslider'
import Listing from "../list";
import accountMenuListItem from '../components/account-menu'
import * as actionTypes from "shop-packages/analytics/action-types";
import {pushEvent} from "shop-packages/analytics";
import elementInViewport from '../components/isInViewPort';
import 'lightslider'
import AddToCartButton from '../components/button-add-to-cart';

export default function () {
  accountMenuListItem();
  soloBanner();
  doubleBox();
  tripleBanner();
  heroBanner(false);
  pushLoginAnalyticsData();
  pushRegisterAnalyticsData();
  pushLogoutAnalyticsData();
  vlHeaderBanner();

  $(document).ready(function() {
    $('.basket-reco__wrapper').lightSlider({
        item:6,
        loop:true,
        slideMove:2,
        easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
        speed:600,
        pager: false,
        prevHtml: '<i class="icon-chevron-left basket-reco-item__controls"></i>',
        nextHtml: '<i class="icon-chevron-right basket-reco-item__controls"></i>',
        slideMargin: 10,
        responsive : [
            {
                breakpoint:800,
                settings: {
                    item:3,
                    slideMove:1,
                    slideMargin:6,
                  }
            },
            {
                breakpoint:480,
                settings: {
                    item:2,
                    slideMove:1
                  }
            }
        ]
    });  
  });

  const $addToCart = $('.basket-reco__wrapper .js-add-to-cart');
  $addToCart.each((_, el) => {
    AddToCartButton($(el));
  })

  function pushAuthAnalyticsData(event, storageKey) {
    const data = localStorage.getItem(storageKey);
    if (!data) {
      return;
    }
  
    try {
      const userId = document.querySelector('#js-user-id-input');
      if (!userId?.value) {
        return;
      }
  
      const { method } = JSON.parse(data);
      const eventData = {
        user_id: userId.value,
        method: method,
        event: event
      };
  
      dataLayer.push(eventData);
    } catch (error) {
      console.log(error);
    } finally {
      delete localStorage[storageKey];
    }
  }
  
  function pushLoginAnalyticsData() {
    pushAuthAnalyticsData('login', 'loginAnalyticsData');
  }
  
  function pushRegisterAnalyticsData() {
    pushAuthAnalyticsData('signup', 'registerAnalyticsData');
  }
  
  function pushLogoutAnalyticsData() {
    const logoutButton = document.querySelector('.user-panel__auth__menu__link');
    if (!logoutButton) {
      return;
    }
  
    const userId = document.querySelector('#js-user-id-input');
    if (!userId?.value) {
      return;
    }
  
    logoutButton.addEventListener('click', function () {
      dataLayer.push({
        'event': 'logout',
        'user_id': userId.value
      });
    })
  }

  function debounce(func, wait, immediate) {
    var timeout;

    return function executedFunction() {
      var context = this;
      var args = arguments;

      var later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };

      var callNow = immediate && !timeout;

      clearTimeout(timeout);

      timeout = setTimeout(later, wait);

      if (callNow) func.apply(context, args);
    };
  };

  const pushAnalyticEvent = (actionType) => {
    const activeSlide = $('.slider__item.active');
    const position = activeSlide.data('position');
    const sliderHref = activeSlide.attr('href');
    const id = 'Hero Banner - ' + position;
    const image = $('.slider__item.active img');

    const data = {
      type: actionType,
      payload: {
        promotions: []
      }
    };

    data.payload.promotions.push({
      'id': id,
      'name': sliderHref,
      'creative': image.attr('src'),
      'position': position
    });

    pushEvent(data);

  };

  function vlHeaderBanner() {
    const headerBanner = document.querySelector('#vl-header-banner');
    const bodyClass = document.body.classList;
    if (!(bodyClass.contains('listing') || bodyClass.contains('homepage')) && headerBanner) {
      headerBanner.remove();
    }
  }

  const bannerPromotions = $('.js-banner-promotion');
  if ( bannerPromotions.length ) {
    $.each(bannerPromotions, function( ind, el ) {
      $(el).attr('data-position', ind + 1);
    });
    bannerPromotions.on('click', function(){
      const el = $(this);
      el.parents('[data-slug]').first().find('.analytics-data').each(function() {
        const analyticsData = JSON.parse($(this).text());
        if (!analyticsData.type) return;
        if (analyticsData.type === actionTypes.promotionViewed){
          $.each( analyticsData.payload.promotions , function( index , value ){
            if( value.id == el.data('analytics') ) {
              const analyticsData = {
                type : actionTypes.promotionClick,
                payload : {
                  promotions : [{...value, position: el.data('position')}]
                }
              };
              pushEvent(analyticsData);
            }
          })
        }
      });
    });
  }

  const homeSlider = $('.js-home-slider');

  const slider = homeSlider.lightSlider({
    onSliderLoad: function () {
      $('.js-unload-slider').addClass('d-none');
      $('.loader').addClass('d-none');
      homeSlider.removeClass('opacity-0');
      homeSlider.addClass('opacity-1');
    },
    item:1,
    auto: true,
    pause: 5000,
    loop:true,
    autoWidth:true,
    enableDrag: true,
    prevHtml: '<i class="icon-chevron-left"></i>',
    nextHtml: '<i class="icon-chevron-right"></i>',

    onAfterSlide: function(e){
      pushAnalyticEvent(actionTypes.promotionViewed);
      heroBanner(e)
    }
    
  });


  const sliderWrapper = $('.js-home-look-slider');

  const sliderLook = sliderWrapper.lightSlider({
    onSliderLoad: function () {
      $('.js-unload-slider').addClass('d-none');
      $('.loader').addClass('d-none');
      sliderWrapper.removeClass('opacity-0');
      sliderWrapper.addClass('opacity-1');
      const activeIndex = sliderLook.getCurrentSlideCount();
      sliderLook[0].querySelectorAll('.lslide')[(activeIndex + 3) % 15]?.classList.add('active-slide');
    },
    item:5.5,
    auto: true,
    loop:true,
    enableDrag: true,
    pauseOnHover: true,
    speed: 1300,
    pause: 1300,
    pager:false,
    cssEasing: 'linear',
    freeMove: true,
    slideEndAnimation: false,
    prevHtml: '<i class="icon-chevron-left"></i>',
    nextHtml: '<i class="icon-chevron-right"></i>',
    responsive : [
      {
          breakpoint:768,
          settings: {
              auto: false,
              loop: true,
              controls: false,
              item: 8.5,
              speed: 500
            }
      },
  ],
    onAfterSlide: function(e){
      $('.home-look-slider-item').each((index, item) => {
        if ($(item).find('img').attr('src') === $('.js-look-by-selected-img').attr('src')) {
            $(item).addClass('active-slide');
        }
      })    
    },

    onBeforeSlide: function(e){
      $('.home-look-slider-item').each((index, item) => {
        if ($(item).find('img').attr('src') === $('.js-look-by-selected-img').attr('src')) {
            $(item).addClass('active-slide');
        }
      })    
    },

  });

  if (window.innerWidth > 768) {
    $('.home-look-by').on('mouseover', (e) => {
      if (e.currentTarget && (e.currentTarget.className === 'home-look-by' || e.currentTarget.className === 'js-look-by-selectable')) {
        sliderLook.pause(); 
      }
    })

    $('.home-look-by').on('mouseleave', (e) => {
      sliderLook.play();
    });
  }

  $('.js-home-look-by-slider-prev').on('click', () => {
    sliderLook.goToPrevSlide();
    lookSliderEvents(-1);
  })

  $('.js-home-look-by-slider-next').on('click', () => {
    sliderLook.goToNextSlide();
    lookSliderEvents(1);
  })
  
  $('.home-look-slider-item').each((index, item) => {
    $(item).on('click', () => {
      $('.home-look-slider-item').each((index, el) => {
        $(el).removeClass('active-slide');
      })
      $(item).addClass('active-slide');
      const selectableItem = $(item).find('.js-look-by-selectable');

      if (selectableItem && $('.js-look-by-selected-img')) {
        $('.js-look-by-selected-img').attr('src', selectableItem.attr('src'));
        $('.js-look-by-selected-href').attr('href', selectableItem.attr('data-href'));
      }
    })
  })

  sliderLook.off('touchmove');
  sliderLook.on('touchmove', () => {
    lookSliderEvents(0);
  })

  sliderLook.off('touchend');
  sliderLook.on('touchend', () => {
    lookSliderEvents(0);
  })

  function lookSliderEvents(slideIndex) {
    $('.home-look-slider-item').each((index, item) => {
      if ((item.getBoundingClientRect().left > window.innerWidth / 2 - 23) && (item.getBoundingClientRect().left < window.innerWidth / 2 + 23)) {
        slideIndex !== 0 && $(item).removeClass('active-slide');

        $($('.home-look-slider-item')[index + slideIndex]).addClass('active-slide');
        const selectableItem = $($('.home-look-slider-item')[index + slideIndex]).find('.js-look-by-selectable');

        if (selectableItem && $('.js-look-by-selected-img')) {
          $('.js-look-by-selected-img').attr('src', selectableItem.attr('src'));
          $('.js-look-by-selected-href').attr('href', selectableItem.attr('data-href'));
        }
      } else {
        $(item).removeClass('active-slide');
      }
    });
  }



  function heroBanner(e) {
    const heroBannerElement = document.querySelector('.js-home-slider');
    if (!heroBannerElement) {
      return;
    }

    elementInViewport(heroBannerElement);

    if(e){
    const htmlCollection = [].slice.call(e[0].children);
    const sliderArray = htmlCollection.slice(1, -1);
  
    sliderArray.forEach((item, index) => {
      if (heroBannerElement.classList.contains('is-visible')) {
        if (item.classList.contains('active')) {
          window.dataLayer.push({ ecommerce: null });
          window.dataLayer.push({
            'event': 'view_promotion',
            'ecommerce': {
              'items': [
                {
                  'promotion_id': `hero_banner_id_${index+1}`,
                  'promotion_name': $(item).data('name'),
                  'creative_name': $(item).data('url'),
                  'creative_slot': `hero_banner_${index+1}`,
                  'location_id': `homepage_hero_banner`
                }
              ]
            },
          });
        }
      }
    })
    }
    else{
      $(document).ready(function() {
          let firstItem = $('.js-home-slider .lslide.active');
          window.dataLayer.push({ ecommerce: null });
          window.dataLayer.push({
            'event': 'view_promotion',
            'ecommerce': {
              'items': [
                {
                  'promotion_id': `hero_banner_id_1`,
                  'promotion_name': firstItem.data('name'),
                  'creative_name': firstItem.data('url'),
                  'creative_slot': `hero_banner_1`,
                  'location_id': `homepage_hero_banner`
                }
              ]
            },
        });
      });
    }
  }

  $('.js-home-slider .lslide').each(function (index) {
    $(this).on('click', function () {
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        'event': 'select_promotion',
        'ecommerce': {
          'items': [
            {
              'promotion_id': `hero_banner_id_${index+1}`,
              'promotion_name': $(this).data('name'),
              'creative_name': $(this).data('url'),
              'creative_slot': `hero_banner_${index+1}`,
              'location_id': `homepage_hero_banner`
            }
          ]
        },
      });
    });
  });

  function soloBanner() {
    const soloBanner = document.querySelectorAll('.js-solo-banner');
    const soloBannerObserve = document.querySelector('.js-solo-banner-observe');
    const soloBannerAnalitycs = document.querySelector('.js-solo-banner-analytics');
    const ecommerce = soloBannerAnalitycs ? JSON.parse(soloBannerAnalitycs.textContent) : null;

    if (!soloBanner) {
      return;
    }

    const observer = new IntersectionObserver(entries => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          window.dataLayer.push({ ecommerce: null });
          window.dataLayer.push({
            'event': 'view_promotion',
            ecommerce
          });
        }
      });
    });

    if(soloBannerObserve) {
      observer.observe(soloBannerObserve);
    }

    soloBanner.forEach((el, index) => {
      el.elems_index = index;
      $(el).on('click', function () {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          'event': 'select_promotion',
          'ecommerce': {
            'items': [
              {
                'promotion_id': `solo_banner_id_${index + 1}`,
                'promotion_name': $(this).data('analytics'),
                'creative_name': `solo_banner_${index + 1}`,
                'creative_slot': `solo_banner_${index+1}`,
                'location_id': `homepage_solo_banner`,
              }
            ]
          }
        });
      });
    });
  }

  function doubleBox() {
    const doubleBox = document.querySelectorAll('.js-double-box');
    const doubleBoxObserve = document.querySelector('.js-double-box-observe');
    const doubleBoxAnalitycs = document.querySelector('.js-double-box-analytics');
    const ecommerce = doubleBoxAnalitycs ? JSON.parse(doubleBoxAnalitycs.textContent) : null;

    if (!doubleBox) {
      return;
    }

    const observer = new IntersectionObserver(entries => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          window.dataLayer.push({ ecommerce: null });
          window.dataLayer.push({
            'event': 'view_promotion',
            ecommerce
          });
        }
      });
    });

    if(doubleBoxObserve) {
      observer.observe(doubleBoxObserve);
    }

    doubleBox.forEach((el, index) => {
      el.elems_index = index;
      $(el).on('click', function () {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          'event': 'select_promotion',
          'ecommerce': {
            'items': [
              {
                'promotion_id': `double_box_id_${index + 1}`,
                'promotion_name': $(this).data('analytics'),
                'creative_name': `creative_box_${index + 1}`,
                'creative_slot': `double_box_${index+1}`,
                'location_id': `homepage_double_box`,
              }
            ]
          }
        });
      });
    });
  }

  function tripleBanner() {
    const tripleBanner = document.querySelectorAll('.js-triple-banner');
    const tripleBannerObserve = document.querySelector('.js-triple-banner-observe');
    const tripleBannerAnalitycs = document.querySelector('.js-triple-banner-analytics');
    const ecommerce = tripleBannerAnalitycs ? JSON.parse(tripleBannerAnalitycs.textContent) : null;

    if (!tripleBanner) {
      return;
    }

    const observer = new IntersectionObserver(entries => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          window.dataLayer.push({ ecommerce: null });
          window.dataLayer.push({
            'event': 'view_promotion',
            ecommerce
          });
        }
      });
    });

    if(tripleBannerObserve) {
      observer.observe(tripleBannerObserve);
    }

    tripleBanner.forEach((el, index) => {
      el.elems_index = index;
      $(el).on('click', function () {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          'event': 'select_promotion',
          'ecommerce': {
            'items': [
              {
                'promotion_id': `triple_banner_${index + 1}`,
                'promotion_name': $(this).data('text'),
                'creative_name': `uclu_banner_${index+1}`,
                'creative_slot': `uclu_banner_${index+1}`,
                'location_id': `homepage_uclu_banner`,
              }
            ]
          }
        });
      });
    });
  }

  const searchFunction = debounce(function(){
    let urlFlag = true;
    let searchCurrentUrl;
    $('body').addClass('zooContainerClass');

    const $this = $(this);
    const searchText = $this.val();
    const searchUrl = window.location.origin + '/list/?search_text=' + searchText;
    let requestArr = [];

    if (urlFlag) {
      searchCurrentUrl = window.location.href;
      urlFlag = false;
    }

    if ($this.val().length > 2) {

      window.history.pushState(null, document.title, searchUrl);
      if (requestArr.length){
        requestArr[0].abort();
        requestArr = [];
      }
      const searchRequest = $.ajax({
        url: searchUrl,
        method: 'GET',
        dataType: 'html',
        success: (response) => {
          $('.js-search-container').html('<div class="js-content">'+$(response).find('.js-base-content').html()+'</div>');
          $('.js-base-content').hide();
          $('.js-search-content').show();
          const productList = [];
          let productListDatas = '';
          $('.js-product-wrapper,.js-product-container').each(function() {
            if($(this).data('pk')) {
              productList.push('&product_id=' + $(this).data('pk'))
            }
          });

          for(let i = 0; i < productList.length; i++) {
            productListDatas += productList[i];
          }
          new Listing();
        }
      });
      requestArr.push(searchRequest);
    }
    else {
      $('body').removeClass('zooContainerClass')
      $('.js-base-content').show();
      $('.js-search-content').hide();
      window.history.pushState(null, document.title, searchCurrentUrl);
      if (homeSlider.length) {
        slider.refresh();
      }

      if (sliderWrapper.length) {
        sliderLook.refresh();
      }
    }

  },250);

  const $document = $(document);
  $document.on('keyup', '.js-list-search-input',searchFunction);

}

function cookiePopup(){
  const $elem = $('.js-cookie-modal');
  
    if(localStorage.acceptCookies) {
      $elem.hide();
    }else {
      $elem.show();
    }

  $('.js-cookie-close-btn').on( 'click', function() {
    localStorage.setItem('acceptCookies', false);
    $elem.hide();
  });
}

(function(){
  var bannerVid = document.querySelector('.js-single-banner-video');
  if( bannerVid ) {
    bannerVid.addEventListener('click', function(){
        if(bannerVid.paused) {
          bannerVid.classList.remove('paused');
          bannerVid.play();
        } else {
          bannerVid.classList.add('paused');
          bannerVid.pause();
        }
    })
  }
})();