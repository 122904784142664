import 'lightslider';
import 'lightgallery';

const ProductSlider = (selector, options = {}) => {
  const defaults = {
    item: 1,
    loop: true,
    gallery: true,
    autoWidth: false,
    slideMargin: 0,
    galleryMargin: 20,
    thumbItem: 4,
    thumbMargin: 14,
    addClass: 'product-slider',
    responsive: [
      {
        breakpoint: 991,
        settings: {
          thumbItem: 6,
        }
      },
      {
        breakpoint: 768,
        settings: {
          galleryMargin: 10,
          thumbItem: 5,
        }
      },
      {
        breakpoint: 575,
        settings: {
          galleryMargin: 5,
          thumbItem: 4,
          thumbMargin: 5,
        }
      },
    ]
  };

  const imageLength = $('.js-product-image').length;

  const opts = {...defaults, ...options}
  const slider = imageLength > 1 ? $(selector).lightSlider(opts) : false;
  return slider;
};

export default ProductSlider;