import IMask from 'imask';
export default class OTPLogin {
  constructor() {
    this.form = $(document.querySelector('.js-otp-form'));
    this.phoneNumber = $(document.querySelector('.js-otp-phone'));
    this.submitForm();
    this.submitCode();
    this.resendCode();

    IMask(this.phoneNumber[0], {
      mask: '\\0\\ {(500)} 000 00 00',
      placeholderChar: '_',
      lazy: false
    });
  }

  submitForm() {
    $('.js-otp-form').on('submit', function (e) {
      e.preventDefault();
      const form = $(document.querySelector('.js-otp-form'));
      const code = $(document.querySelector('.js-code-form'));
      const phoneNumber = $(document.querySelector('.js-otp-phone')).val();
      const phoneVal = phoneNumber.replaceAll(' ', '').replace('(', '').replace(')', '');
      
      $.ajax({
        url: '/users/otp-login/',
        method: 'POST',
        data: { phone: phoneVal, code: '' },
        success: function () {
          form.addClass('d-none');
          code.removeClass('d-none');
        },
        error: function (errors) {
          console.log(errors);
        }
      })
    })
  }

  submitCode() {
    $('.js-code-form').on('submit', function (e) {
      e.preventDefault();
      const phoneNumber = $(document.querySelector('.js-otp-phone')).val();
      const phoneVal = phoneNumber.replaceAll(' ', '').replace('(', '').replace(')', '');
      const codeVal = $('.js-otp-code').val();
      
      $.ajax({
        url: '/users/otp-login/',
        method: 'POST',
        data: { phone: phoneVal, code: codeVal },
        success: function () {
          document.location.href = location.origin;
          localStorage.setItem('loginAnalyticsData', JSON.stringify({ method:'phone number' }));
        },
        error: function (errors) {
          $('.js-error-otp-code').html(errors.responseJSON.non_field_errors);
          console.log(errors.responseJSON.non_field_errors);
        }
      })
    })
  }

  resendCode() {
    $('.js-error-code__resend').on('click', function (e) {
      e.preventDefault();
      const phoneNumber = $(document.querySelector('.js-otp-phone')).val();
      const phoneVal = phoneNumber.replaceAll(' ', '').replace('(', '').replace(')', '');

      $.ajax({
        url: '/users/otp-login/',
        method: 'POST',
        data: { phone: phoneVal, code:'' },
        success: function (response) {
          console.log(response)
        },
        error: function (errors) {
          console.log(errors);
        }
      })
    })
  }
}