import { ApiClient } from 'shop-packages';

import ActionForm from '../components/form-action';

class ForgotPassword {
  constructor() {
    const $form = $(document.getElementById('ForgotPasswordForm'));
    const $email = $form.find('input[name="email"]');

    const form = ActionForm($form);

    form.action = ApiClient.user.resetPassword;
    form.beforeAction = () => {
      form.actionParams = [
        $email.val()
      ];
    };

    form.onSuccess = () => {
      $(document.getElementById('ForgotPasswordPage')).html(
        $(document.getElementById('ForgotPasswordSuccessTemplate')).html()
      );
    }

    form.onError = err => {
      form.showErrors(err);
    }
  }
}

export default ForgotPassword;