import 'jquery-validation';
import Inputmask from 'inputmask';
import store from 'shop-packages/redux/store';

import observe, {
  getValue
} from 'shop-packages/redux/connectSelector';

import {
  completeCheckout,
  setBinNumber,
  setInstallment,
  setMasterPassInstallment
} from 'shop-packages/redux/checkout/actions';

import {
  setCardCVV,
  setCardMonth,
  setCardNumber,
  setCardHolder,
  setCardYear,
  setAgreement,
} from 'shop-packages/redux/checkout/reducer';

import {
  selectBinNumber,
  selectCardMonth,
  selectCardNumber,
  selectCardYear,
  selectCardCVV,
  selectCardTypeName,
  selectCardTypeImage,
  selectInstallments,
  selectCurrentInstallmentId,
  selectCurrency,
  selectAgreement,
  selectPending,
  selectErrors,
  selectCurrentPaymentOption
} from 'shop-packages/redux/checkout/selectors';

import {
  clearErrors
} from 'shop-packages/redux/checkout/reducer';

import { isValidCard,
  templateRenderer
} from '../../../utils';

import {
  formatPrice
} from '../../../utils/formatter/price';

const _messages = {
  required: 'Bu alan zorunludur.',
  minlength: 'En az [__charCount__] karakter girilmelidir.',
  maxlength: 'En fazla [__charCount__] karakter girilmelidir.',
  enterValidCardNo: 'Lütfen geçerli bir kart numarası girin.',
};

const _messageFormat = (message, charCount) => message.replace('[__charCount__]', charCount);


class MasterpassCardOption {
  observers = [];

  binNumber = '';

  hasBinNumber = false;

  agreement = false;

  installments = [];

  selectedInstallmentId = null;

  currency = 'TL';

  validator = null;

  constructor() {
    this.installmentOptionTemplate = $(document.getElementById('MasterpassInstallmentItemTemplate')).html();
    this.$creditCardForm = $(document.getElementById('MPCreditCardForm'));
    this.$masterpassSaveWrapper = $(document.querySelector('.pz-mp-save-card'));
    this.$masterpassSaveHeader = $(document.querySelector('.pz-mp-save-card-cb-w'));
    this.$completeButton = $('.js-checkout-complete-button');
    this.$formErrors = this.$creditCardForm.find('.js-form-errors');

    this.$cardInput = $(document.getElementById('master_card_number'));
    this.$cardHolder = $(document.getElementById('card_holder'));
    this.$cardMonthInput = $(document.getElementById('card_month'));
    this.$cardYearInput = $(document.getElementById('card_year'));
    this.$cardCVVInput = $(document.getElementById('master_card_cvv'));
    this.$agreementInput = $('.js-checkout-agreement-input');
    this.$errorCardNumber = $('.js-error-masterpass_card_number');
    this.$cvvInfo = $('.js-cvv-info');
    this.$cvvInfoImage = $('.js-cvv-info-image');

    this.$cardInputMasked = new Inputmask({
      mask: '9999 9999 9999 9999',
      autoUnmask: true,
    }).mask(this.$cardInput[0]);
    this.$cardInput.attr('data-is-amex', false);
    this.$cardInput.attr('data-card-type-defined', false);

    this.$cardCVVMasked = new Inputmask({
      mask: '999'
    }).mask(this.$cardCVVInput[0]);

    this.$cardImageContainer = $(document.getElementById('CheckoutCardImageMasterpass'));
    this.$cardNameContainer = $(document.getElementById('CheckoutCardName'));
    this.$installmentsEmptyView = $(document.getElementById('MasterpassInstallmentsEmptyView'));
    this.$installmentsContainer = $(document.getElementById('MasterpassInstallmentsContainer'));
    this.$installmentsListContainer = $(document.getElementById('MasterpassInstallmentsList'));
    this.$cardCVVInput.on('blur', this.onCvvInputBlur.bind(this));


    this.$cardInput.on('input.cardNumber, paste', this.onCardInputChange.bind(this));
    this.$cardHolder.on('input.cardHolder, paste', this.onCardHolderChange.bind(this));
    this.$cardMonthInput.on('input.cardMonth', this.onCardMonthChange.bind(this));
    this.$cardYearInput.on('input.cardYear', this.onCardYearChange.bind(this));
    this.$cardCVVInput.on('input.cardCVV, paste', this.onCardCVVChange.bind(this));
    this.$agreementInput.on('input.agreement', this.onAgreementChange.bind(this));

    this.currency = getValue(selectCurrency);
    this.binNumber = getValue(selectBinNumber);
    this.selectedInstallmentId = getValue(selectCurrentInstallmentId);

    this.initObservers();

    this.initCreditCardForm();
    this.setCvvInfoImagePosition();
    this.changeMassterpassSaveTemplate();
    $(window).resize(this.setCvvInfoImagePosition.bind(this));
  }

  setCvvInfoImagePosition() {
    this.$cvvInfoImage.css('top', this.$cvvInfo.height() + 5);
  }

  changeCardInputMaskType(val) {
    if (val.length < 2) {
      this.$cardInput.attr('data-card-type-defined', false);
      return false;
    }
    const cardInputTypeDefined = this.cardInputTypeDefined();
    if (cardInputTypeDefined) {
      return false;
    }
    const isAmex = val.startsWith('37');
    if (isAmex == this.cardInputTypeIsAmex()) {
      return false;
    }
    this.$cardInput.attr('data-is-amex', isAmex);
    this.$cardInput.attr('data-card-type-defined', true);
    if (this.$cardInput[0].inputmask) {
      this.$cardInput[0].inputmask.remove();
    }
    if (isAmex) {
      this.$cardInputMasked = new Inputmask({
        mask: '9999 999999 99999',
        autoUnmask: true
      }).mask(this.$cardInput[0]);
    } else {
      this.$cardInputMasked = new Inputmask({
        mask: '9999 9999 9999 9999',
        autoUnmask: true
      }).mask(this.$cardInput[0]);
    }
  }

  cardInputTypeDefined() {
    return this.$cardInput.attr('data-card-type-defined') == 'true';
  }

  cardInputTypeIsAmex() {
    return this.$cardInput.attr('data-is-amex') == 'true';
  }

  onCardInputChange() {
    const element = this.$cardInput[0];
    const cardNumber = element.inputmask ? element.inputmask.unmaskedvalue() : element.value;
    store.dispatch(setCardNumber(cardNumber));
    this.changeCardInputMaskType(cardNumber);
    this.$errorCardNumber.html(_messages.enterValidCardNo).attr('hidden', isValidCard(cardNumber));
  }

  onCvvInputBlur() {
    const element = this.$cardCVVInput[0];
    if (element.inputmask.unmaskedvalue() !== '') {
      this.onCardCVVChange();
    }
  }

  onCardHolderChange() {
    const element = this.$cardHolder[0];

    const _value = element.value.replace(/[^A-ZÖÇİŞĞÜa-zöçğışü ]/gi, '');
    if (element.value != _value) {
      element.value = _value;
    }

    store.dispatch(setCardHolder(element.value));
  }

  onCardMonthChange(e) {
    store.dispatch(setCardMonth($(e.currentTarget).val()));
  }

  onCardYearChange(e) {
    store.dispatch(setCardYear($(e.currentTarget).val()));
  }

  onCardCVVChange() {
    const element = this.$cardCVVInput[0];
    store.dispatch(setCardCVV(element.inputmask ? element.inputmask.unmaskedvalue() : element.value));
  }

  onAgreementChange(e) {
    const $t = $(e.target);
    const checked = $t.prop('checked');
    $(`.js-checkout-agreement-input[name="${$t.attr('name')}"]`).prop('checked', checked);

    store.dispatch(setAgreement($(e.currentTarget).is(':checked')));
  }

  onCardMonthUpdate(cardMonth) {
    this.$cardMonthInput.val(cardMonth);

    if (this.validator) {
      this.validator.element(this.$cardMonthInput);
    }
  }

  onCardNumberUpdate(cardNumber) {
    const element = this.$cardInput[0];
    element.inputmask ? element.inputmask.setValue(cardNumber) : element.val(cardNumber);
  }

  onCardYearUpdate(cardYear) {
    this.$cardYearInput.val(cardYear);

    if (this.validator) {
      this.validator.element(this.$cardYearInput);
    }
  }

  onCardCVVUpdate(cardCVV) {
    const element = this.$cardCVVInput[0];
    element.inputmask ? element.inputmask.setValue(cardCVV) : element.val(cardCVV);
  }

  onCardTypeNameUpdate(typeName) {
    this.$cardNameContainer.html(typeName);
  }

  onCardTypeImageUpdate(imageSrc) {
    this.$cardImageContainer.html(`<img src="${imageSrc}">`);
  }

  onBinNumberUpdate(binNumber) {
    const currentPaymentOption = getValue(selectCurrentPaymentOption);
    this.hasBinNumber = !!binNumber;

    if (!this.hasBinNumber && currentPaymentOption.payment_type === 'credit_card') {
      this.$cardImageContainer.addClass('d-none');
      this.$cardImageContainer.removeClass('d-flex');
      this.$installmentsEmptyView.removeClass('d-none');
      this.$installmentsContainer.addClass('d-none');

      return;
    }

    if (binNumber !== this.binNumber && currentPaymentOption.payment_type === 'credit_card') {
      store.dispatch(setBinNumber(binNumber));
      this.binNumber = binNumber;
    }

    this.$cardImageContainer.removeClass('d-none');
    this.$cardImageContainer.addClass('d-flex');
    if (binNumber){
      this.$installmentsEmptyView.removeClass('d-flex');
      this.$installmentsEmptyView.addClass('d-none');
    } else {
      this.$installmentsEmptyView.addClass('d-flex');
      this.$installmentsEmptyView.removeClass('d-none');
    }
    this.$installmentsContainer.removeClass('d-none');
  }

  onInstallmentsUpdate(installments) {
    this.installments = installments;
    this.renderInstallments();
  }

  onSelectedInstallmentUpdate(selectedId) {
    this.selectedInstallmentId = selectedId;
    this.$installmentsListContainer.find('tr').removeClass('checked');

    this.$installmentsListContainer
      .find(`.js-installment-input[value="${selectedId}"]`)
      .prop('checked', true).closest('tr')
      .addClass('checked');
  }

  onAgreementUpdate(agreement) {
    this.$agreementInput.prop('checked', agreement);
  }

  renderInstallments() {
    const content = this.installments.map(({ pk,
      label,
      installment_count,
      monthly_price_with_accrued_interest: monthly,
      price_with_accrued_interest: total }) => {
      const checked = this.selectedInstallmentId === pk ? 'checked' : '';
      const currency = this.currency;

      return templateRenderer(this.installmentOptionTemplate, {
        pk,
        label,
        installment_count,
        monthly: formatPrice(monthly),
        total: formatPrice(total),
        checked,
        currency
      });
    });

    this.$installmentsListContainer.html(content.join(''));
    this.$installmentsListContainer
      .find('.js-installment-input')
      .on('input', (e) => {
        const selected = $(e.currentTarget).val();
        this.$installmentsListContainer.find('tr').removeClass('checked');
        $(e.currentTarget).closest('tr').addClass('checked');
        const currentPaymentOption = getValue(selectCurrentPaymentOption);
        store.dispatch((currentPaymentOption.payment_type === 'masterpass'
          ? setMasterPassInstallment : setInstallment)(selected));
      });
  }

  initCreditCardForm() {
    const normalizeMaskedValue = (element) => element.inputmask ? element.inputmask.unmaskedvalue() : element.value;
    this.validator = this.$creditCardForm.validate({
      submitHandler: (_, event) => {
        event.preventDefault;
        store.dispatch(clearErrors());
        store.dispatch(completeCheckout());

        return false;
      },
      highlight: (element) => {
        const $element = $(element);
        if (this.$agreementInput[0] == $element[0]) {
          $element.parent().addClass('has-errors');
        } else {
          $element.addClass('has-errors');
        }
      },
      unhighlight: (element) => {
        const $element = $(element);
        if (element.classList.contains('js-checkout-agreement-input')) {
          $element.parent().removeClass('has-errors');
          $('.js-error-aggreement').html('').attr('hidden', true);
        } else {
          $element.removeClass('has-errors');
        }
      },
      errorPlacement: (error, element) => {
        const _element = element[0];
        if (_element.classList.contains('js-checkout-agreement-input')) {
          $('.js-error-aggreement').html(error.html()).attr('hidden', false);
        } else if (this.$cardMonthInput[0] == _element || this.$cardYearInput[0] == _element) {
          error.insertAfter(element.parent().parent());
        } else {
          error.insertAfter(element);
        }

        if ($(window).width() < 480) {
          const $error = $('.js-error-agreement:visible,div.error:visible,label.error[for]:visible');
          if ($error.length) {
            $('html, body').animate({
              scrollTop: $error.offset().top - 100
            }, 500);
          }
        }
      },
      ignore: [],
      groups: {
        card_date: 'card_month card_year'
      },
      rules: {
        card_holder: {
          required: !!this.$cardHolder.length,
        },
        card_number: {
          required: true,
          minlength: () => this.cardInputTypeIsAmex() ? 15 : 16,
          normalizer: () => normalizeMaskedValue(this.$cardInput[0]),
        },
        card_month: {
          required: true,
          normalizer: () => getValue(selectCardMonth),
        },
        card_year: {
          required: true,
          normalizer: () => getValue(selectCardYear),
        },
        card_cvv: {
          required: true,
          minlength: 3,
          normalizer: () => normalizeMaskedValue(this.$cardCVVInput[0]),
        },
        agreement: {
          required: true,
          normalizer: () => getValue(selectAgreement),
        },
      },
      messages: {
        card_holder: {
          required: _messages.required,
        },
        card_number: {
          required: _messages.required,
          minlength: () => _messageFormat(_messages.minlength, this.cardInputTypeIsAmex() ? 15 : 16),
        },
        card_month: {
          required: _messages.required,
        },
        card_year: {
          required: _messages.required,
        },
        card_cvv: {
          required: _messages.required,
          minlength: _messageFormat(_messages.minlength, 3),
        },
        agreement: {
          required: _messages.required,
        }
      }
    });
  }

  changeMassterpassSaveTemplate() {
    if (this.$masterpassSaveWrapper) {
      this.$masterpassSaveHeader.find('.pz-mp-save-card-cb-w__text').insertAfter(this.$masterpassSaveHeader);
    }
  }

  onPendingUpdate(isPending) {
    isPending ?
      this.$completeButton.attr('disabled', 'disabled').addClass('loading') :
      this.$completeButton.removeAttr('disabled').removeClass('loading');
  }

  onErrorsUpdate(errors) {
    if (errors) {
      for (const key in errors) {
        const $error = $(`.js-error-${key}`);
        if ($error.length) {
          $(`.js-error-${key}`)
            .html(errors[key])
            .attr('hidden', false)
            .parent()
            .addClass('has-errors');

          $('html, body').animate({
            scrollTop: $error.offset().top - 100
          }, 500);
        }
      }
    } else {
      $('[class^="js-error-"], [class*=" js-error-"]')
        .html('')
        .attr('hidden', true)
        .parent()
        .removeClass('has-errors');
    }
  }

  initObservers() {
    this.observers = [
      observe(selectBinNumber).subscribe(this.onBinNumberUpdate.bind(this)),
      observe(selectCardMonth).subscribe(this.onCardMonthUpdate.bind(this)),
      observe(selectCardNumber).subscribe(this.onCardNumberUpdate.bind(this)),
      observe(selectCardYear).subscribe(this.onCardYearUpdate.bind(this)),
      observe(selectCardCVV).subscribe(this.onCardCVVUpdate.bind(this)),
      observe(selectCardTypeName).subscribe(this.onCardTypeNameUpdate.bind(this)),
      observe(selectCardTypeImage).subscribe(this.onCardTypeImageUpdate.bind(this)),
      observe(selectInstallments).subscribe(this.onInstallmentsUpdate.bind(this)),
      observe(selectCurrentInstallmentId).subscribe(this.onSelectedInstallmentUpdate.bind(this)),
      observe(selectAgreement).subscribe(this.onAgreementUpdate.bind(this)),
      observe(selectPending).subscribe(this.onPendingUpdate.bind(this)),
      observe(selectErrors).subscribe(this.onErrorsUpdate.bind(this)),
    ];
  }

  unmount() {
    this.$cardInput.off('input.cardNumber');
    this.$cardMonthInput.off('input.cardMonth');
    this.$cardYearInput.off('input.cardYear');
    this.$cardCVVInput.off('input.cardCVV');
    this.$agreementInput.off('input.agreement');
    this.$cardHolder.off('input.cardHolder');

    store.dispatch(setCardNumber(''));
    store.dispatch(setCardMonth(''));
    store.dispatch(setCardYear(''));
    store.dispatch(setCardCVV(''));
    store.dispatch(setCardHolder(''));
    store.dispatch(setAgreement(false));

    this.validator.destroy();

    if (this.$cardInput[0].inputmask) {
      this.$cardInput[0].inputmask.remove();
    }
    if (this.$cardCVVInput[0].inputmask) {
      this.$cardCVVInput[0].inputmask.remove();
    }

    for (const observer of this.observers) {
      observer.unsubscribe();
    }
  }
}

export default MasterpassCardOption;