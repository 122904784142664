import URL from 'shop-packages/api/urls';
import mapStyle from "./mapStyle";

class Store {

  constructor() {
    this.mapID = 'mapArea';
    this.zoom = 5;
    this.lat = 38.9637;
    this.lng = 35.2433;
    this.center = {lat: this.lat, lng: this.lng};
    this.init();
  }

  loadGoogleMapScript = () => {
    let script = document.createElement('script');
    script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDa6W9KtR3pC2-JM50CXJJ7RBrPD0L6tiE&callback=initMap';
    script.async = true;

    window.initMap = () => {
      this.map = new window.google.maps.Map(document.getElementById(this.mapID), {
        center: this.center,
        zoom: this.zoom,
        styles: mapStyle,
        scrollwheel: false
      });

      this.mapInit();
      this.storeClick();
      this.storeManualClick();
      // this.cityChange();
    };
    document.head.appendChild(script);
  }

  init() {
    this.formSubmit();
    this.renderCityOption();
    this.loadGoogleMapScript();
  }

  storeManualClick = () => {
    const urlParam = new URLSearchParams(location.search);
    const lat = urlParam.get('lat');
    const long = urlParam.get('long');

    if (lat && long) {
      document.querySelectorAll('.js-stores-item')?.forEach(element => {
        if (element.dataset.latitude === lat && element.dataset.longitude === long) {
          element.click();
        }
      }); 
    }
  };

  storeClick = () => {
    let lng, lat;
    $(".js-stores").on("click", (e) => {
      const $this = $(e.currentTarget);
      if ($($this).hasClass("active")) {
        $($this).removeClass("active");
        this.zoomSet(this.lat, this.lng, this.zoom);
      } else {
        $($this).addClass("active");
        lat = parseFloat($($this).data('latitude'));
        lng = parseFloat($($this).data('longitude'));
        this.zoomSet(lat, lng, 15);
        $('.js-stores-list').scrollTop(0)
      }
      $('.js-stores-list').toggleClass('overflow-hidden');
    })
  };

  async getMapData() {
    let mapData = await fetch(URL.STORES);
    return await mapData.json();
  }

  async getCity() {
    let cityData = await fetch(`${URL.ADDRESS_CITY}?retailstore__isnull=false`);
    return await cityData.json();
  }

  // Sehir datasinin geldigi fonksiyon
  // async getTownship(cityID) {
  //   let cityData = await fetch(`${URL.ADDRESS_TOWNSHIP}?retailstore__isnull=false&city=${cityID}`);
  //   return await cityData.json();
  // }

  // Eger adresle arama isterlerse
  // setLocationByFormValue = (address) => {
  //   let geocoder = new google.maps.Geocoder();
  //   geocoder.geocode({'address': address}, (results, status) =>{
  //
  //     if (status == google.maps.GeocoderStatus.OK) {
  //       let lat = results[0].geometry.location.lat();
  //       let lng = results[0].geometry.location.lng();
  //       this.zoomSet(lat, lng, 10);
  //     }
  //   });
  // };

  renderCityOption = () => {
    this.getCity().then((data) => {
      let cityOptions = data.results.map(function (item) {
        return $(`<option value="${item.pk}">${item.name}</option>`);
      });
      cityOptions.unshift(`<option value=""></option>`);
      let citySelect = $('[name="city"]');
      citySelect.html(cityOptions);
    })
  };

  formSubmit = () => {
    $('.js-submit-button').on('click', (e) => {
      e.preventDefault();
      // Eger adresle arama isterlerse
      // let cityText = $('.js-city-select .select2-selection__rendered').text();
      // let townshipText = $('.js-township-select .select2-selection__rendered').text();
      // this.setLocationByFormValue()
      const formValue = $('.js-stores-form').serializeArray();
      const cityID = formValue[0].value;
      this.scrollList(cityID)
    })
  };

  scrollList = (cityID) => {
    const storeList = $('.js-stores-list');
    const listOffset = storeList.offset().top;
    storeList.scrollTop(0);
    storeList.removeClass('overflow-hidden');
    $(".js-stores").removeClass('active');
    const cityOffset = $(`#${cityID}`).offset().top - listOffset;
    storeList.scrollTop(cityOffset)
  };

  // Sehire bagli olarak ilceleri getiren fonksiyon
  // cityChange = () => {
  //   let citySelect = $('[name="city"]');
  //   citySelect.on('change', (e) => {
  //     const $this = $(e.currentTarget);
  //     const cityID = $($this).val();
  //     this.renderTownshipOption(cityID)
  //   })
  // };

  // Sehire bagli olarak ilcelerin seceneklerini olusturan fonksiyon
  // renderTownshipOption = (cityID) => {
  //   this.getTownship(cityID).then((data) => {
  //     let townshipOptions = data?.results.map(function (item) {
  //       return $(`<option value="${item.pk}">${item.name}</option>`);
  //     });
  //     let townshipSelect = $('[name="township"]');
  //     townshipSelect.html(townshipOptions);
  //   })
  // };

  mapInit = () => {
    const $mainMapContainer = $('#mapArea');
    let markerList = [];
    if ($mainMapContainer.length) {
      this.getMapData().then((response) => {
        for (let i = 0; i < response.results.length; i++) {
          markerList.push({
            ltt: response.results[i].latitude,
            long: response.results[i].longitude,
            url: response.results[i].absolute_url
          });
        }
        this.addMarkers(this.map, markerList);
      }).catch((err) => {
        console.log(err)
      });
    }
    this.addMarkers(this.map, markerList);
  };

  addMarkers = (map, markers) => {
    let marker;
    for (let i = 0; i < markers.length; i++) {
      marker = new window.google.maps.Marker({
        position: {lat: parseFloat(markers[i].ltt), lng: parseFloat(markers[i].long)},
        map: map,
        icon: '../../static_omnishop/img/map-icon.png',
        id: markers[i].url
      });
      this.markerClick(map, marker, i)
    }
  };

  markerClick = (map, marker, i) => {
    marker.addListener('click', (function (marker, i) {
      return function () {
        map.setZoom(15);
        map.setCenter(marker.getPosition());
      };
    }(marker, i)));
  };

  zoomSet(lat, lng, zoom) {
    const pt = new google.maps.LatLng(lat, lng);
    this.map.setCenter(pt);
    this.map.setZoom(zoom);
  }

}


export default Store;