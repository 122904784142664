import 'jquery-validation';

import store from 'shop-packages/redux/store';
import observe, { getValue } from 'shop-packages/redux/connectSelector';

import {
  setPaymentOption,
  setFundsTransferAccount,
  completeFundsTransfer,
} from 'shop-packages/redux/checkout/actions';
import {
  selectFundsTransferAccounts,
  selectCurrentFundsTransferAccount,
  selectAgreement,
  selectPending,
  selectErrors,
} from 'shop-packages/redux/checkout/selectors';

import { templateRenderer } from '../../../utils';
import { setAgreement, clearErrors } from 'shop-packages/redux/checkout/reducer';

class FundsTransferOption {
  observers = [];
  selectedAccountId = null;

  constructor({ pk }) {
    const accounts = getValue(selectFundsTransferAccounts);

    if (!accounts.length) {
      store.dispatch(setPaymentOption(pk));
    }

    this.accountItemTemplate = $(document.getElementById('FundsTransferAccountItemTemplate')).html();
    this.accountHolderItemTemplate = $(document.getElementById('FundsTransferHolderItemTemplate')).html();

    this.$accountsListContainer = $(document.getElementById('FundsTransferAccountsList'));
    this.$accountHolderContainer = $(document.getElementById('FundsTransferHolder'));

    this.$fundsTransferForm = $(document.getElementById('FundsTransferForm'));
    this.$completeButton = $(document.getElementById('FundsTransferCompleteButton'));
    this.$agreementInput = $('.js-checkout-agreement-input');

    this.$agreementInput.on('input.agreement', this.onAgreementChange.bind(this));

    this.initObservers();

    this.initFundsTransferForm();
  }

  onAccountsUpdate(accounts) {
    this.accounts = accounts;
    const content = this.accounts.map(({
      pk,
      bank: { name: bankName },
      iban,
    }) => {
      const checked = this.selectedAccountId === pk ? 'checked' : '';

      return templateRenderer(this.accountItemTemplate, {
        pk,
        bankName,
        iban,
        checked,
      });
    });

    this.$accountsListContainer.html(content.join(''));
    this.$accountsListContainer
      .find('.js-funds-transfer-account')
      .on('input', (e) => {
        const selected = $(e.currentTarget).val();
        store.dispatch(setFundsTransferAccount(selected));
      });
  }

  onSelectedAccountUpdate(selectedAccount) {
    if (!selectedAccount || !selectedAccount.pk) {
      return;
    }

    this.selectedAccountId = selectedAccount.pk;
    this.$accountsListContainer
      .find(`.js-funds-transfer-account[value="${this.selectedAccountId}"]`)
      .prop('checked', true);
  }

  onAgreementChange(e) {
    store.dispatch(
      setAgreement($(e.currentTarget).is(':checked'))
    );
  }

  onAgreementUpdate(agreement) {
    this.$agreementInput.prop('checked', agreement);
  }

  initFundsTransferForm() {
    this.validator = this.$fundsTransferForm.validate({
      submitHandler: (_, event) => {
        event.preventDefault;

        store.dispatch(clearErrors());
        store.dispatch(completeFundsTransfer());

        return false;
      },
      highlight: (element) => {
        const $element = $(element);

        if ($element.attr('type') === 'checkbox') {
          $element.parent().addClass('has-errors');
        }

        $element.addClass('has-errors');
      },
      unhighlight: (element) => {
        const $element = $(element);

        if ($element.attr('type') === 'checkbox') {
          $element.parent().removeClass('has-errors');
        }

        $element.removeClass('has-errors');
      },
      errorPlacement: () => {
        return false;
      },
      rules: {
        agreement: {
          required: true,
          normalizer: () => getValue(selectAgreement),
        }
      }
    });
  }

  onPendingUpdate(isPending) {
    !!isPending ?
      this.$completeButton
        .attr('disabled', 'disabled')
        .addClass('loading') :
      this.$completeButton
        .removeAttr('disabled')
        .removeClass('loading');
  }

  onErrorsUpdate(errors) {
    $('.js-error-*').text('').hide();
    for (const key in errors) {
      $(`.js-error-${key}`).text(errors[key]).show();
    }
  }

  initObservers() {
    this.observers = [
      observe(selectCurrentFundsTransferAccount).subscribe(this.onSelectedAccountUpdate.bind(this)),
      observe(selectFundsTransferAccounts).subscribe(this.onAccountsUpdate.bind(this)),
      observe(selectAgreement).subscribe(this.onAgreementUpdate.bind(this)),
      observe(selectPending).subscribe(this.onPendingUpdate.bind(this)),
      observe(selectErrors).subscribe(this.onErrorsUpdate.bind(this)),
    ];
  }

  unmount() {
    this.$agreementInput.off('input.agreement');
    store.dispatch(setAgreement(false));

    this.validator.destroy();

    for (const observer of this.observers) {
      observer.unsubscribe();
    }
  }
}

export default FundsTransferOption;