import { getValue } from 'shop-packages/redux/connectSelector';
import {
  selectCurrentAddress,
  selectCurrentBillingAddress,
  selectCurrentPaymentOption,
  selectShippingAmount,
  selectTotalAmount
} from 'shop-packages/redux/checkout/selectors';

class RemoteSalesAgreementModal {
  constructor() {
    $('#CheckoutProceedButton').on('click', this.fillModal.bind(this));
  }

  fillModal() {
    const shippingAddress = getValue(selectCurrentAddress);
    const billingAddress = getValue(selectCurrentBillingAddress);
    const paymentOption = getValue(selectCurrentPaymentOption);
    const shippingAmount = getValue(selectShippingAmount);
    const totalAmount = getValue(selectTotalAmount);

    const $salesItem = document.querySelectorAll('.js-contract-item');

    $salesItem.forEach(function(element) {
      if (element.id === 'SalesCustomerName') {
        $(element).html(billingAddress.is_corporate ? billingAddress?.company_name || '' : `${billingAddress?.first_name || ''} ${billingAddress?.last_name || ''}`);
      }
      if (element.id === 'SalesCustomerAddress') {
        $(element).html(`${billingAddress?.line || ''} ${billingAddress?.township.name || ''} ${billingAddress?.postcode || ''} ${billingAddress?.city.name || ''}`);
      }
      if (element.id === 'SalesCustomerPhone') {          
        $(element).html(`${billingAddress?.phone_number || ''}`);
      }
      if (element.id === 'SalesCustomerEmail') {
        $(element).html(`${billingAddress?.email || ''}`);
      }
      if (element.id === 'SalesShippingName') {
        $(element).html(shippingAddress?.is_corporate  ? shippingAddress?.company_name || '' : `${shippingAddress?.first_name || ''} ${shippingAddress?.last_name || ''}`);
      }
      if (element.id === 'SalesShippingAddress') {
        $(element).html(`${shippingAddress?.line || ''} ${shippingAddress?.township.name || ''} ${shippingAddress?.postcode || ''} ${shippingAddress?.city.name || ''}`);
      }
      if (element.id === 'SalesShippingPhone') {
        $(element).html(`${shippingAddress?.phone_number || ''}`);
      }
      if (element.id === 'SalesShippingEmail') {
        $(element).html(`${shippingAddress?.email} || ''`);
      }
      if (element.id === 'SalesBillingName') {
        $(element).html(billingAddress?.is_corporate ? billingAddress?.company_name || '' : `${billingAddress?.first_name || ''} ${billingAddress?.last_name || ''}`);
        return;
      }
      if (element.id === 'SalesBillingAddress') {
        $(element).html(`${billingAddress?.line || ''} ${billingAddress?.township.name || ''} ${billingAddress?.postcode || ''} ${billingAddress?.city.name || ''}`);
      }
      if (element.id === 'SalesBillingPhone') {
        $(element).html(`${billingAddress?.phone_number || ''}`);
      }
      if (element.id === 'SalesBillingEmail') {
        $(element).html(`${billingAddress?.email || ''}`);
      }
      if (element.id === 'SalesPaymentType') { 
        $(element).html(`${paymentOption?.payment_type_label || ''}`);
      }
      if (element.id === 'SalesShippingAmount') {
        $(element).html(`${shippingAmount} TL`);
      }
      if (element.id === 'SalesTotalAmount') {
        $(element).html(`${totalAmount} TL`);
      }
    });
  }
}

export default RemoteSalesAgreementModal;