export default class AccountCommon {
  constructor() {
    this.onControlOldOrders();
  }

  onControlOldOrders() {
    const oldOrders = document.querySelector('.account-menu__link-item.accountOldOrders');

    $.ajax({
      url: '/users/old-orders/',
      success(data) {
        const resultsLenght = data.results.length;
        const lengthStr = resultsLenght.toString();

        localStorage.setItem('orders', lengthStr);
      }
    });

    const getLength = localStorage.getItem('orders');

    if (+getLength > 0) {
      oldOrders.classList.remove('d-none');
    }
  }
}
