import { ApiClient } from 'shop-packages';
import ActionForm from '../../components/form-action';

export default class ChangeEmail {
  constructor(){
    this.$formContainer = $(document.getElementById('ChangeEmailFormWrapper'));
    this.$form = this.$formContainer.find('.js-change-email-form');
    this.$formMessage = this.$formContainer.find('.js-form-message');

    const form = ActionForm(this.$form);

    form.onError = err => {
      form.showErrors(err);
    };

    this.$form.submit(this.onFormSubmit.bind(this));
  }

  showEmailError(errorJSON) {
    if(errorJSON.non_field_errors) {
      $('p.password-error').text(errorJSON.non_field_errors[0]);
      $('p.password-error').removeClass('d-none');
    }
  }

  async onFormSubmit(e) {
    e.preventDefault();
    if($('input[name=change_email]').val() === $('input[name=repeat_change_email]').val()) {
      $('.password-input-wrapper p').text('');
      const formData = $(e.target).serializeArray();
      const emailValues = formData.map(({value}) => value);
      
      try {  
        await ApiClient.user.changeEmail(...emailValues);
        this.$form.addClass('d-none');
        this.$formMessage.removeClass('d-none');
      } catch (error) {
        this.showEmailError(error.responseJSON)
      }
    }else {
      $('p.email-error').text('E-posta adresleri uyuşmuyor.');
      $('p.email-error').removeClass('d-none');
    }
  }
};