const footer = {

  footerTitleClick: function () {
    const footerTitle = $('.js-footer-title');
    footerTitle.on('click', function () {
      $(this).toggleClass('active');
    })
  },

  init: function(){
    footer.footerTitleClick();
  },

};

export default function () {
  footer.init()
};


$('.js-analytic-data-social').on('click',function(){
  const item = this.dataset.key;
  dataLayerSocial(item);
})

function dataLayerSocial(e) {
  
  window.dataLayer.push({
    event: 'gaEvent',
    action: e,
    label: 'Click',
    category: 'Social Media'
  });
}

$('.js-analytic-subscription').on('click',function(){
  const item = $('.js-form-input').val();
  dataLayerubscription(item);
})

function dataLayerubscription(e) {
  
  window.dataLayer.push({
    event: 'gtm.subscription',
    action: 'Success',
    category: 'E Bulten',
    label: e
  });
}