export default function () {
  const mainMenu = $('.js-main-menu'),
        menuCloser = $('.js-menu-close'),
        menuOpener = $('.js-menu-button'),
        submenuBox = $('.js-mobile-submenu'),
        backButton = $('.js-back-button'),
        navigationBox = $('.js-mobile-navigation');

  menuOpener.on('click', function () {
    navigationBox.addClass('active');
    submenuBox.removeClass('active');
    document.querySelector('html').style.position = 'fixed';
  });

  backButton.on('click', function (e) {
    e.stopPropagation();
    submenuBox.removeClass('active');
  });

  menuCloser.on('click', function (e) {
    e.stopPropagation();
    submenuBox.removeClass('active');
    navigationBox.removeClass('active');
    document.querySelector('html').style.position = '';
  });

  mainMenu.on('click', function (e) {
    $(this).children().addClass('active');
  })

};
