import { ApiClient } from 'shop-packages';
import { templateRenderer } from '../../utils';
import ActionForm from '../../components/form-action';
import IMask from 'imask';

export default class AddressForm {
  constructor(){
    const $form = $(document.getElementById('addressForm_'));

    const form = ActionForm($form);
    
    form.onError = err => {
      form.showErrors(err);
    }

    this.$container = $(document.getElementById('addressFormWrapper'));
    this.$formModal = $(document.getElementById('accountAddressFormWrapper'));
    this.$addressWrapper = $(document.getElementById('addressWrapper'));
    this.$addressBoxesWrapper = $(document.getElementById('addressBoxesWrapper'));
    this.$addressBoxTemplate = $(document.getElementById('AccountAddressBoxTemplate')).html();
    this.$newAddressBoxTemplate = $(document.getElementById('AccountNewAddressTemplate')).html();
    this.$addressForm = this.$container.find('.js-address-form');
    this.$corporateRadio = this.$container.find('input[name=type]');
    this.$corporateInputs = this.$container.find('.js-corporate-inputs');
    this.$citiesSelect = this.$container.find('select[name=city]');
    this.$townshipSelect = this.$container.find('select[name=township]');
    this.$districtSelect = this.$container.find('select[name=district]');
    this.$phoneNumber = this.$container.find('input[name=phone_number]');
    this.$postCode = this.$container.find('input[name=postcode]');
    this.$backLink = this.$formModal.find('.js-back-address-list');

    this.$label_ = $(document.querySelectorAll('.input-label-wrapper label_'));
    this.$input_ = $(document.querySelectorAll('.input-label-wrapper input'));
    this.$textarea_ = $(document.querySelectorAll('.input-label-wrapper textarea'));

    this.$input_.focusin(this.onInputFocusIn.bind(this));
    this.$input_.focusout(this.onInputFocusOut.bind(this));
    this.$textarea_.focusin(this.onTextAreaFocusIn.bind(this));
    this.$textarea_.focusout(this.onTextAreaFocusOut.bind(this));

    this.init();
    this.fillCities();
    this.taxNumCheck();

    this.$citiesSelect.change(this.onChangeCitySelect.bind(this));
    this.$townshipSelect.change(this.onChangeTownshipSelect.bind(this));
    this.$addressForm.submit(this.onFormSubmit.bind(this));
    this.$corporateRadio.change(this.onChangeCorporateRadio.bind(this));
    this.$backLink.click(this.onClickBackLink.bind(this));
  }

  onInputFocusIn(e) {
    e.target.parentElement.parentElement.children[0].classList.remove('d-none');
  }
  onInputFocusOut(e) {
    e.target.parentElement.parentElement.children[0].classList.add('d-none');
  }
  onTextAreaFocusIn(e) {
    e.target.parentElement.parentElement.parentElement.children[0].classList.remove('d-none');
  }
  onTextAreaFocusOut(e) {
    e.target.parentElement.parentElement.parentElement.children[0].classList.add('d-none');
  }

  onClickBackLink(){
    this.addressFormDisplay('hide');
  }

  fillSelect(arry, firstOptionText, $elem, selectedId){
    $elem.html(
      `<option>${firstOptionText}</option>
      ${arry.data.results.map(option => `
        <option value="${option.pk}"${(selectedId && option.pk === selectedId) ? 'selected' : ''}>
          ${option.name}
        </option>
      `).join('')}`
    );

    $elem.parent('.select-wrapper').removeClass('loading');
  }

  onChangeCitySelect(e){
    this.clearSelectBoxes();

    this.fillTownship(e.target.value);
  
    if($(e.target).val() !== null && $(e.target).val() !== '') {
      this.$townshipSelect.parent().parent().removeClass('d-none');
    } 
  }

  onChangeTownshipSelect(e){
    this.$districtSelect[0].options.length = 1;
    this.$districtSelect[0].selectedIndex = 0;

    this.fillDistrict(e.target.value);
    this.$districtSelect.parent().parent().removeClass('d-none');
  }

  onChangeCorporateRadio(e){
    const activeRadio = this.$addressForm.find(`[name=type][value=company]`).is(':checked')

    activeRadio ? 
      this.$corporateInputs.removeClass('d-none') :
      this.$corporateInputs.addClass('d-none');
  }

  clearAddressForm(){
    this.$addressForm[0].reset();
    this.$addressForm.removeAttr('data-pk');
  }

  clearSelectBoxes(){
    this.$townshipSelect[0].options.length = 1;
    this.$townshipSelect[0].selectedIndex = 0;
    this.$districtSelect[0].options.length = 1;
    this.$districtSelect[0].selectedIndex = 0;
    this.$townshipSelect.parent().parent().addClass('d-none');
    this.$districtSelect.parent().parent().addClass('d-none');
  }

  init(){
    this.$deleteAddressLink = $('.js-delete-address');
    this.$updateAddressLink = $('.js-update-address');
    this.$newAddressLink = $('.js-new-address');
    this.modalDisplay = this.$newAddressLink.attr('data-modal');
    this.$updateAddressLink.click(this.onClickUpdateAddress.bind(this));
    this.$newAddressLink.click(this.onClickNewAddress.bind(this));
    this.$deleteAddressLink.click(this.deleteAddress.bind(this));
    this.$citiesSelect.val(null).trigger('change');
    
    this.onClickBackLink();

    this.$phoneNumberInputMask = IMask(this.$phoneNumber[0], {
      mask: '\\0\\ {5}00 000 00 00',
      placeholderChar: '_',
      lazy: true
    });
    this.$phoneNumber.attr("placeholder", "Cep Telefonu *").val('');
    
    this.$postCodeInputMask = IMask(this.$postCode[0], {
      mask: '00000',
      placeholderChar: '_',
      lazy: true
    });
    this.$postCode.attr("placeholder", "Posta Kodu").val('');
    
  }

  async deleteAddress(e){
    e.stopPropagation();

    const addressPk = $(e.target).closest('.js-address-box').attr('data-pk');

    try {
      await ApiClient.address.removeAddress(addressPk);
  
    } catch(e) {

    }
    await this.updateAddressBox();
  }

  async updateAddressBox(){
    const addresses = await ApiClient.address.fetchAddresses();

    const currentAddresses = addresses.data.results.map(({ pk, title, line, postcode, township, city, primary }) => {
      const checked = primary ? 'checked' : '';

      const address = `${line} ${township.name} ${postcode} ${city.name}`;
      const addressBox = templateRenderer(this.$addressBoxTemplate, { pk, title, address, checked });

      return addressBox;
    });

    currentAddresses.push(this.$newAddressBoxTemplate);
    this.$addressBoxesWrapper.html(currentAddresses.join(''));

    this.init();

    return true;
  }

  async onClickNewAddress(e){
    e.stopPropagation();
    this.clearAddressForm();
    this.clearSelectBoxes();
    await this.fillCities();
    this.addressFormDisplay('show', 'new-address');
  }

  addressFormDisplay(display, form=''){
    if (this.modalDisplay == 'true') {
      this.$formModal.modal(display);
    }
    else {
      if (display == 'show') {
        this.$addressWrapper.addClass('d-none');
        this.$formModal.attr('data-address-content', form).removeClass('d-none');
      }
      else {
        this.$addressWrapper.removeClass('d-none');
        this.$formModal.addClass('d-none');
      }
    }
  }

  async onClickUpdateAddress(e){
    e.stopPropagation();

    if(this.$phoneNumber[0].value.indexOf('_') >= 0) {
      this.$phoneNumber[0].value = '';
    }else {
      this.$phoneNumber[0].value = this.$phoneNumber[0].value.replace(/\s/g, '');
    }

    this.clearAddressForm();
    let address = {};

    const addressBox = $(e.target).closest('.js-address-box');
    const addressPk = addressBox.attr('data-pk');
    const addressData = addressBox.data();

    if (Object.keys(addressData).length > 10) {
      address.data = {
        city: addressData.city,
        township: addressData.township,
        district: addressData.district,
        phone_number: addressData.phoneNumber,
        first_name: addressData.firstName,
        last_name: addressData.lastName,
        line: addressData.line,
        title: addressData.title,
        postcode: addressData.postcode,
        is_corporate: addressData.isCorporate,
        company_name: addressData.companyName,
        tax_office: addressData.taxOffice,
        tax_no: addressData.taxNo,
      };
    } else {
      address = await ApiClient.address.fetchAddress(addressPk);
    }

    await this.fillCities(address.data.city);
    await this.fillTownship(address.data.city, address.data.township);
    await this.fillDistrict(address.data.township, address.data.district);

    this.fillAddressForm(address.data);

    const aptNoRegex = /Apt No: (.*?),/;
    const sokakRegex = /Sokak: (.*?)(,|$)/;
    const daireNoRegex = /Daire No: (.*?)(,|$)/;

    const aptNo = address.data.line.match(aptNoRegex);
    const sokak = address.data.line.match(sokakRegex);
    const daireNo = address.data.line.match(daireNoRegex);

    if (aptNo && aptNo.length > 0) {
      this.$addressForm.find(`[name=apartmanNo]`).val(aptNo[1]);
    }

    if (sokak && sokak.length > 0) {
      this.$addressForm.find(`[name=sokak]`).val(sokak[1]);
    }

    if (daireNo && daireNo.length > 0) {
      this.$addressForm.find(`[name=daireNo]`).val(daireNo[1]);
    }

    const startIndex = address.data.line.indexOf("Apt No:");
    if (startIndex !== -1) {
      const newData = address.data.line.substring(0, startIndex);
      this.$addressForm.find(`[name=line]`).val(newData);
    }

    this.$addressForm.attr('data-pk', addressPk);
    this.addressFormDisplay('show', 'update-address');
  }

  async onFormSubmit(e){
    e.preventDefault();
        
    const formValues = {};
    $.each($(e.target).serializeArray(), function() {
      formValues[this.name] = this.value;
    });

    if (formValues.phone_number.length < 15) return;

    const radioButton = this.$addressForm.find(`[name=type][value=company]`).is(':checked')

    if (radioButton && formValues.tax_no.length > 1 && formValues.tax_office.length > 1 && formValues.sokak.length > 1 && formValues.apartmanNo.length > 1 && formValues.daireNo.length >= 1 && formValues.line.length >= 10) {
      let formData = $(e.target).serialize();
      const addressPk = $(e.target).attr('data-pk');

      formData = this.addStreetandAptValue(formData);

      try{
        addressPk ? await ApiClient.address.updateAddress(addressPk, formData)
        : await ApiClient.address.addAddress(formData);
        
        await this.updateAddressBox();
        this.addressFormDisplay('hide');
      }
      catch(e){
      }
     
    } else if (!radioButton && formValues.sokak.length > 0 && formValues.apartmanNo.length > 0 && formValues.daireNo.length >= 1 && formValues.line.length >= 10) {
      let formData = $(e.target).serialize();
      const addressPk = $(e.target).attr('data-pk');

      formData = this.addStreetandAptValue(formData);

      try{
        addressPk ? await ApiClient.address.updateAddress(addressPk, formData)
        : await ApiClient.address.addAddress(formData);
        
        await this.updateAddressBox();
        this.addressFormDisplay('hide');
      }
      catch(e){
      }
    }

  }

  addStreetandAptValue(formData) {
    let dataParts = formData.split('&');
    let dataObject = {};

    for (let i = 0; i < dataParts.length; i++) {
      let parts = dataParts[i].split('=');
      dataObject[parts[0]] = decodeURIComponent(parts[1]);
    }
    
    dataObject.line += ` Apt No: ${dataObject.apartmanNo}, Sokak: ${dataObject.sokak}, Daire No: ${dataObject.daireNo}`;

    const newData = Object.entries(dataObject)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&');

    return newData
  }

  async fillAddressForm(address){
    for (const key of Object.keys(address)) {
      if (key == 'is_corporate') {
        this.$addressForm.find(`[name=type]`)[0].checked = false;

        address[key] ? this.$addressForm.find('.js-input-company')[0].checked = true : 
          this.$addressForm.find('.js-input-individual')[0].checked = true;

        this.$addressForm.find(`[name=type]`).trigger('change');
      }
      else {
        this.$addressForm.find(`[name=${key}]`).val(address[key]);
      }
    }
  }

  async fillCities(selectedCity){
    this.$citiesSelect
      .parent('.select-wrapper')
      .addClass('loading')
      .parent()
      .removeClass('d-none');

    const cities = await ApiClient.address.getCities();
    this.fillSelect(cities, 'İl Seçiniz', this.$citiesSelect, selectedCity);
  }

  async fillTownship(city_pk, selectedTownship){
    if(city_pk){
      this.$townshipSelect
      .parent('.select-wrapper')
      .addClass('loading') 
      .parent()
      .removeClass('d-none');

      const townships = await ApiClient.address.getTownships(city_pk);
      this.fillSelect(townships, 'İlçe Seçiniz', this.$townshipSelect, selectedTownship);

      return townships;
    }
  }

  async fillDistrict(township_pk, selectedDistrict){
    if(township_pk){
      this.$districtSelect
      .parent('.select-wrapper')
      .addClass('loading')
      .parent()
      .removeClass('d-none');

      const districts = await ApiClient.address.getDistricts(township_pk);
      this.fillSelect(districts, 'Mahalle Seçiniz', this.$districtSelect, selectedDistrict);

      return districts;
    }
  }

  taxNumCheck() {
    const taxInput = $("input[name=tax_no]");
    taxInput.keypress(function(event) {
      return (/\d/.test(String.fromCharCode(event.which)));
  });
  }
};