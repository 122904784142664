import { ApiClient } from 'shop-packages';
import 'jquery-ui.1.11.1/ui/datepicker';
import ActionForm from '../../components/form-action';
import IMask from 'imask';

export default class Profile {
  constructor(){
    this.$formContainer = $(document.getElementById('profileFormWrapper'));
    this.$form = this.$formContainer.find('.js-profile-form');
    this.$formMessage = this.$formContainer.find('.js-form-message');
    
    this.$input_ = $(document.querySelectorAll('.input-label-wrapper input'));
    this.$input_.focusin(this.onInputFocusIn.bind(this));
    this.$input_.focusout(this.onInputFocusOut.bind(this));
    
    this.$form.submit(this.onFormSubmit.bind(this))

    this.deleteAccountForm = $(document.querySelector('.delete-account-modal .js-delete-account-form'));
    this.deleteAccountForm.submit(this.deleteAccount.bind(this));

    const form = ActionForm(this.$form);
    
    form.onError = err => {
      form.showErrors(err);
    }
    
    $('input[name=date_of_birth]').datepicker({
      dateFormat: "yy-mm-dd",
      monthNames: [ "Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık" ],
      dayNamesMin: [ "Pa", "Pt", "Sl", "Ça", "Pe", "Cu", "Ct" ],
      firstDay:1,
      nextText: 'İleri',
      prevText: 'Geri',
      changeYear: true,
      yearRange: '1920:2030'
    });
    this.getbirthDay();
    this.toggleDeleteAccountModal()
  }

  deleteAccount(e){
    e.preventDefault();

    $.ajax({
      url:'/users/anonymize/',
      method:'PATCH',
      headers:{
        'X-CSRFToken': $(window.GLOBALS.csrf_token).val()
      }
    }).then(function() {
      window.location.href = location.origin;
    })
    .fail(function(jqXHR) {
      const error = jqXHR.responseJSON?.non_field_errors;

      if (jqXHR.responseJSON?.error_code == "user_100_2") {
        $('.error-text').text('Tamamlanmamış siparişiniz olduğundan şu anda hesap kapatma işleminiz gerçekleşememektedir.');

      }else if (error) {
        $('.error-text').text(error);
      }
      $('.error-text').removeClass('d-none');
    });
  }

  toggleDeleteAccountModal() {
    const accountDeleteButton = document.querySelector('.delete-account-modal-button');

    if(!accountDeleteButton) {
      return;
    }

    const modal = document.querySelector('.delete-account-modal');
    accountDeleteButton.addEventListener('click',function() {
      modal.show();
    });

    document.addEventListener('click',function(e) {
      const modalContent = document.querySelector('.delete-account-modal .pz-modal-dialog__content');

      if(!e.composedPath().includes(modalContent) && !e.composedPath().includes(accountDeleteButton) && modal.classList.contains('-visible')) {
        modal.dismiss();
        $('.error-text').addClass('d-none');
      }
    })
  }

  getbirthDay() {
    $.get( window.location.origin + '/users/profile/', function(res) {
      $('input[name=date_of_birth]').val(res.date_of_birth);
    });
  }
  onInputFocusIn(e) {
    e.target.parentElement.parentElement.children[0].classList.remove('d-none');
  }
  onInputFocusOut(e) {
    e.target.parentElement.parentElement.children[0].classList.add('d-none');
  }
  
  async onFormSubmit(e) {
    e.preventDefault();

    this.$accountCheckbox = $(document.querySelectorAll('.account-checkbox'));
    this.$accountCheckbox.each(function() {
      this.checked === true ? this.value = true : this.value = false;
    });
    
    let phoneNumber = document.getElementById('phone');
    phoneNumber.value = phoneNumber.value.replace(/\s/g,'');
    
    let formData = $(e.target).serialize();
    
    if(formData.includes('email_allowed')=== false) {
      formData += '&email_allowed=false';
    }if(formData.includes('sms_allowed')=== false) {
      formData += '&sms_allowed=false';
    }

    await ApiClient.user.updateProfile(formData);
        
    this.$formMessage.removeClass('d-none');

    setTimeout(() => this.$formMessage.addClass('d-none'), 3000);
    
  }
};