import { ApiClient } from 'shop-packages';
import * as actionTypes from 'shop-packages/analytics/action-types';
import {pushEvent} from "shop-packages/analytics";

import 'sharer.js';
import 'lightslider';

import '../assets/img/placeholder-image.jpg';
import '../assets/img/findinstorepin.svg';
import '../assets/img/sharebutton.svg';

import ProductVariants from '../components/product/variants';
import ProductSlider from '../components/product/slider';
import AddToCartButton from '../components/button-add-to-cart';
import ActionButton from '../components/button-action';
import Accordion from '../components/accordion';
import CombineVariant from './combine/index';
import { getAnalyticsProductFromLocalStorage, mobile } from '../utils';
import '@fancyapps/fancybox';
import {
  Benefits,
  BenefitItem
} from './benefit';
import { basketReducer } from 'shop-packages/redux/basket/selectors';
import observe from 'shop-packages/redux/connectSelector';
import PzCheckoutProvider from 'pz-checkout-providers';

export default class Product {
  constructor() {
    this.benefits = null;
    this.init();
    this.checkFavorite();

    window.onpopstate = history.onpushstate = () => {
      this.fetch(location.pathname + location.search);
    };
  }

  init() {
    new CombineVariant();
    this.$container = $(document.getElementById('ProductPage'));
    this.errorLabelElement = this.$container.find('.js-variant-error-label');
    this.$body = $('html, body');
    this.$window = $(window);
    this.$imagesContainer =  $(document.getElementById('jsProductImages'));
    this.$image = this.$imagesContainer.find('.js-product-image');
    this.mobile = mobile();
    this.productContent = this.$container.find('.js-product-content');

    if (this.mobile){
      this.initSlider();
    }

    // this.benefits = new Benefits();

    // const $benefitItems = document.querySelectorAll('.js-benefit');

    // $benefitItems.forEach($item => {
    //   const benefit = new BenefitItem($item);

    //   this.benefits.add(benefit);
    // });
    
    Accordion();
    this.fancyBox();
    this.getNotice();
    this.addToCart();
    this.variants();
    this.favoriteButton();
    this.initRecommendationSlider();
    this.findInStore();
    this.shareButton();
    this.closeButton();
    this.favoriteButtonSmall();
    this.googleAnalytics();
    this.fetchInstallment();

    window.Sharer.init();

    if ($('.js-checkout-providers').length) {
      this.initAkifast();
    }
  }

  initAkifast() {
    new PzCheckoutProvider({
      clearBasket: true,
      options: [
        {
          slug: 'akifast',
          options: {
            title: 'HIZLI ÖDE',
            className: 'js-btn-akifast-provider'
          }
        }
      ]
    });
    this.akifastCheck();
    this.akifastOnClick();
  }

  akifastCheck() {
    const $akifastBtn = this.$container.find('.js-btn-akifast-detail');
    if ($akifastBtn.length > 0) {
      const pk = $akifastBtn.parent().attr('data-product');
      if (pk && pk.length > 0) {
        $akifastBtn.removeClass('btn-akifast--disabled');
      } else {
        $akifastBtn.addClass('btn-akifast--disabled');
      }
    }
  }

  akifastOnClick() {
    $(document).off('click', '.js-btn-akifast-detail');
    $(document).on('click', '.js-btn-akifast-detail', function () {
      const pk = $(this).parent().attr('data-product');
      if (pk && pk.length > 0) {
        $('.js-variant-error').addClass('d-none');
        $(`.js-checkout-providers[data-product='${pk}'] .js-btn-akifast-provider`).trigger('click');
      } else {
        $('.js-variant-error').removeClass('d-none');
      }
    });
  }

  googleAnalytics() {
    try {
      const element = document.querySelector('.js-analytics-product');
      const combineProductsAnalyticItems = document.querySelectorAll('.js-analytics-product.combine-product');

      if (combineProductsAnalyticItems.length > 0) {
        combineProductsAnalyticItems.forEach(item => {
          const ecommerce = JSON.parse(item.textContent);
          setTimeout(() => {
            window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push({
              event: 'view_item',
              ecommerce
            });
          }, 200);
        });
        return;
      } 

      const ecommerce = JSON.parse(element.textContent);
      const { items } = ecommerce;
      const item = items[0];
      ecommerce.value = +ecommerce.value;
      item.price = +item.price;
      item.discount = +item.discount;
      item.discount_rate = +item.discount_rate;
      
      const analyticProduct = getAnalyticsProductFromLocalStorage(item?.item_id);
      item.index = analyticProduct?.index || 0;
      item.item_list_id = analyticProduct?.listIdx || item.item_list_id;
      item.item_list_name = analyticProduct?.listName || item.item_list_name;
      setTimeout(() => {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'view_item',
          ecommerce
        });
      }, 200);
    } catch (error) {
      console.log(error);
    }
  }

  findIndexByBasecode(analyticsProducts, item) {
    const foundProduct = analyticsProducts?.find(product => product.basecode === item);
    if (foundProduct) {
      return foundProduct.index;
    }
    return null;
  }

  fancyBox() {
    if (!this.mobile) {
        $('[data-fancybox="js-zoom-gallery"]').fancybox({
            loop: true,
            arrows: true,
            infobar: false,
            margin: [44, 0, 22, 0],
            buttons: [
                'close',
            ],
            thumbs: {
                autoStart: true,
                axis: 'x'
            }
        })
    }
  }

  initScrollContent(){
    let contentOffsetTop = this.productContent.offset().top;

    this.$body.animate({ scrollTop: contentOffsetTop });
  }

  variants() {
    this.variantsController = undefined;
    this.variantsController = new ProductVariants(this.$container);
    this.variantsController.onChange = params => {
      this.$container.find('.js-add-to-cart')
        .attr('disabled', 'disabled')
        .addClass('loading');

      this.$container.find('.js-product-get-notice')
        .attr('disabled', 'disabled')
        .addClass('loading');

      const query = `${location.pathname}?${params.join('&')}`;
      history.pushState(null, document.title, query);
      this.fetch(query);
    };
  }

  initSlider(){
    ProductSlider('#jsProductImages', {
      controls: false,
      gallery: false,
      pager: false,
      item: 1,
      slideMargin: 8
    });
  }

  initRecommendationSlider() {
    $('.js-reco-slider').each((_, el) => {
      $(el).lightSlider({
        item: 4,
        loop: true,
        galleryMargin: 20,
        autoWidth: false,
        controls: false,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              item: 3
            }
          },
          {
            breakpoint: 768,
            settings: {
              item: 2
            }
          },
          {
            breakpoint: 575,
            settings: {
              item: 1.5
            }
          },
        ]
      });
    });
  }

  scrollAnimation(thumbnailOffsetTop, $container){
    this.$window.scroll(function() {
      let currentScrollTop = window.scrollY;
      let scrolling = ($container.parent().height() - $container.height()) > currentScrollTop ? true : false;


      let scrollTop = currentScrollTop > thumbnailOffsetTop ? 
        ( currentScrollTop - thumbnailOffsetTop ) 
        : 0;

      if (scrolling)
        $container.stop(true, true).animate({top: scrollTop });
    });
  }

  addToCart() {
    const $addToCart = this.$container.find('.js-add-to-cart');

    observe(basketReducer).subscribe(({ basket, pending }) => {
      const { basketitem_set } = basket;
      const basketItemsPk = [];

      basketitem_set?.forEach((item) => {
        basketItemsPk.push(item.product.pk);
      });
      
      
      if ($addToCart.data('base-code') && basketitem_set?.length > 0 && !basketItemsPk.includes($addToCart.data('product'))) {
        basketitem_set?.forEach((item) => {
          if (item.product['base_code'] === $addToCart.data('base-code') && $addToCart.data('color') == item.product.attributes['integration_renk_kodu']) {
            $addToCart.data('is-same', true);
          }
        });
      } else {
        $addToCart.data('is-same', false);
      }
    });
  
    if (!$addToCart.length) {
      return;
    }
    const addToCartButton = AddToCartButton($addToCart, this.benefits);

    addToCartButton.success = (response) => {
      const { data : { basket } = {} } = response;

      if(this.mobile){
        $('.js-mini-basket-mobile').fadeIn('500');
      }else{
        $('.js-mini-basket-full-screen-content').css('display', 'block');
      }
      
      const htmlBodySelector = $('html,body');
      htmlBodySelector.addClass('overflow-hidden');

      $('.analytics-data').each(function() {
        const analyticsData = JSON.parse($(this).text());
        if (!analyticsData.type) return;
        if(analyticsData.type === actionTypes.productViewed){
          const productData = {
            type : actionTypes.productAdded ,
            payload : {
              products : [{
                ...analyticsData.payload.products[0],
                quantity : 1,
                cart_id : basket.pk
              }]
            }
          };
          pushEvent(productData)
        }
      });

      let cartProducts = [] ;
      basket.basketitem_set.forEach((item) => {
        let obj = {
          cart_id: basket.pk,
          itemgroup_id: item.product.attributes.integration_renk_kodu && item.product.attributes.integration_beden1 ? item.product.base_code + item.product.attributes.integration_renk_kodu + item.product.attributes.integration_beden1 : item.product.base_code,
          productPK: item.product.pk,
          sku: item.product.sku,
          quantity: item.quantity,
          price: item.product.price,
          variantid: item.product.attributes.filterable_renk
        };
        cartProducts.push(obj)
      });

      const cartDetailData = {
        type : actionTypes.cartDetailEvent,
        payload : cartProducts
      };

      pushEvent(cartDetailData)

    };

    addToCartButton.error = (error) => {
      if (this.errorLabelElement) {
        this.errorLabelElement.addClass('red');
        const errorLabel = this.errorLabelElement?.attr('data-variant');
        errorLabel && this.errorLabelElement.text(errorLabel + ' Seçiniz');
      }

      if(this.mobile)
        this.initScrollContent();
    };


    const $mobilBasketPopupClose = $('.js-mini-basket-mobile-continue');
    $mobilBasketPopupClose.on('click', () => {
      const htmlBodySelector = $('html,body');

      htmlBodySelector.removeClass('overflow-hidden');
      $('.js-mini-basket-mobile').fadeOut('500');
    });
  }

  async checkFavorite() {
    if (!window.GLOBALS.userLoggedIn) {
      return;
    }

    const $favouriteElemSmall = $(document.getElementById('favouriteProductElemSmall'));
    const $favouriteElem = $(document.getElementById('favouriteProductElem'));
    const productPk = $favouriteElem.attr('data-product-pk');

    const response = await ApiClient.favorites.fetchFavouriteStatus([productPk]);

    if (response.data.results && response.data.results.length) {
      const favoritePk = response.data.results[0].pk;
      $favouriteElem.attr('data-favorite-pk', favoritePk);
      $favouriteElem.addClass('active');
      $favouriteElem.html(`<i class="heart-outline"></i> Listeden Çıkart`);
      $favouriteElemSmall.attr('data-favorite-pk', favoritePk);
      $favouriteElemSmall.addClass('active');
      $favouriteElemSmall.html(`<i class="heart-outline"></i>`);
    }
  }

  async favoriteButton() {
    if (!window.GLOBALS.userLoggedIn) {
      return;
    }

    const $favouriteElem = $(document.getElementById('favouriteProductElem'));

    $favouriteElem.on('click', async () => {
      const isActive = $favouriteElem.hasClass('active');
      const productPk = $favouriteElem.attr('data-product-pk');
      const favoritePk = $favouriteElem.attr('data-favorite-pk');
      const stockId = $favouriteElem.attr('data-stock-id');

      if (isActive) {
        await ApiClient.favorites.removeFavourite(favoritePk);
        $favouriteElem.removeAttr('data-favorite-pk');
        $favouriteElem.removeClass('active');
        $favouriteElem.html(`<i class="heart-outline"></i> Listeye Ekle`);
      } else {
        const response = await ApiClient.favorites.addFavourite(productPk);
        $favouriteElem.attr('data-favorite-pk', response.data.pk);
        $favouriteElem.addClass('active');
        $favouriteElem.html(`<i class="heart-outline"></i> Listeden Çıkart`);
        this.pushFavoriteAnalyticEvent()
      }
    });
  }

  async favoriteButtonSmall() {
    if (!window.GLOBALS.userLoggedIn) {
      return;
    }

    const $favouriteElemSmall = $(document.getElementById('favouriteProductElemSmall'));

    $favouriteElemSmall.on('click', async () => {
      const isActive = $favouriteElemSmall.hasClass('active');
      const productPkSmall = $favouriteElemSmall.attr('data-product-pk');
      const favoritePkSmall = $favouriteElemSmall.attr('data-favorite-pk');

      if (isActive) {
        await ApiClient.favorites.removeFavourite(favoritePkSmall);
        $favouriteElemSmall.removeAttr('data-favorite-pk');
        $favouriteElemSmall.removeClass('active');
        $favouriteElemSmall.html(`<i class="heart-outline">`);
      } else {
        const responseSmall = await ApiClient.favorites.addFavourite(productPkSmall);
        $favouriteElemSmall.attr('data-favorite-pk', responseSmall.data.pk);
        $favouriteElemSmall.addClass('active');
        $favouriteElemSmall.html(`<i class="heart-outline"></i>`);
        this.pushFavoriteAnalyticEvent()
      }
    });
  }

  pushFavoriteAnalyticEvent() {
    try {
      const productAnalyticsData = JSON.parse($('.js-analytics-product').text());
      productAnalyticsData.value = +productAnalyticsData.value;
      const item =  productAnalyticsData.items[0];
      item.price = +item.price; 
      item.discount = +item.discount; 
      item.index = +item.index; 
      item.item_last_price = +item.item_last_price; 
      
      const analyticProduct = getAnalyticsProductFromLocalStorage(item?.item_id);
      item.item_list_id = analyticProduct?.listIdx || item.item_list_id;
      item.item_list_name = analyticProduct?.listName || item.item_list_name;
      window.dataLayer.push({ event: 'add_to_wishlist', ecommerce:productAnalyticsData });
    } catch (error) {}
  }

  getNotice() {
    const $getNotice = this.$container.find('.js-product-get-notice');

    if (!$getNotice.length) {
      return;
    }

    const getNoticeButton = new ActionButton($getNotice);

    getNoticeButton.action = ApiClient.product.getStockNotice;
    getNoticeButton.beforeAction = () => {
      if (!window.GLOBALS.userLoggedIn) {
        location.href = `/users/login/?next=${location.pathname}`;
        return;
      }

      getNoticeButton.actionParams = [
        $getNotice.data('product'),
      ];
    }

    getNoticeButton.onSuccess = () => {
      const getNoticeModal = $(document.getElementById('ProductStockNoticeModal'));
      getNoticeModal.modal('show');
    }
  }

  fetchInstallment() {
    const installmentUrl = $('.js-installment').data('installment-url');
    const container = $('.js-installment-content');
    $.ajax({
      url: installmentUrl,
      complete: () => {
    $('.js-installment-loading').hide();
      },
      success: (response) => {
        this.installmentTemplate(container, response.results);
      }
    });
  }
  
  installmentTemplate($container, result) {
    let installmentHtml = '';
  
    const slugs = result?.map(item => { return item.card_type.slug });

    result?.map((item,index) => {
      if (slugs.indexOf(item.card_type.slug) == index && item.installments.length > 1) {
      installmentHtml += `
        <div class="installment-content__wrapper">
          <div class="installment-content__bank-info-wrapper js-installment-accordion-title">
            <img class="installment-content__card-logo" src="${item.card_type.logo}" />
            <i class="installment-content__icon"> </i>
          </div>
          <div class="installment-content__table">
            <div class="installment-content__table-header">
              <p>Taksit Sayısı</p>
              <p>Aylık Ödeme</p>
              <p>Toplam Tutar</p>
            </div>
      `;
  
        item.installments.map((installment) => {
          installmentHtml += `
            <div class="installment-content__table-row">
              <p class="installment-table-cell">${installment.installment_count}</p>
              <p class="installment-table-cell">${installment.single_installment_amount} TL</p>
              <p class="installment-table-cell">${installment.total_amount} TL</p>
            </div>
          `;
        });
  
      installmentHtml += `
          </div>
        </div>
      `;
      }
    });
  
    $container.prepend(installmentHtml);

    $('.js-installment-accordion-title').click(function(){
      $(this).parent().toggleClass('active');
    });
  }

  findInStore() {
    const isCombinePage = document.querySelector('.js-combin-products');
    
    if (!isCombinePage) {
      let productPk = '';
      if($('.js-variants-container[data-variant-key="integration_beden1"] .js-variant.selected').length  > 0) {
        productPk = $('.js-variants-container[data-variant-key="integration_beden1"] .js-variant.selected').attr('data-pk');
      } else {
        productPk = document.getElementById('ProductPage').getAttribute('data-pk');
      }
      const city = document.getElementById('findInStoreCity');
      const town = document.getElementById('findInStoreTown');
      const resultList = $('.js-result-list');
      const findInStoreButton = document.querySelectorAll('.js-find-in-store');
      const variantColor = findInStoreButton[0].getAttribute('data-variant-renk');
      const variantSize = findInStoreButton[0].getAttribute('data-variant-beden');
      const store = [];
      findInStoreButton.forEach(item => {
        item.addEventListener('click', () => {
          $.ajax({
            url: '/retail_store_stock/' + productPk + '/',
            method: 'GET',
            dataType: 'json',
            success: (response) => {
              $('.js-find-in-store-loading').css('display', 'none');
              if(response.length <= 0) {
                $('.js-find-in-store-response-error').removeClass('d-none');
              } else {
                $('.js-find-in-store-response-error').addClass('d-none');
                $('.js-find-in-store-response').removeClass('d-none');
                const cities = [];
                store.push(response)
                $.each(response, function(index, item) {
                  if ($.inArray(item.township.city.name, cities) === -1) {
                    cities.push(item.township.city.name);
                  }
                })
                cities.forEach(element => {
                  $(city).append($('<option>', {
                    value: element,
                    text: element
                  }));
                });
              }
            }
          })
        })
      })
      
      $(city).on('change', function() {
        let selectedVal = $(this).val();
        $(town).find('option').remove().end().append('<option>İlçe Seçiniz</option>');
        resultList.empty();
        store[0].forEach(item => {
          if(item.township.city.name == selectedVal) {
            $(town).append($('<option>', {
              value: item.township.name,
              text: item.township.name
            }));
            
            resultList.append(`<div class="result-list__item">
              <span>${item.name}</span>
              <span>${variantColor} - ${variantSize}</span>
              <span>${item.stock}</span>
              <span><a href="/address/stores/?township=${item.township.pk}&lat=${item.latitude}&long=${item.longitude}">Mağaza Detay</a></span>
            </div>`);
            
          }
        });
      })

      $(town).on('change', function() {
        let selectedTownVal = $(this).val();
        let selectedCityVal = $(city).val();
        resultList.empty();
        store[0].forEach(item => {
          if(item.township.name == selectedTownVal && selectedCityVal == item.township.city.name) {
            resultList.append(`<div class="result-list__item">
              <span>${item.name}</span>
              <span>${variantColor} - ${variantSize}</span>
              <span>${item.stock}</span>
              <span><a href="/address/stores/?township=${item.township.pk}&lat=${item.latitude}&long=${item.longitude}">Mağaza Detay</a></span>
            </div>`);
            
          }
        });
      })
    }
  }

  shareButton() {
    $('.js-social-menu').on('click', function() {
      $('.social-sm-size').css('display', 'block');
    })
  }

  closeButton() {
    $('.js-social-button-close').on('click', function() {
      $('.social-sm-size').css('display', 'none');
    })
  }


  async fetch(query) {
    const response = await ApiClient.misc.fetchPageHtml(query);

    const result = $(response).find('#ProductPage').html();
    this.$container.html(result);
    this.init();
  }
};

$('.js-analytic-share-social').on('click',function(){
  const item = this.dataset.key;
  dataLayerSocial(item);
})

function dataLayerSocial(e) {
  
  window.dataLayer.push({
    event: 'gaEvent',
    action: e,
    label: 'Share',
    category: 'Social Media'
  });
}
