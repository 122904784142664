import ActionElement from '../element-action';

class ActionButton extends ActionElement {
  disableOnAction = true;
  showSpinnerOnAction = true;

  constructor($element) {
    super($element);

    this.$element.addClass('pz-button__action');
  }

  set disabled(value) {
    !!value ?
      this.$element.attr('disabled', 'disabled') :
      this.$element.removeAttr('disabled');
  }

  set spin(value) {
    !!value ?
      this.$element.addClass('loading') :
      this.$element.removeClass('loading');
  }

  _bindAction() {
    this.removeEvent('click.action');
    this.setEvent('click.action', this._onClick.bind(this));
  }

  async _onClick() {
    if (this.disableOnAction) {
      this.disabled = true;
    }

    if (this.showSpinnerOnAction) {
      this.spin = true;
    }

    await this.runAction();

    this.disabled = false;
    this.spin = false;
  }
}

export default ActionButton;
