import './index.scss';
import 'bootstrap';
import 'lightslider';
import 'select2';
import 'jquery-confirm';

import { ApiClient } from 'shop-packages';
import store from "shop-packages/redux/store";
import { fetchBasket } from "shop-packages/redux/basket/actions";

import '../assets/img/favicon.png';
import '../assets/img/map-icon.png';
import '../assets/img/last_border.svg';
import '../assets/img/first_border.svg';
import '../assets/img/smart_first.png';
import '../assets/img/smart_third.png';
import '../assets/img/smart_second.png';
import '../assets/img/icon-white.svg';
import '../assets/img/icon-facebook.svg';
import '../assets/img/icon-youtube.svg';
import '../assets/img/icon-twitter.svg';
import '../assets/img/icon-pinterest.svg';
import '../assets/img/icon-instagram.svg';
import '../assets/img/mng_logo.png';
import '../assets/img/500.png';
import '../assets/img/logo.png';
import '../assets/img/star.png';
import '../assets/img/logo-x.png';
import '../assets/img/app-store.png';
import '../assets/img/google-play.png';
import '../assets/img/Group-115.png';
import '../assets/img/Group-105.png';
import '../assets/img/Group-105-two-status.svg';
import '../assets/img/Group-98.png';
import '../assets/img/Group-85.png';
import '../assets/img/Group-117.svg';
import '../assets/img/user.svg';
import '../assets/img/pin.svg';
import '../assets/img/mail.svg';
import '../assets/img/check.svg';
import '../assets/img/phone.svg';
import '../assets/img/calendar.svg';
import '../assets/img/clock.svg';
import '../assets/img/youtube.png';
import '../assets/img/twitter.png';
import '../assets/img/facebook.png';
import '../assets/img/instagram.png';
import '../assets/img/kargom-sende.png';
import '../assets/img/packupp.png';
import '../assets/img/yurtici-kargo.png';
import '../assets/img/kg.png';
import '../assets/img/kg-logo.png';
import '../assets/img/close.svg';
import '../assets/img/gpay.png';
import '../assets/img/whatsapp.png';
import '../assets/img/whatsapp-smokin.webp';
import '../assets/img/arrow-up.svg';
import '../assets/img/trash.svg';
import '../assets/img/iyzico-logo.png';
import '../assets/img/iyzico-lock.png';
import '../assets/img/edit.png';

import {
  HttpClient
} from 'pz-core';
import Router from './router';
import Analytics from '../analytics'
import Common from '../common';
import redirectApp from '../components/redirect-popup/index';
// import Autocomplete from '../components/autocomplete';
import MiniBasket from "../partials/user-panel/mini-basket";
import Subscription from '../components/subscription';
import lazyImages from '../common/lazy-images';
window.gettext = (str) =>  str;
$(document).ready(() => {
  ApiClient.setBaseUrl('http://localhost:8080/');
  ApiClient.setCsrfToken($(window.GLOBALS.csrf_token).val());

  Router();
  Analytics();
  Common();
  redirectApp();
  Countdown();
  lazyImages();
  // Autocomplete();
  new MiniBasket();
  const subscription = new Subscription();

  $('.custom-select').select2({
    minimumResultsForSearch: Infinity
  });

  if(!window.GLOBALS.page?.includes('checkout')) {
    store.dispatch(fetchBasket());
  }

  subscription.onSubmit();
  const csrfTokenHandlerMiddleware = {
    key: 'csrf-token-handler-middleware',
    request: (config) => {
      const csrfToken = ((fragment) => {
        fragment.innerHTML = window.GLOBALS.csrf_token;
        return fragment.querySelector('input').value;
      })(document.createElement('fragment'));
      config.headers['X-CSRFToken'] = csrfToken;
      return config;
    },
  };
  HttpClient.use(csrfTokenHandlerMiddleware);
  window.showAkifast = () => {
    if ($('.js-akifast-wrapper').length) {
      $('.js-akifast-wrapper').removeClass('d-none');
    }
  };
});

function Countdown() {

  var elem = $('.home-band-countdown')

  if (elem.length) {
    elem.each(function () {
      var thisCounter = $(this),
        d = new Date(),
        now = new Date().getTime(),
        exDate = new Date(thisCounter.data('ex-date')).getTime(),
        days, hours, minutes, seconds,
        distance = exDate - now;
      distance = Math.ceil(distance / 1000)
      var countdown = setInterval(function () {
        --distance
        if (distance > 0) {
          elem.addClass('active');

          days = Math.floor(distance / 60 / 60 / 24) 
          hours = Math.floor(distance / 60 / 60) % 24
          minutes = Math.floor(distance / 60) % 60
          seconds = distance % 60

          days < 10 ? thisCounter.find('.js-countdown-day').text("0" + days) : thisCounter.find('.js-countdown-day').text(days);
          hours < 10 ? thisCounter.find('.js-countdown-hour').text("0" + hours) : thisCounter.find('.js-countdown-hour').text(hours);
          minutes < 10 ? thisCounter.find('.js-countdown-minute').text("0" + minutes) : thisCounter.find('.js-countdown-minute').text(minutes);
          seconds < 10 ? thisCounter.find('.js-countdown-second').text("0" + seconds) : thisCounter.find('.js-countdown-second').text(seconds);
        }else {
          elem.removeClass('active');
        }
      }, 1000)
    });
  }
}