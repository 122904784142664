import store from 'shop-packages/redux/store';
import observe, { getValue } from 'shop-packages/redux/connectSelector';

import { fetchCheckout } from 'shop-packages/redux/checkout/actions';
import { selectIsReady, selectErrors, selectCurrentPaymentOptionId } from 'shop-packages/redux/checkout/selectors';

import { BasketSummary } from '../../basket/summary';
import BasketList from '../../basket/basket-list';
import BasketListItem from '../../basket/basket-list-item';

import Tabs from './tabs';
import Summary from './summary';
import RemoteSalesAgreementModal from './salesAgreement';
import Gift from './gift';
import PzClickCollect from './click-collect';
import MasterPass from 'pz-masterpass';
import { gettext } from '../../locales/gettext';
import { ApiClient } from 'shop-packages';
import $U from 'shop-packages/api/urls';


const fetchIndexPage = () => ApiClient.request('GET', $U.CHECKOUT_BASE, {
  page: 'IndexPage',
});

const fetchCustomCheckout = async () => {
  const indexPage= await fetchIndexPage();
  new Gift();
};


export default class Checkout {
  constructor() {
    this.$nonFieldErrors = $('.js-error-non_field_errors');
    this.iframe();

    fetchCustomCheckout();
    const $$selectIsReady = observe(selectIsReady)
      .subscribe(isReady => {
        if (isReady) {
          this.onReady();
          $$selectIsReady.unsubscribe();
          this.setLastState();
        }
      });

    this.onErrors = observe(selectErrors).subscribe(this.onErrors.bind(this));

    new MasterPass({
      validateCreditCardForm: () => $('.js-credit-card-form').find('label.error:visible').length === 0,
      validateAgreementCheckbox: () => $('.js-credit-card-form .js-mp-checkout-agreement-input').is(':checked'),
      infoIconClassName: 'fa fa-info-circle',
      deleteIconClassName: 'fa fa-trash-alt',
      otpModalIconClassName: 'fa fa-mobile-alt',
      language: 'tur',
      translation: {
        youHaveRegisteredCards: gettext('MasterPass\'e kayıtlı kartlarınız bulunmaktadır.'),
        linkCards: gettext('MasterPass hesabınıza kayıtlı kartlarınız bulunmaktadır. Kartlarınızı kullanmak ister misiniz?'),
        payWithNewCard: gettext('Başka bir kart ile ödemek istiyorum.'),
        payWithMyMasterPassCard: gettext('MasterPass\'e kayıtlı kartımla ödeme yapmak istiyorum.'),
        agreementText: gettext('Kartımı Mastercard’ın Masterpass kart saklama altyapısı ile saklamak ' +
          've sonraki alışverişlerde kullanmak istiyorum. Masterpass Kullanım Koşulları’nı Kabul ediyorum.'),
        masterPassCardName: gettext('Kartınıza vermek istediğiniz isim'),
        pleaseSelectInstallment: gettext('Lütfen taksit seçin'),
        pleaseSelectCard: gettext('Lütfen kart seçin'),
        pleaseEnterCardName: gettext('Lütfen kart ismi girin'),
        deleteCardConfirmation: gettext('Kartı silmek istediğinize emin misiniz?'),
        threeDTransactionFailed: gettext('3D ödeme hatalı.'),
        resendSms: gettext('Tekrar SMS gönder'),
        confirmationCode: gettext('Doğrulama kodu'),
        delete: gettext('SİL'),
        save: gettext('KARTIMI KAYDET'),
        confirm: gettext('Onayla'),
        use: gettext('Kullan'),
      },
      responseMessages: {
        '5001': gettext('Bankanızdan gelen tek kullanımlık şifreyi giriniz.'),
        '5008': gettext('Telefon numarası doğrulaması için tek seferlik şifreniz.')
      },
      /* eslint-disable max-len */
      infoText: `
        <div class="masterpass-info-text">
          <p>
            <b>Masterpass KK:</b>
            <a href="https://www.masterpassturkiye.com/TermsAndConditions.aspx" class="text-underline btn-link" target="_blank">https://www.masterpassturkiye.com/TermsAndConditions.aspx</a>
          </p>
          <h4>Masterpass Nedir?</h4>
          <p>Masterpass, kart bilgilerinizi Mastercard alt yapısı içinde güvenli bir şekilde saklayarak, hızlı ve kolay ödeme yapmanızı sağlayan online kart saklama yöntemidir. Kullanıcı adı ve şifre ile kaydolduğunuz bir mobil uygulama değildir. Kullanmak için herhangi bir ücret ödemeniz gerekmemektedir.</p>
          <p>Binlerce Masterpass üyesi işyerinde (<a href="https://www.mastercard.com.tr/tr-tr/tuketiciler/odeme-secenekleri/masterpass/is-yerleri.html" class="text-underline btn-link" target="_blank">https://www.mastercard.com.tr/tr-tr/tuketiciler/odeme-secenekleri/masterpass/is-yerleri.html</a>) hızlı ve kolay ödeme yapabilmek için, ödeme adımında bir defaya mahsus cep telefonu numaranızla birlikte kart bilgilerinizi girmeniz ve “Masterpass’e kaydet” seçeneğine tıklamanız gerekmektedir. Kart bilgileriniz ancak ve ancak bankanız tarafından iletilen tek kullanımlık şifre ile doğrulanarak eklenebilir. Bu sayede bilginiz ve onayınız dışında Masterpass’e kartınız kaydedilemez.</p>
          <p>Alternatif olarak, kartınızı destekleyen bankaların mobil Bankacılık uygulamaları (<a href="https://www.mastercard.com.tr/tr-tr/isletmeler/bankalar-ve-kredi-kuruluslari/guvenli-dijital-odeme.html" class="text-underline btn-link" target="_blank">https://www.mastercard.com.tr/tr-tr/isletmeler/bankalar-ve-kredi-kuruluslari/guvenli-dijital-odeme.html</a>) vasıtasıyla da Masterpass’e ekleyebilirsiniz.</p>
          <p>Farklı bir Masterpass üyesi işyerinde aynı cep telefonu numaranızı kullandığınızda Masterpass’e kayıtlı kartlarınızla ödemenizi tamamlayabilirsiniz. Bunun için bir defaya mahsus bankanız tarafından gönderilen tek kullanımlık şifre ile Masterpass hesabınızı doğrulamanız gerekmektedir.</p>
          <p>Masterpass’e kaydettiğiniz kart, sizin hızlı ve kolay ödeme yapmanızı sağlar, her seferinde 16 haneli kart numarası, son kullanma tarihi, vb. bilgileriniz girmenize gerek kalmaz. Ayrıca hiçbir zaman siz satın almayı onaylamadan ödeme işlemiz doğrudan tamamlanmaz.</p>
          <p>Masterpass’e desteklenen tüm bankaların Kredi, bankamatik ve ön ödemeli kartlarını kaydedebilirsiniz. Tüm liste için <a href="https://masterpass.com/tr-tr/faqs/payment-processing-shopping.html" class="text-underline btn-link" target="_blank">https://masterpass.com/tr-tr/faqs/payment-processing-shopping.html</a></p>
        </div>  
      `,
    });
    this.setDevice();
    this.initSaveSalesDocumentAction();
    localStorage.removeItem('successDataPush');
  }

  onReady() {
    this.initClickCollect();
    this.tabber = new Tabs();
    this.summary = new Summary();
    new BasketSummary($(document.getElementById('CheckoutSummary')));
    this.basketList = new BasketList($(document.getElementById('CheckoutSummaryList')),BasketListItem);
    this.salesAgreementModal = new RemoteSalesAgreementModal();
  }

  initClickCollect() {
    if (this.clickCollect) {
      return;
    }

    this.clickCollect = new PzClickCollect();
  }


  setLastState() {
    const isPaymentSelected = localStorage.getItem('isContinuedPayment');
    if (isPaymentSelected === 'true') {
      setTimeout(() => this.tabber.$paymentTab.trigger('click'), 0);
    }
  }

  onErrors(errors) {
    if (errors && errors?.non_field_errors) {
      this.$nonFieldErrors.html(errors.non_field_errors).show();
    } else {
      this.$nonFieldErrors.html('').hide();
    }
  }

  iframe() {
    document.addEventListener('open-3d-iframe', (e) => {
      const iframeWrapper = document.createElement('div');
      const iframe = document.createElement('iframe');
      const closeButton = document.createElement('div');
      const redirectUrl = e.detail?.redirect_url;

      const iframeURLChange = (iframe, callback) => {
        iframe.addEventListener('load', () => {
          setTimeout(() => {
            if (iframe?.contentWindow?.location) {
              callback(iframe.contentWindow.location);
            }
          }, 0);
        });
      };

      const removeIframe = async () => {
        const iframeSelector = document.querySelector('.js-checkout-payment-iframe-wrapper');

        if (!iframeSelector) {
          return;
        }

        iframeSelector.remove();

        const iframeLoading = document.createElement('div');

        iframeLoading.className = 'iframe-loading-wrapper js-iframe-loading-wrapper';
        document.body.appendChild(iframeLoading);

        await fetch(`${window.URLS.checkout}`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json'
          }
        });

        iframeLoading.remove();
      };

      iframeWrapper.className = 'checkout-payment-iframe-wrapper js-checkout-payment-iframe-wrapper';
      closeButton.className =
      'checkout-payment-iframe-wrapper-close-button js-checkout-payment-iframe-wrapper-close-button';

      const iframeSelector = document.querySelector('.js-checkout-payment-iframe-wrapper');

      iframe.setAttribute('src', redirectUrl);

      closeButton.innerHTML = '&#x2715';
      closeButton.addEventListener('click', removeIframe);

      iframeWrapper.append(iframe, closeButton);

      if (iframeSelector === null) {
        document.body.appendChild(iframeWrapper);

        iframeURLChange(iframe, (location) => {
          if (location.origin !== window.location.origin) {
            return false;
          }

          const searchParams = new URLSearchParams(location.search);
          const isOrderCompleted = location.href.includes('/orders/completed');
          const data = {
            url: location.pathname
          };

          if (searchParams.has('success') || isOrderCompleted) {
            removeIframe();
          }

          if (isOrderCompleted) {
            window.parent.ReactNativeWebView.postMessage(JSON.stringify(data));
          }
        });
      }
    });
  }


  initSaveSalesDocumentAction(){
    $('.save-sales-document-button').on('click', function(){
      window.print();
    })
  }

  setDevice() {
    const isMobileApp = JSON.parse(localStorage.getItem('isMobileApp'));

    if (isMobileApp) {
      $('body').addClass('mobile-app');
    }

    window.dataLayer.push({
      event: 'session_property',
      mobile_app: isMobileApp ? true : false,
    });
    
  }
};

import '../../assets/img/mastercard.png';
import '../../assets/img/mastersecure.png';
import '../../assets/img/noimage.jpg';
import '../../assets/img/ssl-secure.png';
import '../../assets/img/tqlty.png';
import '../../assets/img/vbv.png';
import '../../assets/img/visa.png';
import '../../assets/img/cvv.png';
import '../../assets/img/bell.png';