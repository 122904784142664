import {ApiClient} from "shop-packages";
import store from "shop-packages/redux/store";
import {
  errorBasket,
  setBasket,
} from "shop-packages/redux/basket/reducer";
import ActionButton from "../button-action";


const RemoveFromCartButton = ($element, attributes) => {
  const button = new ActionButton($element);
  button.action = ApiClient.basket.removeProduct;
  button.beforeAction = () => {
    button.actionParams = [
      $element.data('pk'),
      {
        attributes,
      }
    ];
  };

  button.onSuccess = response => {
    store.dispatch(setBasket(response.data));
    if (button.success) button.success(response , button.actionParams[0]);
  };

  button.onError = err => {
    store.dispatch(errorBasket({
      pk: $element.data('product'),
      error: err.responseJSON,
    }));

    if (button.error) button.error(err);
  };

  return button;
};

export default RemoveFromCartButton;
