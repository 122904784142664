import RegisterForm from "../auth/forms/registerForm";

export default class Success {
  constructor() {
    const $form = $(document.getElementById('GuestFormSuccess'));
    this.successPage = document.querySelector('.js-success-page');
    this.successContent = document.querySelector('.js-success-content');
    delete localStorage.giftProductAdded;

    this.init();
    if (localStorage.getItem('successDataPush') == null) {
      this.pushAnalyticsData();
    }
    new RegisterForm($form);

  }

  pushAnalyticsData() {
    const element = document.querySelector('.js-analytics-success');
    const ecommerce = JSON.parse(element.textContent);
    const isMobileApp = JSON.parse(localStorage.getItem('isMobileApp'));

    window.dataLayer.push({
      event: 'session_property',
      mobile_app: isMobileApp ? true : false,
    });

    if(localStorage.basketAnalytics){
      try {
        const basket = JSON.parse(localStorage.basketAnalytics);
        ecommerce.coupon = basket[0].coupon || "";
        const items = ecommerce.items;  
        items.forEach(item => {
          const basketItem = basket.find(basketItem => basketItem.item_sku === item.item_sku);
          if (basketItem) { 
            item.discount_rate = basketItem.discount_rate;
            item.item_discount_status = basketItem.item_discount_status
            item.coupon = basketItem.coupon;
            item.item_last_price = basketItem.item_last_price
            item.discount =  basketItem.discount;
            item.item_list_id = basketItem.item_list_id;
            item.item_list_name = basketItem.item_list_name;
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "purchase",
      ecommerce
    })
    delete localStorage.basketAnalytics;
    delete localStorage.analyticsProducts;
    localStorage.setItem('successDataPush', true);
  }

  init = () => {
    const isMobileApp = JSON.parse(localStorage.getItem('isMobileApp'));

    this.successPage.hidden = isMobileApp;
    this.successContent.hidden = isMobileApp;
  }
}