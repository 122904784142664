import AddToCartButton from '../../components/button-add-to-cart';
import * as actionTypes from 'shop-packages/analytics/action-types';
import {pushEvent} from "shop-packages/analytics";
import { ApiClient } from 'shop-packages';
import store from "shop-packages/redux/store";
import { setBasket } from "shop-packages/redux/basket/reducer";
import { setAnalyticsProductToStorage } from '../../utils';
export default class CombineVariant {

	constructor() {
		this.selectedProducts = [];
		this.request = [];
		this.init();
	}

	init() {
		this.fetchCombineVariant();
		this.sizeSelection();
		this.addToCart();
		const addAllToCartButton = $('.js-add-all-to-cart');
		addAllToCartButton.prop('disabled', true);
	}

	fetchCombineVariant() {
			this.$container = $(document.getElementById('ProductPage'));
			const $variants = this.$container.find('.js-combine-products');
			$variants.each((index, el) => {
				const pk = el.getAttribute('data-pk');
				$.ajax({
					url: `/product/${pk}/`,
					beforeSend(xhr) {
						xhr.setRequestHeader('Accept', 'application/json');
					},
					success: (response) => {
						const sizeData = response.variants.filter(
							item => item.attribute_key == 'integration_beden1'
						)[0]?.options;

						const sizeContainer = $(el).find('.js-combin-sizes');
			  			sizeContainer.empty();

						sizeData && sizeData.forEach(item => {
							const button = document.createElement('button');
							button.type = 'button';
							button.classList.add('size-button');
							button.dataset.productPk = item.product.pk;
							button.innerText = item.label;
							if (item.is_selectable === false || item.in_stock === false) {
								button.disabled = true;
							}
							sizeContainer.append(button);
						});
						this.sizeSelection();
					}
				});
			});
	}


	sizeSelection() {
		const sizeButtons = $('.js-combin-sizes button');
		const $this = this;

		sizeButtons.off('click').on('click', function(e) { 
			const $parentProduct = $(e.currentTarget).closest('.js-combine-products');
			const $parentPp = $(e.currentTarget).closest('.js-combin-sizes');
			const pks = $parentPp.find('button').map((index, item) => $(item).data('productPk')).get();
			const productPk = $(e.currentTarget).data('productPk');

			$this.selectedProducts = $this.selectedProducts.filter(p => !pks.includes(p));
			$this.selectedProducts.push(productPk);

			$parentProduct.find('.js-combin-sizes button').removeClass('selected');
			
			$(e.currentTarget).addClass('selected');
			const addAllToCartButton = $('.js-add-all-to-cart');
			addAllToCartButton.attr('data-products', $this.selectedProducts.map(p => p).join(','));
			addAllToCartButton.prop('disabled', false);
			$('.js-combine-stock').removeClass('show');
			$('.js-combine-success').removeClass('show');
			
		});
	}

	addToCart() {
		const $addToCartButton = $('.js-add-all-to-cart');
		const _this = this;
		
		let i = 0; 
		const successfulProducts = [];

		$addToCartButton.off('click').on('click', function() {
			$addToCartButton.addClass('loading');
			_this.selectedProducts.forEach((pk) => {
				setTimeout(() => {
					const data = {
						product: pk,
						quantity: 1
					};

					const request = ApiClient.request('POST', window.URLS.basket, JSON.stringify(data), {
						contentType: 'application/json'
					});
					request.then((response) => {
						window.dataLayer.push({ ecommerce: null });
						const button = $('.js-combin-sizes').find(`button[data-product-pk="${pk}"]`);
						const stockMessage = button.closest('.combin-size-wrapper').find('.js-combine-success');
						stockMessage.addClass('show');

						_this.request.push(response.data);
						_this.processAnalyticsData(response, pk);
    					_this.updateEcommerceData(response, pk);

						
						if (_this.selectedProducts.length === _this.request.length) {
							_this.updateBasket(_this.request[_this.request.length - 1]);
							
						}
						
					})
					
					.catch((err) => {	
						const button = $('.js-combin-sizes').find(`button[data-product-pk="${pk}"]`);
						const stockMessage = button.closest('.combin-size-wrapper').find('.js-combine-stock');

						stockMessage.addClass('show');
						$addToCartButton.prop('disabled', true);
						$addToCartButton.removeClass('loading');
						$('.js-combin-sizes button').removeClass('selected');
						
						_this.selectedProducts = _this.selectedProducts.filter(p => p !== pk);

						if (_this.selectedProducts.length === _this.request.length && _this.selectedProducts.length != 0) {
							_this.updateBasket(_this.request[_this.request.length - 1]);
						}

					});					

				}, 1000 * i);
				i++;
			});
			if (successfulProducts.length > 0) {
				const successfulEvent = {
					type: actionTypes.successfulProductsAdded,
					payload: {
						productPKs: successfulProducts
					}
				};
				pushEvent(successfulEvent);
			}
		});
	}
	resetSelection() {
		this.selectedProducts = [];
		this.request = [];
	
		$('.js-combin-sizes button').removeClass('selected');
		const $addToCartButton = $('.js-add-all-to-cart');
		$addToCartButton.removeAttr('data-products');
		$addToCartButton.prop('disabled', true);
		$addToCartButton.removeClass('loading');
	}

	updateBasket = (data) => {
		let totalQuantity = 0;
		const basketData = data.basket.basketitem_set;

		basketData.forEach((basketItem) => {										
			totalQuantity += basketItem.quantity; 
		});

		store.dispatch(setBasket( data));
		
		$('.js-mini-basket-total-quantity').text(totalQuantity);

		this.resetSelection();

	}
	
	processAnalyticsData(response, pk) {
		$('.analytics-data').each(function () {
			const analyticsData = JSON.parse($(this).text());
			if (!analyticsData.type) return; 
			if (analyticsData.type === actionTypes.productViewed) {
				const productData = {
					type: actionTypes.productAdded,
					payload: {
						products: [{
							...analyticsData.payload.products[0],
							quantity: 1,
							cart_id: response.data.basket.pk
						}]
					}
				};
				pushEvent(productData);
			}
		});
	}
	
	updateEcommerceData(response, pk) {
		let element = document.querySelector('.js-analytics-product');
		if (!element) return;
	
		const selectedSize = this.getSelectedSize(element);
		const ecommerce = JSON.parse(element.textContent);
		const items = ecommerce.items[0];
	
		// Update item properties
		items.price = +items.price || items.price;
		items.discount_rate = +items.discount_rate || items.discount_rate;
		items.discount = +items.discount || items.discount;
		items.item_last_price = +items.item_last_price || items.item_last_price;
		items.item_size = selectedSize || items.item_size;
		ecommerce.value = +ecommerce.value || ecommerce.value;
	
		const analyticsProducts = JSON.parse(localStorage.getItem('analyticsProducts') || '[]');
		const findProducts = analyticsProducts.find(product => product.basecode == element.dataset.basecode);
		items.index = +findProducts?.index || +items?.index;
		items.item_list_id = findProducts?.listIdx || items.item_list_id;
		items.item_list_name = findProducts?.listName || items.item_list_name;
	
		setAnalyticsProductToStorage({
			pk: element?.dataset?.pk,
			idx: items.index,
			basecode: items.item_id,
			listIdx: items.item_list_id,
			listName: items.item_list_name
		});
	
		this.pushCartDetailEvent(response);
		window.dataLayer.push({
			event: 'add_to_cart',
			ecommerce
		});
	}
	getSelectedSize(element) {
		let selectedSize = '';
		if (element.classList.contains('combine-product')) {
			const analyticsElement = document.querySelector(`.js-analytics-product[data-basecode="${element.dataset.basecode}"]`);
			if (analyticsElement) {
				const productOptions = document.querySelector(`select[data-basecode='${element.dataset.basecode}']`);
				if (productOptions) {
					selectedSize = productOptions.options[productOptions.selectedIndex]?.text;
				}
			}
		}
		return selectedSize;
	}
	pushCartDetailEvent(response) {
		let cartProducts = response.data.basket.basketitem_set.map(item => ({
			cart_id: response.data.basket.pk,
			itemgroup_id: item.product.attributes.integration_renk_kodu && item.product.attributes.integration_beden1 
				? item.product.base_code + item.product.attributes.integration_renk_kodu + item.product.attributes.integration_beden1 
				: item.product.base_code,
			productPK: item.product.pk,
			sku: item.product.sku,
			quantity: item.quantity,
			price: item.product.price,
			variantid: item.product.attributes.filterable_renk
		}));
	
		const cartDetailData = {
			type: actionTypes.cartDetailEvent,
			payload: cartProducts
		};
	
		pushEvent(cartDetailData);
	}
}
