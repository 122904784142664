import formPost from '../flatpages/formPost';
import selectOption from '../flatpages/selectionOption';
import formData from '../flatpages/formData';

export default function formValidate() {
  const formHtml = $('[name="form"]');
  const requiredHtml = $(formHtml).find('[data-required="value"]');
  const requiredCheckboxHtml = $(formHtml).find('[data-required-checkbox="value"]');

  const mailInputHtml = $(formHtml).find('[data-mail="value"]');
  const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  selectOption();

  $(formHtml).on('submit', (event) => {
    event.preventDefault();
    formData();
    $(requiredHtml).each(function () {
      if (!this.value) {
        $(this).addClass('error');
        $('.required-error').addClass('error');
      } else {
        $(this).removeClass('error');
        $('.required-error').removeClass('error');
      }

      $(requiredCheckboxHtml).each(function () {
        if (!$(this).is(':checked')) {
          $(this).addClass('error');
          $('.required-error').addClass('error');
        } else {
          $(this).removeClass('error');
          $('.required-error').removeClass('error');
        }
      });
      const regexResult = regexEmail.test(mailInputHtml.val());
      if (regexResult === false) {
        $(mailInputHtml).addClass('error');
        $('.required-error').addClass('error');
      } else {
        $(mailInputHtml).removeClass('error');
        $('.required-error').removeClass('error');
      }

    });
    if (!$(requiredHtml).hasClass('error') && !$(requiredCheckboxHtml).hasClass('error')) {
      formPost();
    }
  });
}