import Component from "./component";
import Template from "./template";

export default class List extends Component {
  constructor(_$, selector, ViewHolder) {
    super(_$, selector);
    this.children = [];
    if(ViewHolder){
      this.ViewHolder = ViewHolder;
      this.viewHolderEl = this._$.find(this.ViewHolder.selector + '[hidden]');
    }
    this.setProps({ list: [] })
  }

  setViewHolder(ViewHolder) {
    if (!ViewHolder instanceof Template) throw new Error('ViewHolder must be extend Template');
    this.ViewHolder = ViewHolder;
    this.viewHolderEl = this._$.find(this.ViewHolder.selector + '[hidden]');
    return this;
  }

  getDomElements = () => {
    if (!this.props.list || !this.ViewHolder) return [];
    return this.props.list.map(item => new this.ViewHolder(this.viewHolderEl, item))
  };

  mount() {
    this.children = this.getDomElements();
    return this;
  }

  renderProducts(container, products) {
    products.forEach($child => {
      container.append($child.mount());
      $child.render();
    });
    
  }

  render() {
    this._$.html(this.viewHolderEl);
    let dowryProducts = [];
    let products = [];

    this.children
    .forEach($child => {
      if(this._$.hasClass('js-basket-items')) {
        if( $child.props.basketItem.product.attributes?.sepet_ayrisim == 'ceyiz') {
          dowryProducts.push($child)
        }else {
          products.push($child)
        }

        return;
      }

      this._$.append($child.mount());
      $child.render();
    });
     
    if(this._$.hasClass('js-basket-items')){

      if(dowryProducts.length > 0) {
        this._$.addClass('dowry-products');
        this.renderProducts(this._$, dowryProducts);

        const productsBoxHtml = `
          <div class="products-box">
            <div class="products-box__title">
            <img alt="edit" src="${window.URLS.static}img/edit.png" >
               <a href="/ceyiz-seti">Kutuyu Düzenle</a>
            </div>
          </div>
        `

        this._$.prepend('<div class="dowry-box">Çeyiz Kutunuz</div>')
        this._$.append(productsBoxHtml)

        this._$.append('<div class="items-all">Sepetinizdeki Diğer Ürünler</div>')
  
        this.renderProducts(this._$, products);

      }else if(dowryProducts.length === 0) {
        this._$.removeClass('dowry-products');
        this.renderProducts(this._$, products);
      }
    }
  }
}
