export default function () {

  const profileButton = $('.js-mini-profile'),
        profileMenu = $('.js-profile-menu'),
        profileCloseButton = $('.js-profile-close'),
        overlayArea = $('.js-overlay');

  profileButton.on('click', function () {
    profileMenu.addClass("active");
    overlayArea.addClass("d-block");
  });

  profileCloseButton.on('click', function (e) {
    e.stopPropagation();
    profileMenu.removeClass("active");
    overlayArea.removeClass("d-block");
  });

  overlayArea.on('click', function () {
    profileMenu.removeClass("active");
    overlayArea.removeClass("d-block");
  })
}

