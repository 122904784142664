import store from 'shop-packages/redux/store';
import observe, { getValue } from 'shop-packages/redux/connectSelector';
import {
  selectAddresses,
  selectCurrentAddress,
  selectCurrentBillingId,
  selectCurrentAddressCity,
  selectShippingOptions,
  selectCurrentShippingId,
  selectSameBillAddress,
  selectCurrency,
  selectAddressAndShipping,
  selectSummaryData,
} from 'shop-packages/redux/checkout/selectors';

import {
  fetchAddresses,
  setAddress,
  setBillingAddress,
  setShippingOption,
  setSameBillingAddress,
} from 'shop-packages/redux/checkout/actions';

import { setTab } from 'shop-packages/redux/checkout/reducer';

import AddressForm from '../../components/address-form';

import { getAnalyticsProductFromLocalStorage, templateRenderer } from '../../utils';
class AddressTab {
  observers = [];
  addresses = [];
  selectedAddressId = null;
  selectedBillingId = null;
  selectedShippingId = null;
  sameBilling = true;
  currency = 'TL';

  constructor() {
    this.addressBoxTemplate = $(document.getElementById('CheckoutAddressBoxTemplate')).html();
    this.newAddressTemplate = $(document.getElementById('CheckoutNewAddressTemplate')).html();
    this.shippingOptionTemplate = $(document.getElementById('CheckoutShippingItemTemplate')).html();
    this.addressWarningTemplate = $(document.getElementById('CheckoutAddressWarningTemplate')).html();
    this.shippingLabelTemplate = $(document.getElementById('CheckoutShippingLabelTemplate')).html();
    this.selectedAddressTemplate = $(document.getElementById('CheckoutSelectedAddressTemplate')).html();

    this.$addressListContainer = $(document.getElementById('CheckoutAddressList'));
    this.$selectedCityContainer = $(document.getElementById('CheckoutAddressCity'));
    this.$shippingOptionsContainer = $(document.getElementById('CheckoutShippingList'));
    this.$sameBillingAddressInput = $(document.getElementById('CheckoutSameBillingCheck'));
    this.$billingListWrapper = $(document.getElementById('CheckoutBillingWrapper'));
    this.$billingListContainer = $(document.getElementById('CheckoutBillingList'));
    this.$shippingLabelContainer = $(document.getElementById('CheckoutShippingLabel'));
    this.$selectedAddressContainer = $(document.getElementById('CheckoutSelectedAddress'));

    this.$sameBillingAddressInput.on('input.sameBillingAddress', this.onSameBillingChange.bind(this));

    this.currency = getValue(selectCurrency);
    this.isUpdateAddress = false;

    const selectedAddress = getValue(selectCurrentAddress);
    this.selectedAddressId = selectedAddress ? selectedAddress.pk : null;
    const selectedShipping = getValue(selectCurrentShippingId);
    this.selectedShippingId = selectedShipping ? selectedShipping.pk : null;

    this.addressForm = new AddressForm();
    this.addressForm.updateAddressBox = () => {
      store.dispatch(fetchAddresses())
      this.isUpdateAddress = true;
    }

    this.initObservers();
  }

  onSameBillingChange(e) {
    store.dispatch(setSameBillingAddress($(e.currentTarget).is(':checked')));
  }

  onAddressesUpdate(addresses) {
    this.addresses = addresses;
    this.renderShippingAddresses();

    if(this.addresses.length > 0) {
      const messageContainer = document.querySelector('.checkout-address-error');
      messageContainer?.classList.add('d-none');
    }

    if (!this.sameBilling) {
      this.renderBillingAddresses();
    }
  }

  onSelectedAddressUpdate(selectedAddress) {
    if (!selectedAddress) {
      this.renderAddressWarning();
      return;
    }
    const selectedId = selectedAddress.pk;
    this.selectedAddressId = selectedId;

    this.$addressListContainer
      .find('.js-address-box').removeClass('checked');

    const selectedAddressBox = this.$addressListContainer
      .find(`.js-address-box[data-pk="${selectedId}"]`);

    selectedAddressBox.addClass('checked');
    selectedAddressBox.find('.js-address-input')
      .prop('checked', true);
    
    this.renderSelectedAddress();
  }

  onSelectedBillingUpdate(selectedId) {
    this.selectedBillingId = selectedId;
    this.$billingListContainer
      .find('.js-address-box').removeClass('checked');

    const selectedAddressBox = this.$billingListContainer
      .find(`.js-address-box[data-pk="${selectedId}"]`);

    selectedAddressBox.addClass('checked');

    selectedAddressBox
      .find(`.js-address-input[value="${selectedId}"]`)
      .prop('checked', true);
  }

  onSelectedCityUpdate(cityName) {
    if (cityName) {
      this.$shippingLabelContainer.html(
        templateRenderer(this.shippingLabelTemplate, { cityName })
      );
    }
  }

  onShippingOptionsUpdate(shippingOptions) {
    this.shippingOptions = shippingOptions;
    this.renderShippingOptions();

    const isRetail = this.shippingOptions.find(({ slug }) => slug === 'RetailStore') 


    if (shippingOptions.length >= 1 && !isRetail) {
      $("#CheckoutShippingList input[type='radio']:first").trigger('click');
    }
  }

  onSelectedShippingUpdate(selectedId) {
    this.selectedShippingId = selectedId;
    this.$shippingOptionsContainer
      .find(`.js-shipping-input[value="${selectedId}"]`)
      .prop('checked', true);
      this.onCheckoutShipping(2,selectedId)
      this.googleAnaltyicsShipping(selectedId);
  }
  
  onSameBillingUpdate(sameBilling) {
    this.sameBilling = sameBilling;
    this.$sameBillingAddressInput.prop('checked', sameBilling);

    const action = sameBilling ? 'remove' : 'add';
    this.$billingListWrapper[`${action}Class`]('d-flex');

    if (!sameBilling) {
      this.renderBillingAddresses();
    }
  }

  renderSelectedAddress(addressAndShipping) {
    if (!addressAndShipping || !addressAndShipping.address) {
      this.$selectedAddressContainer.html('');
      return;
    }
    
    const address = addressAndShipping.address;
    const ship = addressAndShipping.shipping;

    this.$selectedAddressContainer.html(
      templateRenderer(this.selectedAddressTemplate, {
        title: address.title,
        address: `${address.line} ${address.township.name} ${address.postcode} ${address.city.name}`,
        shipping: ship ? `${ship.name} ile` : '',
        pk:address.pk,
      })
    );

    this.$selectedAddressContainer
      .find('#CheckoutChangeAddressButton')
      .click((e) => {
        store.dispatch(setTab('address'));
        const addressPk = $(e.currentTarget).attr('data-pk');
        $(`.js-address-box[data-pk="${addressPk}"] .js-update-address`).trigger('click');
      });
  }

  renderShippingAddresses() {
    const container = this.$addressListContainer;
    const selectedId = this.selectedAddressId;
    const selectAction = setAddress;
    const type = 'shipping';

    this.renderAddresses({ container, selectedId, selectAction, type });
  }

  renderBillingAddresses() {
    const container = this.$billingListContainer;
    const selectedId = this.selectedBillingId;
    const selectAction = setBillingAddress;
    const type = 'billing';

    this.renderAddresses({ container, selectedId, selectAction, type });
  }

  renderAddresses({ container, selectedId, selectAction, type }) {
    const content = this.addresses.map(({
      pk,
      title,
      line,
      postcode,
      township,
      city,
      district,
      is_corporate,
      company_name,
      tax_office,
      tax_no,
      phone_number,
      first_name,
      last_name,
    }) => {
      const checked = selectedId === pk ? 'checked' : '';
      const inputName = `${type}Radio`;
      const address = `${line} ${township.name} ${postcode} ${city.name}`;

      return templateRenderer(this.addressBoxTemplate, {
        pk,
        title,
        checked,
        inputName,
        address,
        line,
        city: city ? city.pk : '',
        township: township ? township.pk : '',
        district: district ? district.pk : '',
        postcode,
        isCorporate: is_corporate,
        companyName: company_name,
        taxOffice: tax_office,
        taxNo: tax_no,
        phoneNumber: phone_number,
        firstName: first_name,
        lastName: last_name,
      });
    });

    if (type === 'shipping') {
      content.push(this.newAddressTemplate);
    }

    container.html(content.join(''));
    container
      .find('.js-address-box')
      .on('click', (e) => {
        const selected = $(e.currentTarget).attr('data-pk');
        document.getElementById('CheckoutProceedButton').removeAttribute('disabled');
        if ($(e.currentTarget).attr('data-first-name').length <= 20 && $(e.currentTarget).attr('data-last-name').length <= 20 ) {
          $(e.currentTarget).find('.js-address-input').prop('checked', true);
          store.dispatch(selectAction(selected));
        } else {
          document.getElementById('CheckoutProceedButton').setAttribute('disabled', 'disabled');
          alert('Adres bilgisi içinde yer alan isim ve soyisim alanları 20 karakterden uzun olamaz. Lütfen bilgileri güncelleyin veya yeni adres oluşturun.')
        }
      });

      this.isUpdateAddress && container.find('.js-address-box').eq(0).trigger('click');
      this.addressForm.init()
  }

  renderShippingOptions() {
    if (!this.shippingOptions.length) {
      this.$shippingOptionsContainer.html('');
      return;
    }

    const content = this.shippingOptions.map(({pk, name, slug, shipping_amount: amount}) => {
      const checked = this.selectedShippingId === pk && this.shippingOptions.length === 1 ? 'checked' : '';
      const currency = this.currency;
      const amountWithCurrency = amount == 0.00 ? 'Ücretsiz' : amount + currency;
      
      if (checked) {
        $('.checkout-shipping-error').addClass('d-none');
      }

      return templateRenderer(this.shippingOptionTemplate, { pk, name, slug, checked, amountWithCurrency });
    });

    this.$shippingOptionsContainer.html(content.join(''));
    this.$shippingOptionsContainer
      .find('.js-shipping-input')
      .on('input', (e) => {
        const selected = $(e.currentTarget).val();
        store.dispatch(setShippingOption(selected));
        this.onCheckoutShipping(2,selected)

        this.$shippingOptionsContainer
          .find(`.js-shipping-input[value="${selected}"]`)
          .prop('checked', true);
      });
  }

  renderAddressWarning() {
    this.$shippingLabelContainer.html(
      this.addressWarningTemplate
    );
  }

  initObservers() {
    this.observers = [
      observe(selectAddresses).subscribe(this.onAddressesUpdate.bind(this)),
      observe(selectCurrentAddress).subscribe(this.onSelectedAddressUpdate.bind(this)),
      observe(selectCurrentBillingId).subscribe(this.onSelectedBillingUpdate.bind(this)),
      observe(selectCurrentAddressCity).subscribe(this.onSelectedCityUpdate.bind(this)),
      observe(selectShippingOptions).subscribe(this.onShippingOptionsUpdate.bind(this)),
      observe(selectCurrentShippingId).subscribe(this.onSelectedShippingUpdate.bind(this)),
      observe(selectSameBillAddress).subscribe(this.onSameBillingUpdate.bind(this)),
      observe(selectAddressAndShipping).subscribe(this.renderSelectedAddress.bind(this)),
    ]
  }

  unmount() {
    this.$sameBillingAddressInput.off('input.sameBillingAddress');

    for (const observer of this.observers) {
      observer.unsubscribe();
    }
  }

  onCheckoutShipping(step,checkoutOption){
    const shippingName = $(`.js-shipping-input[value="${checkoutOption}"]`)?.next()[0]?.innerText;
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      'event': 'checkoutOption',
      'eventCategory': 'Enhanced Ecommerce',
      'eventAction': 'Checkout Options',
      'eventLabel': shippingName,
      'eventValue': 0,
      'noninteraction': false,
      'ecommerce': {
        'checkout_option': {
          'actionField': {'step': step, 'option': shippingName}
        }
      }
    });
  }

  googleAnaltyicsShipping(checkoutOption){
    const shippingName = $(`.js-shipping-input[value="${checkoutOption}"]`)?.next()[0]?.innerText;

    const sendAnalyticData = (e) => {
      e.preventDefault();
      const ecommerce = JSON.parse($('.analytics-address-data').text());
      ecommerce.shipping_tier = shippingName;
      const checkoutSummary = getValue(selectSummaryData);
      try {
        ecommerce.value = checkoutSummary?.totalAmount ? +checkoutSummary?.totalAmount : ecommerce.value;
        const items = ecommerce.items;
        const basketAnalytics = JSON.parse(localStorage.basketAnalytics || '[]');

        items.forEach(item => {
          const analyticProduct = getAnalyticsProductFromLocalStorage(item.item_id);
          const basketItem = basketAnalytics.find(basketItem => basketItem.item_sku === item.item_sku);
          if (basketItem) {
            item.discount_rate = basketItem?.discount_rate;
            item.item_discount_status = basketItem?.item_discount_status
            item.coupon = basketItem?.coupon;
            item.item_last_price = basketItem?.item_last_price
            item.discount = basketItem?.discount;
          }
          item.item_list_id = analyticProduct?.listIdx || '';
          item.item_list_name = analyticProduct?.listName || '';
        });
      } catch (error) {
        console.log(error)
      }

      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        "event": "add_shipping_info",
        "ecommerce": ecommerce
      });
    };

    $('.js-analytics-payment').off('click.sendAnalyticData');
    $('.js-analytics-payment').on('click.sendAnalyticData',sendAnalyticData);
  }
}

export default AddressTab;