export default function formPost() {

  const formSerilize = $('[name="form"]').serializeArray();
  
  let message = formSerilize.find((element) => element.name == 'message').value;
  let email = formSerilize.find((element) => element.name == 'email').value;
  let name = formSerilize.find((element) => element.name == 'name').value;
  let surname = formSerilize.find((element) => element.name == 'surname').value;
  let phone = formSerilize.find((element) => element.name == 'phone').value;
  let subject = formSerilize.find((element) => element.name == 'subject').value;

  $.ajax({
    url: '/users/contact-us/',
    method: 'post',
    async: false,
    beforeSend: function (request) {
      request.setRequestHeader('X-CSRFToken', $('input[name="csrfmiddlewaretoken"]').val());
    },
    data: {
      'message': message,
      'email': email,
      'full_name': name + surname,
      'phone': phone,
      'subject': subject,

    },
    success() {
      setTimeout(() => {
        $('.js-appointment-form-area').hide();
        $('.js-success-message').show();
      },1000);

      setTimeout(() => {
        location.reload();
      },1500);
    },
    error() {
      alert('error occurred');
    }
  });
}