import Element from '../element';

class ActionElement extends Element {
  _action = () => Promise.resolve();

  actionParams = [];
  beforeAction = () => Promise.resolve();
  onSuccess = () => {};
  onError = () => {};

  constructor($element) {
    super($element);
  }

  set action(promiseFn) {
    this._action = promiseFn;
    if (this._bindAction) {
      this._bindAction();
    }
  }

  async runAction() {
    try {
      await this.beforeAction();
      const response = await this._action(...this.actionParams);
      this.onSuccess(response);
    } catch (err) {
      this.onError(err);
    }
  }
};

export default ActionElement;