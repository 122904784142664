import List from "../../components/dom/list";
import { basketErrorSelector, basketListSelector } from "shop-packages/redux/basket/selectors";
import observe from "shop-packages/redux/connectSelector";
import PzCheckoutProvider from 'pz-checkout-providers/pz_checkout_providers/templates/pz_checkout_providers';

export default class BasketList extends List {
  static selector = ".js-basket-list-container";

  constructor(_$, ViewHolder) {
    super(_$, BasketList.selector, ViewHolder);
    observe(basketErrorSelector).subscribe(({ errors, pending }) => {
      if (pending) return;
      this.setProps({ errors }).render()
    });

    observe(basketListSelector).subscribe(({ list, errors, pending }) => {
      if (pending) return;
      this.setProps({ list: list || [], errors }).mount().render();
      this.initAkifast();
    })
  }
  render() {
    const errors = this.props.errors;
    if (errors && Array.isArray(errors)) {
      this.children.forEach(child => {
        const error = errors.find(err => err.pk === child.productPk);
        child.setProps({ error: error ? error.error.non_field_errors : '' })
      });
    }
    super.render();
  }
  initAkifast() {
    if ($('.js-checkout-providers').length) {
      $('.js-checkout-providers').find('.js-btn-akifast-provider').off('click');
      $('.js-checkout-providers').find('.js-btn-akifast-provider').remove();
      new PzCheckoutProvider({
        clearBasket: false,
        options: [
          {
            slug: 'akifast',
            options: {
              title: 'Hızlı Öde',
              className: 'js-btn-akifast-provider'
            }
          }
        ]
      });
    }
  }
}
