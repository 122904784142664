import { ApiClient } from 'shop-packages';

export default class Address {
  constructor(){
    this.$container = $(document.getElementById('addressBoxesWrapper'));
    this.$primaryRadio = this.$container.find('input[name=primary_address]');
    
    this.$primaryRadio.on('input', this.setPrimaryAddress.bind(this));
  }

  async setPrimaryAddress(e){
    const addressPk = $(e.target).closest('.js-address-box').attr('data-pk');
    
    await ApiClient.address.setDefaultAddress(addressPk);
  }
};