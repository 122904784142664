import { ApiClient } from 'shop-packages';
import store from 'shop-packages/redux/store';
import { fetchCheckout } from 'shop-packages/redux/checkout/actions';
import { selectSummaryData } from 'shop-packages/redux/checkout/selectors';
import observe from 'shop-packages/redux/connectSelector';

class Gift {
  constructor() {
    this.giftCard();
  }

  giftCardObserver(response) {
    const updateGiftPackagePrice = () => {
      const checkoutGiftElementWrapper = document.querySelector('.checkout__summary .checkout__gift-package');
      const checkoutGiftElementPrice = checkoutGiftElementWrapper?.querySelector('.checkout-summary-item__value');

      const giftPackagePrice = response?.data?.pre_order?.gift_box?.price;

      if (!giftPackagePrice) return;

      if (checkoutGiftElementWrapper) {
        checkoutGiftElementWrapper.classList.remove('hidden');
      }

      if (checkoutGiftElementPrice) {
        checkoutGiftElementPrice.innerText = `${giftPackagePrice} TL`;
      }
    };

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        
        if (mutation.type === 'childList' || mutation.type === 'attributes') {
          observer.disconnect();
          updateGiftPackagePrice();
          observer.observe(document.body, { childList: true, subtree: true });
        }
      });
    });

    observer.observe(document.body, { childList: true, subtree: true });

    updateGiftPackagePrice();
  }

  async giftCard() {
    if (localStorage.giftProductAdded === 'true') {
      try {
        const response = await ApiClient.gift.addGift('null');
        if (response?.status !== "success") throw new Error('Gift package could not be added');
        this.giftCardObserver(response);

        observe(selectSummaryData).subscribe((data) => {
          this.giftCardObserver(data);
        });

        store.dispatch(fetchCheckout());
      } catch (error) {
        console.log(error);
      }
    } else {
      await ApiClient.gift.removeGift();
      store.dispatch(fetchCheckout());
    }
  }
}

export default Gift;