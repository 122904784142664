import Basket from '../basket';
import Listing from '../list';
import Product from '../product';
import ProductItem from '../components/product-item';
import UserPanel from '../partials/user-panel/index';
import Footer from '../partials/footer/index';
import Navigation from '../partials/navigation/index';
import MobileNavigation from '../partials/mobile-navigation';
import MiniBasket from "../partials/user-panel/mini-basket";
import Checkout from '../orders/checkout';
import Flatpage from '../flatpages';
import AccountIndex from '../account/index';
import Profile from '../account/profile';
import ChangePassword from '../account/change-password';
import ChangeEmail from '../account/change-email';
import Address from '../account/address';
import AddressForm from '../components/address-form';
import Login from '../auth/login';
import ForgotPassword from '../auth/forgot-password';
import Register from '../auth/register';
import Favourites from '../account/favourites';
import CheckoutAuth from '../orders/checkout-auth';
import AccountOrders from '../account/orders';
import AccountOrdersDetail from '../account/orders-detail';
import OrdersCancellation from '../account/orders-cancellation';
import AccountContact from '../account/contact';
import Store from '../stores';
import Blog from '../flatpages/blog';
import LandingPage from '../flatpages/landing-page';
import Collection from '../flatpages/collection';
import CollectionPage from '../flatpages/collection-page';
import CollectionThird from '../flatpages/collection-third';
import SmartShop from '../flatpages/smart-shop';
import Success from '../orders/success';
import OtpLogin from '../flatpages/otp-login';
import AccountCommon from '../account/common';
import DowryItem from '../flatpages/dowry-set';
import NewSeasonLandingPage from '../flatpages/ds-damat-yeni-sezon';


const routes = new Map([
  ['basket', Basket],
  ['search', Listing],
  ['product', Product],
  ['checkout', Checkout],
  ['profile', Profile],
  ['changePassword', ChangePassword],
  ['changeEmail', ChangeEmail],
  ['address', Address],
  ['addressForm', AddressForm],
  ['login', Login],
  ['forgotPassword', ForgotPassword],
  ['register', Register],
  ['favourties', Favourites],
  ['checkoutAuth', CheckoutAuth],
  ['accountIndex', AccountIndex],
  ['accountOrders', AccountOrders],
  ['accountOrdersDetail', AccountOrdersDetail],
  ['accountOrdersCancellation', OrdersCancellation],
  ['accountContact', AccountContact],
  ['FlatPage' , Flatpage],
  ['Store' , Store],
  ['Blog' , Blog],
  ['landingPage', LandingPage],
  ['SmartShop', SmartShop],
  ['success', Success],
  ['otpLogin', OtpLogin],
  ['dowryItem', DowryItem],
  ['accountCommon', AccountCommon],
  ['ds-damat-yeni-sezon', NewSeasonLandingPage]
]);

const Router = () => {
  new MiniBasket();
  Footer();
  UserPanel();
  Navigation();
  MobileNavigation();
  new ProductItem();

  for (const page of window.GLOBALS.page) {
    const Component = routes.get(page);
    if (Component) {
      new Component();
    }
  }
}

export default Router;
