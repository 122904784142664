export default function formData() {
  const formSerilize = $('[name="form"]').serializeArray();

  let shops = $('[name="shops"]').find(':selected').text();
  let stores = $('[name="store"]').find(':selected').text();
  let city = formSerilize.find((element) => element.name == 'city').value;
  let address = formSerilize.find((element) => element.name == 'addresstext').value;
  let date = formSerilize.find((element) => element.name == 'date').value;
  let time = formSerilize.find((element) => element.name == 'time').value;

  $('[name="message"]').text(
    ` Tercih ettiğiniz randevu servisi : ${shops} \n
      Tercih ettiğiniz kategori  : ${stores} \n
      İl : ${city} \n
      Açık adres : ${address} \n
      Tercih ettiğiniz randevu tarihi  : ${date} \n
      Tercih ettiğiniz randevu saati  : ${time} \n
     `
  );
}