import observe from "shop-packages/redux/connectSelector";

export default class Component {
  constructor(_$, selector) {
    this._$ = selector ? _$.find(selector) : _$;
    this.props = {};
    this.subscription = null;
  }

  setProps(props) {
    this.props = {
      ...this.props,
      ...props
    };
    return this;
  }

  connect(selector, mapStateToProps) {
    this.subscription = observe(selector);
    this.subscription.subscribe((state) => {
      this.setProps(mapStateToProps(state));
      this.render();
    })
  }

  mount() {
    return this._$;
  }

  render() {
  }

  destroy() {
    this.subscription.unsubscribe();
  }
}
