import { setBasket } from 'shop-packages/redux/basket/reducer';
import { store } from 'shop-packages';
import { basketReducer } from "shop-packages/redux/basket/selectors";
import observe from "shop-packages/redux/connectSelector";
import RemoveFromCartButton from "../../components/button-remove-from-cart";
import Action from '../../components/dowry-item/action';
import 'lightslider';
import AddToCartButton from '../../components/button-add-to-cart';

class DowryItem {
  constructor() {
    this.productWrapper = document.querySelector('.js-dowry-wrapper');

    observe(basketReducer).subscribe(({ basket, pending }) => {
      if (!basket || pending) return;
      const { basketitem_set } = basket;
      let hasBasketItem = basketitem_set && basketitem_set.length > 0;

      if (basketitem_set.length > 0) {
        document.querySelector('.footer').classList.add('dowry-footer-active');
        document.querySelector('.js-dowry-footer').classList.add('-show');
        document.querySelector('.js-dowry-footer').classList.remove('-hide');
      } else {
        document.querySelector('.js-dowry-footer').classList.remove('-show');
        document.querySelector('.js-dowry-footer').classList.add('-hide');
        document.querySelector('.footer').classList.remove('dowry-footer-active');
      }

      if (hasBasketItem) {
        let template = '';
        basketitem_set.forEach((basketItem) => {
          const basketItemImg = basketItem.product.productimage_set[0].image;
          const basketItemPk = basketItem.product.pk;
          const basketItemQuantity = basketItem.quantity;

          template += `
            <div class="dowry-footer__observe-img">
              <span class="dory-item-quantity" data-quantity="${basketItemQuantity}" data-pk="${basketItemPk}"></span>
              <img class="dory-item-img" src="${basketItemImg}" alt="">
              <span class="js-delete-product delete-product" data-pk="${basketItemPk}">x</span>
            </div>`;
        });
          document.querySelector('.js-observe-basketitem').innerHTML = template;
          document.querySelector('.js-cart-total-amount').innerHTML = basket.total_amount;
      }

      $('.js-delete-product').each((i) => {
        RemoveFromCartButton($('.js-delete-product').eq(i));
        if (window.innerWidth < 768) {
          $('.js-add-to-cart').each((i) => {
            AddToCartButton($('.js-add-to-cart').eq(i));
          });
          document.querySelectorAll('.js-product-amount-item-quantity').forEach((item) => {
            if (item.dataset.pk == basketitem_set[i].product.pk) {
              item.innerHTML = basketitem_set[i].quantity;
            }
          });
        }
      });
    });      

    const buttons = document.querySelectorAll('.js-tab-btn');
    const contents = document.querySelectorAll('.js-tab');
    buttons.forEach(button => {
      button.addEventListener('click', (e) => {
        const dataTarget = button.getAttribute('data-target');
        buttons.forEach(button => button.classList.remove('-active'));
        contents.forEach(content => content.classList.remove('-active'));
        button.classList.add('-active');
        document.getElementById(dataTarget).classList.add('-active');
      })
    });
    $('.js-back-to-top').on('click', () => {
      $('html, body').animate({ scrollTop: $('.js-back-to-top-breakpoint').offset().top - 20 }, 'slow');
    });

    this.init();    
  }

  init() {
    document.querySelectorAll('.js-dowry-wrapper').forEach(($item) => {
      new Action($item, this);
    });
    let isRendered = false;

    const initializeSlider = () => {
      const tabSlider = $('.tab-slider');
      tabSlider.each((_, el) => {
        if ($(el)?.[0]?.childElementCount > 1) {
        $(el).lightSlider({
        onSliderLoad: function () {
          tabSlider.removeClass('opacity-0');
          tabSlider.addClass('opacity-1');
        },
        item:2,
        loop:false,
        enableDrag: false,
      });
    }
    })
  }
    const destroySlider = () => {
      const tabSlider = $('.tab-slider');
      if (tabSlider.hasClass('lightSlider')) {
      tabSlider.lightSlider('destroy');
      }
    }
    const checkWindowSize = () => {
      if (!isRendered) {
        if ($(window).width() < 768) { 
          initializeSlider();
        } else {
          destroySlider();
        }
        isRendered = true;
  }
  }
    $(window).on('load', checkWindowSize());
    $(window).on('resize', checkWindowSize());

    this.mobileVariants();
  }




  onAddToCartSuccess = (response, addeProductPk) => {
    store.dispatch(setBasket(response.data));
  }

  mobileVariants = () => {
    
    const addToCartBtns = document.querySelectorAll('.js-mobile-add');

    addToCartBtns.forEach(function(addToCartBtn) {
      const actionElement = addToCartBtn.closest('.js-dowry-wrapper').querySelector('.js-action');
      const actionContentElement = addToCartBtn.closest('.js-dowry-wrapper').querySelector('.js-action-content');
      const quantityElement = addToCartBtn.closest('.js-dowry-wrapper').querySelector('.product-amount__quantity');
      
      addToCartBtn.addEventListener('click', function() {
        actionElement.classList.add('show-variants');
        const variants = actionElement.querySelectorAll('.js-variant-option');
        if (window.innerWidth > 768) {
          quantityElement.classList.add('show-amount');
        }
        if (actionElement.classList.contains('show-variants')) {
          addToCartBtn.style.display = 'none';
        } 
      });
    });

    document.addEventListener('click', function(event) {
      const targetElement = event.target;
      const isInsideComponent = targetElement.closest('.js-dowry-wrapper');
      const variantElements = document.querySelectorAll('.js-variant-option');
      if (!isInsideComponent) {
        const allActionElements = document.querySelectorAll('.js-action');
        allActionElements.forEach(function(actionElement) {
          actionElement.classList.remove('show-variants');
          variantElements.forEach((variantElement) => {
            variantElement.classList.remove('-selected');
            variantElement.classList.add('js-add-to-cart');
          });
          addToCartBtns.forEach(function(addToCartBtn) {
            const quantityElement = addToCartBtn.closest('.js-dowry-wrapper').querySelector('.product-amount__quantity');
            quantityElement.classList.remove('show-amount');
            if (window.innerWidth < 768) {
            const mobileAddBtn = addToCartBtn.closest('.js-dowry-wrapper').querySelector('.js-mobile-add');
            mobileAddBtn.style.display = 'block';
            }
          });
        });
      }
    });
  }
}

export default DowryItem;
