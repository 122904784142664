/* eslint-disable */
import { store } from "shop-packages";
import { setDeliveryOption, setRetailStore } from "shop-packages/redux/checkout/actions";
import { setSameBillAddress } from 'shop-packages/redux/checkout/reducer';
import { selectCurrentAddress, selectCurrentDeliveryOptionId, selectCurrentRetailStore, selectCurrentShippingId, selectDeliveryOptions, selectRetailStores } from "shop-packages/redux/checkout/selectors";
import observe, { getValue } from "shop-packages/redux/connectSelector";

export default class PzClickCollect {
  initialized = false;
  isActive = false;
  deliveryOptions;
  shippingAddressList;
  billingAddressList;
  clickCollectBoxClass;
  clickCollectBox;
  currentDeliveryOptionPk;
  retailStores = [];

  constructor({
    deliveryStoreLabel = 'Teslimat Mağazası',
    boxClass = 'click-collect',
    buttonIcon = null,
    buttonText = 'Mağazadan Teslim Al',
    addressBoxSelector = '.js-address-box',
    newAddressButtonSelector = '.js-new-address',
    deleteAddressButtonSelector = '.js-delete-address',
    updateAddressButtonSelector = '.js-update-address',
    shippingAddressListSelector = '#CheckoutAddressList',
    billingAddressListSelector = '#CheckoutBillingList',
    checkoutProceedButtonSelector = '#CheckoutProceedButton',
    sameBillingCheckboxSelector = '#CheckoutSameBillingCheck',
  } = {}) {
    this.deliveryStoreLabel = deliveryStoreLabel;
    this.buttonIcon = buttonIcon;
    this.buttonText = buttonText;
    this.clickCollectBoxClass = boxClass;
    this.checkoutProceedButtonSelector = checkoutProceedButtonSelector;
    this.addressBoxSelector = addressBoxSelector;
    this.newAddressButtonSelector = newAddressButtonSelector;
    this.shippingAddressListSelector = shippingAddressListSelector; 
    this.billingAddressListSelector = billingAddressListSelector;
    this.sameBillingCheckboxSelector = sameBillingCheckboxSelector;
    this.deleteAddressButtonSelector = deleteAddressButtonSelector;
    this.updateAddressButtonSelector = updateAddressButtonSelector;
    this.retailStoreCitySelectClassName = 'js-retail-store-city-select';
    this.retailStoreSelectClassName = 'js-retail-store-select';
    this.loaderClassName = 'js-click-collect-loader';
    this.shippingAddressList = document.querySelector(this.shippingAddressListSelector);
    this.billingAddressList = document.querySelector(this.billingAddressListSelector);

    observe(selectDeliveryOptions).subscribe(this.onDeliveryOptionsUpdate);

    const mutationConfig = { attributes: false, childList: true, subtree: true };
    new MutationObserver(this.onAddressListUpdate).observe(this.shippingAddressList, mutationConfig);
    new MutationObserver(this.onBillingAddressListUpdate).observe(this.billingAddressList, mutationConfig);
  }

  get defaultDeliveryOption() {
    return this.deliveryOptions.find(option => option.delivery_option_type === 'customer');
  }

  get retailStoreDeliveryOption() {
    return this.deliveryOptions.find(option => option.delivery_option_type === 'retail_store');
  }

  get boxHTML() {
    return `
      <div class="click-collect__btn js-click-collect-activate-btn">
        ${this.buttonIcon ? `<i class="icon pz-icon-${this.buttonIcon}"></i>` : ''}
        <span>${gettext(this.buttonText)}</span>
      </div>
      <div class="click-collect__retail-stores">
        <label class="click-collect-checkbox d-none"><input type="radio" class="fsize-22" {{ checked }}></label>
        <button class="click-collect__close-btn js-click-collect-box-deactivate">&#10005;</button>
        ${this.buttonIcon ? `<i class="icon pz-icon-${this.buttonIcon}"></i>` : ''}
        ${this.deliveryStoreLabel ? `<span>${gettext(this.deliveryStoreLabel)}</span>` : ''}
        <select class="click-collect__select ${this.retailStoreCitySelectClassName}"></select>
        <select class="click-collect__select ${this.retailStoreSelectClassName}"></select>
      </div>
      <div class="click-collect__loader ${this.loaderClassName}"></div>
    `;
  }

  init = () => {
    observe(selectCurrentDeliveryOptionId).subscribe(this.onDeliveryOptionUpdate);
    observe(selectCurrentAddress).subscribe(this.onSelectedAddressUpdate);
    observe(selectRetailStores).subscribe(this.onRetailStoresUpdate);
    observe(selectCurrentShippingId).subscribe(this.onSelectedShippingUpdate);
    this.initialized = true;
  }

  onBillingAddressClick = (e) => {
    const currentTarget = e.currentTarget;

    if (!this.isActive) {
      return;
    }

    e.stopImmediatePropagation();

    this.billingAddressList.querySelectorAll(this.addressBoxSelector).forEach(addressBox => {
      addressBox.classList.remove('checked');
      addressBox.querySelector('input').removeAttribute('checked');
    });

    currentTarget.classList.add('checked');
    currentTarget.querySelector('input').setAttribute('checked', 'checked');
    this.onStoreChange();
  }

  onBillingAddressListUpdate = () => {
    this.billingAddressList.querySelectorAll(this.addressBoxSelector).forEach(addressBox => {
      addressBox.removeEventListener('click', this.onBillingAddressClick);
      addressBox.addEventListener('click', this.onBillingAddressClick, true);
    });

    this.billingAddressList.querySelectorAll([
      this.deleteAddressButtonSelector, this.updateAddressButtonSelector, this.newAddressButtonSelector
    ].join(',')).forEach(el => {
      if (this.isActive) {
        el.setAttribute('style', 'display: none !important;');
      } else {
        el.removeAttribute('style');
      }
    });
    this.onStoreChange();
  }

  onAddressListUpdate = () => {
    if (this.shippingAddressList.querySelector(`.${this.clickCollectBoxClass}`)) {
      return;
    }

    const clickCollectBox = document.querySelector(this.newAddressButtonSelector).parentNode.cloneNode(true);
    clickCollectBox.classList.add(this.clickCollectBoxClass);
    clickCollectBox.classList.toggle('-active', this.isActive);

    const clickCollectBoxContent = clickCollectBox.querySelector(this.newAddressButtonSelector);
    clickCollectBoxContent.classList.add(`${this.clickCollectBoxClass}__content`);
    clickCollectBoxContent.classList.remove('--action');
    clickCollectBoxContent.classList.remove(this.newAddressButtonSelector.replace('.', ''));
    clickCollectBoxContent.innerHTML = this.boxHTML;
    
    this.shippingAddressList.querySelectorAll([
      this.addressBoxSelector, this.newAddressButtonSelector
    ].join(',')).forEach(addressBox => {
      addressBox.parentNode.classList.toggle('click-collect-disabled', this.isActive);
    });

    this.shippingAddressList.append(clickCollectBox);
    this.bindEvents();
    this.fillCitySelect();
  }

  bindEvents = () => {
    const clickCollectBox = this.shippingAddressList.querySelector(`.${this.clickCollectBoxClass}`);
    clickCollectBox.querySelector('.js-click-collect-box-deactivate').addEventListener('click', this.onDeactivate);

    clickCollectBox.querySelector('.js-click-collect-activate-btn').addEventListener('click', () => {
      const messageContainer = document.querySelector('.checkout-address-error');
      const address = getValue(selectCurrentAddress);

      if (!address) {
        messageContainer?.classList.remove('d-none');
        return;
      }

      
      this.showLoader();
      store.dispatch(setDeliveryOption(this.retailStoreDeliveryOption.pk));
    });

    clickCollectBox.querySelector(`.${this.retailStoreCitySelectClassName}`).addEventListener('change', this.onCityChange);
    clickCollectBox.querySelector(`.${this.retailStoreSelectClassName}`).addEventListener('change', this.onStoreChange);
  }

  onDeactivate = () => {
    this.showLoader()
    store.dispatch(setDeliveryOption(this.defaultDeliveryOption.pk));
  }

  onDeliveryOptionsUpdate = (deliveryOptions) => {
    this.deliveryOptions = deliveryOptions;

    if (this.retailStoreDeliveryOption && !this.initialized) {
      this.init();
    }
  }

  showLoader = () => {
    document.querySelector(`.${this.loaderClassName}`)?.classList.add('-visible');
  }

  hideLoader = () => {
    document.querySelector(`.${this.loaderClassName}`)?.classList.remove('-visible');
  }

  onSelectedShippingUpdate = (shippingOptionId) => {
    this.onStoreChange(false);
  }

  onDeliveryOptionUpdate = (deliveryOptionPk) => {
    const toggleVisibilityElements = [
      document.querySelector(this.sameBillingCheckboxSelector).parentNode,
      this.billingAddressList.querySelector(this.deleteAddressButtonSelector),
      this.billingAddressList.querySelector(this.updateAddressButtonSelector)
    ];
    
    this.hideLoader();
    this.currentDeliveryOptionPk = deliveryOptionPk;
    this.isActive = this.currentDeliveryOptionPk === this.retailStoreDeliveryOption?.pk;
    document.querySelector(`.${this.clickCollectBoxClass}`)?.classList.toggle('-active', this.isActive);

    store.dispatch(setSameBillAddress(!this.isActive));


    toggleVisibilityElements.forEach(el => {
      if (this.isActive) {
        el?.setAttribute('style', 'display: none !important;');
      } else {
        el?.removeAttribute('style');
      }
    });

    this.shippingAddressList.querySelectorAll(this.addressBoxSelector).forEach(addressBox => {
      addressBox.classList.toggle('click-collect-disabled', this.isActive);
    });
  }

  onRetailStoresUpdate = (retailStores) => {
    this.retailStores = retailStores;
  }

  onCityChange = () => {

    if(!this.isActive) {
        return
    }

    const cityPk = parseInt(document.querySelector(`.${this.retailStoreCitySelectClassName}`).value);
    const retailStores = getValue(selectRetailStores);
    const stores = retailStores.filter(store => store.township.city.pk === cityPk);
    const storesSelect = document.querySelector(`.${this.retailStoreSelectClassName}`);
    const selectedRetailStore = getValue(selectCurrentRetailStore);

    storesSelect.toggleAttribute('hidden', cityPk === -1);
    storesSelect.innerHTML = '';
    storesSelect.append(...stores.map(store => {
      const option = document.createElement('option');
      option.value = store.pk;
      option.innerText = store.name;
      return option;
    }));

    if (selectedRetailStore && storesSelect.querySelector(`option[value="${selectedRetailStore.pk}"]`)) {
      storesSelect.value = selectedRetailStore.pk;
    } else if (storesSelect.querySelector('option')) {
      storesSelect.value = storesSelect.querySelector('option').value;
    }
    this.onStoreChange();
  }

  onStoreChange = (sendRetailStoreRequest = true) => {
    const storesSelect = document.querySelector(`.${this.retailStoreSelectClassName}`);
    const currentBillingAddressPk = this.billingAddressList.querySelector(`${this.addressBoxSelector}.checked`)?.dataset.pk;
    const currentShippingPk = getValue(selectCurrentShippingId);


    if (!currentBillingAddressPk) {
      return;
    }


    if (sendRetailStoreRequest && this.isActive) {
        store.dispatch(setRetailStore(storesSelect.value, currentBillingAddressPk));
    }

    if (currentShippingPk) {
      setTimeout(() => {
        document.querySelector(this.checkoutProceedButtonSelector).removeAttribute('disabled');
      }, 0);
    }
  }

  fillCitySelect = () => {
    const citySelect = document.querySelector(`.${this.retailStoreCitySelectClassName}`);
    const selectedRetailStore = getValue(selectCurrentRetailStore);


    if (!citySelect) {
      return;
    }

    const cityPks = new Set(this.retailStores.map(store => store.township.city.pk));

    citySelect.innerHTML = '';
    citySelect.append(...cityPks.map(pk => {
      const city = this.retailStores.find(store => store.township.city.pk === pk)?.township.city;
      const option = document.createElement('option');
      option.value = city.pk;
      option.innerText = city.name;
      return option;
    }));

    if (selectedRetailStore) {
      citySelect.value = selectedRetailStore.township.city.pk;
    } else if (citySelect.querySelector('option')) {
      citySelect.value = citySelect.querySelector('option').value;
    }

    this.onCityChange();
  }
}