import RegisterForm from './forms/registerForm';

class Register {
  constructor() {
    const $form = $(document.getElementById('RegisterForm'));

    this.registerForm = new RegisterForm($form);
    this.facebookRegisterButton = document.querySelector('.js-facebook-register');

    if(!this.facebookRegisterButton){
      return;
    }
    this.facebookRegisterButton.addEventListener('click', function(e) {
      e.preventDefault();
      localStorage.setItem('registerAnalyticsData', JSON.stringify({ method:'facebook' }));
      location.href = this.href;
    });
  }
}

export default Register;