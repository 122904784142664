import {addAdapter, initAdapters, pushEvent} from 'shop-packages/analytics';
import GoogleAnalyticsAdapter from 'shop-packages/analytics/adapters/google-analytics';
import GoogleTagManager from 'shop-packages/analytics/adapters/gtm';
import * as actionTypes from "shop-packages/analytics/action-types";

export default function () {
  const googleAnalyticsAdapter = new GoogleAnalyticsAdapter({key : "" });
  const googleTagManager = new GoogleTagManager({key : "GTM-TFRGBZT" });

  addAdapter(googleAnalyticsAdapter);
  addAdapter(googleTagManager);

  initAdapters();

  function productListViewedPush(e){
    e.detail.each(function () {
      const analyticsData = JSON.parse($(this).text());
  
      if (analyticsData.type === actionTypes.productListViewed) {
        let loopCount = analyticsData.payload.length / 6;
        for(let i = 0 ; i < loopCount ; i++){
          let currentData =  {
            type: actionTypes.productListViewed,
            payload: []
          };
          const startIndex = i * 6;
          const endIndex = startIndex + 6;
          currentData.payload = [...analyticsData.payload.slice(startIndex , endIndex)];
          pushEvent(currentData);
        }
        return
      }
  
      pushEvent(analyticsData);
    });
  }

  document.querySelector('#ListProductWrapper')?.addEventListener('productListPush', (e) => { productListViewedPush(e); }, false);
  productListViewedPush({'detail': $('.analytics-data')});

  if (GLOBALS.userLoggedIn) {
    const analyticsDataUser = JSON.parse($('.js-analytics-user-data').text());
    dataLayer.push(analyticsDataUser);
  }
}