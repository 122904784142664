import { ApiClient } from 'shop-packages';
import { templateRenderer } from '../../utils';

export default class FavouriteProduct {
  constructor(){
    this.init();

    this.$removeAllFavourite = $('.js-remove-from-all-favourites');
    this.$removeAllFavourite.on('click', this.onClickRemoveAllFavouriteElem.bind(this));
    this.fetchFavouriteProducts();
  }

  init(){
    this.$favouriteElem = $(document.getElementById('favouriteProductElem'));
    this.$removeFavourite = $('.js-remove-from-favourites');
    this.$favouriteItemsWrapper = $(document.getElementById('FavouritesWrapper'));
    this.$favouriteItemTemplate = $(document.getElementById('FavoriteProductItem')).html();
    this.$favouriteEmptyTemplate = $(document.getElementById('FavoriteEmpty')).html();

    this.$favouriteElem.on('click', this.onClickFavouriteElem.bind(this));
    this.$removeFavourite.on('click', this.onClickRemoveFavouriteElem.bind(this));
  }

  async onClickFavouriteElem(){
    const productPk = this.$favouriteElem.attr('data-product-pk');
    const isActive = this.$favouriteElem.hasClass('active');

    try{
      isActive ? await ApiClient.favorites.removeFavourite(productPk)
      : await ApiClient.favorites.addFavourite(productPk);

      this.$favouriteElem.toggleClass('active');
    }
    catch(e){
      console.log(e);
    }
  }

  async onClickRemoveFavouriteElem(e){
    e.preventDefault();
    const productPk = $(e.target).attr('data-product-pk');

    try{
      await ApiClient.favorites.removeFavourite(productPk);
    }
    catch(e){
      console.log(e);
    }

    this.fetchFavouriteProducts();
  }

  async onClickRemoveAllFavouriteElem(e){
    e.preventDefault();
    for (const item of this.$removeFavourite){
      let productPk = $(item).attr('data-product-pk');

      try{
        if (!productPk.includes('%'))
          await ApiClient.favorites.removeFavourite(productPk);
      }
      catch(e){
        console.log(e);
      }
    }

    this.fetchFavouriteProducts();
  }

  async fetchFavouriteProducts(){
    const favourites = await ApiClient.favorites.fetchFavourites(200);
    const currentFavouriteProducts = favourites.data.results.map((item) => {
      let product = item.product;
      let productColor = '';
      let productSize = '';

      if(typeof product.attributes.integration_kalip_ust !== "undefined"){productColor = `${product.attributes.integration_kalip_ust},`};
      if(typeof product.attributes.integration_beden1 !== "undefined"){productSize = `${product.attributes.integration_beden1} Beden`};

      let data = {
        pk: item.pk,
        url: product.absolute_url,
        name: product.name,
        retailprice: product.price >= product.retail_price ? '' : `${product.retail_price + ' TL'}`,
        price: `${product.price + ' TL'}`,
        img: `<img class="w-100" src="${product.productimage_set[0].image}" alt="${product.name}">`,
        productinfo: `${productColor || ''} ${productSize || ''}`
      }

      const favouriteProduct = templateRenderer(this.$favouriteItemTemplate, data);

      return favouriteProduct;
    });

    
    if (favourites.data.count > 0) {
      this.$favouriteItemsWrapper.html(currentFavouriteProducts.join(''));
    }
    else {
      this.$favouriteItemsWrapper.html(this.$favouriteEmptyTemplate);
      this.$removeAllFavourite.addClass('d-none');
    }
    
    this.init();
  }
}