import { ApiClient } from 'shop-packages';
import store from "shop-packages/redux/store";
import {
  setBasket,
  errorBasket,
} from "shop-packages/redux/basket/reducer";
import ActionButton from '../button-action';
import { setAnalyticsProductToStorage } from '../../utils';

const AddToCartButton = ($element, benefits) => {
  const button = new ActionButton($element);

  button.action = () => {
    let data = {
      product: parseInt($element.attr('data-product'), 10),
      quantity: parseInt($element.attr('data-quantity'), 10)
    };

    if (benefits) {
      data['sub_items'] = benefits.toBasketItems();
    }

    return ApiClient.request('POST', window.URLS.basket, JSON.stringify(data), {
      contentType: 'application/json'
    });
  };

  button.beforeAction = () => {
    if (!$element.data('product')) throw ('error');

    button.actionParams = [
      $element.data('product'),
      $element.data('quantity'),
    ];
  };

  button.onSuccess = response => {
    store.dispatch(setBasket(response.data));

    if (button.success) {
      window.dataLayer.push({ ecommerce: null });
      button.success(response);
      try {
        let element = document.querySelector('.js-analytics-product');
        if (!element) {
          return;
        }

        let selectedSize = '';
        if (element.classList.contains('combine-product')) {
          const analyticsElement = document.querySelector(`.js-analytics-product[data-basecode="${$element.data('baseCode')}"]`);
          if (analyticsElement) {
            element = analyticsElement;
            const productOptions = document.querySelector(`select[data-basecode='${$element.data('baseCode')}']`);
            if (productOptions) {
              selectedSize = productOptions.options[productOptions.selectedIndex]?.text;
            }
          }
        }

        const ecommerce = JSON.parse(element.textContent);
        const items = ecommerce.items[0];

        items.price && (items.price = +items.price);
        items.discount_rate && (items.discount_rate = +items.discount_rate);
        items.discount && (items.discount = +items.discount);
        items.item_last_price && (items.item_last_price = +items.item_last_price);
        items.item_size && (items.item_size = selectedSize ? selectedSize : items.item_size);
        ecommerce.value && (ecommerce.value = +ecommerce.value);

        const analyticsProducts = JSON.parse(localStorage.getItem('analyticsProducts') || '[]');
        const findProducts = analyticsProducts && analyticsProducts.find(product => product.basecode == element.dataset.basecode);
        items.index = +findProducts?.index ? +findProducts.index : +items?.index;
        items.item_list_id = findProducts?.listIdx || items.item_list_id;
        items.item_list_name = findProducts?.listName || items.item_list_name;

        window.dataLayer.push({
          event: 'add_to_cart',
          ecommerce
        });

        setAnalyticsProductToStorage({
          pk: element?.dataset?.pk,
          idx: items.index,
          basecode: items.item_id,
          listIdx: items.item_list_id,
          listName: items.item_list_name
        });
      } catch (error) {
        console.log(error);
      }
    };
  };
  

  button.onError = err => {
    store.dispatch(errorBasket({
      pk: $element.data('product'),
      error: err.responseJSON,
    }));

    if (button.error) button.error(err);
  };

  return button;
};

export default AddToCartButton;