import { ApiClient } from 'shop-packages';
import 'jquery-validation'

export default class OrdersCancellation{
  constructor(){
    this.cancellationReasons();
    this.$container = $(document.getElementById('OrderCancellationWrapper'));
    this.$orderId = this.$container[0].getAttribute('data-order-id');
    this.$cancellationSelect = this.$container.find('.js-reason-select select');
    this.$cancellationForm = this.$container.find('.js-cancellation-form');
    this.$cancellationProduct = this.$container.find('.js-order-cancellation-product');
    this.$cancellationType = this.$container.find('.js-order-cancellation-product').data('cancellation-type');
    this.$submitButton = $('.order-cancellation-button');

    this.cancellationFormSubmit();

    const updateSubmitButtonState = () => {
      const selectedCount = $('.order-cancellation-products input').filter(':checked').length;
      if (selectedCount) {
        this.$submitButton.show();
        $('.order-cancellation-button').html(`${selectedCount} Ürün ile Devam Et <i class="icon-arrow_right ml-1"></i>`);
      } else {
        this.$submitButton.hide();
        $('.order-cancellation-button').html(`Devam Et`);
      }
    };

    $('.order-cancellation-products input').change(updateSubmitButtonState);

    updateSubmitButtonState();
  }

  async cancellationReasons(){
    this.cancellationReasons = await ApiClient.order.fetchCancellationReasons();
    this.cancellationReasons.data.results.filter( (reason) => (reason.cancellation_type === this.$cancellationType)).map(reason => {
      this.$cancellationSelect.append(`
        <option value="${reason.id}" data-type="${reason.cancellation_type}"> ${reason.subject} </option>
      `)
    });
  }

  cancellationFormSubmit(){
    this.$cancellationForm.validate({
      submitHandler: (_, event) => {
        event.preventDefault();
        this.$cancellationProduct.map(async (i, product) => {
          const productId = $(product).attr('data-cancellation-id');
          const productCode = $(product).attr('data-cancellation-basecode');
          const cancellationSku = $('.js-order-cancellation-product').attr('data-cancellation-sku');
          const $selectedOption = $(product).find('.js-reason-select select option:selected');
          const cancellationAvailability = $(product).find('.js-order-cancellation-checkbox input').is(':checked') && $selectedOption.val();
          const holderName = $('.js-order-cancellation-customer-name').val() + ' ' +  $('.js-order-cancellation-customer-surname').val();
          const iban = $('.js-order-cancellation-customer-iban').val();
          const sizeProduct = $(product).find('.order-detail-product-attributes').text().trim();
          const number = $('.js-order-detail-number').text().trim();
         
          
          
          if(!cancellationAvailability){
            return;
          }
          
          try{
            await ApiClient.order.sendCancelRequestIban(productId, {
              id: $selectedOption.val(),
              type:$selectedOption.attr('data-type'),
              description:$selectedOption.text(),
              iban : iban,
              holder_name: holderName, 
            });

            $(product).find('.js-order-cancellation-checkbox .input-wrapper').hide();

            if ($selectedOption.attr('data-type') == 'cancel') {
              $(product).find('.js-reason-select').replaceWith(`
                <span class="text-muted">İptal Talebiniz Alınmıştır</span>
              `);
            }
            else if ($selectedOption.attr('data-type') == 'refund'){
              let orders = await ApiClient.order.searchOrder(this.$orderId);
              let order_item = orders.data.orderitem_set.filter(order_item => order_item.id == productId);
              let number = $('.js-order-detail-number').text().trim();              
              let fitProduct = $selectedOption.attr('value') == '34' || $selectedOption.attr('value') == '5';
              let returnReason = fitProduct ? 'size' : 'other';
              let fitReason;

              if($selectedOption.attr('value') == '34'){
                fitReason = "large"
              } else if ($selectedOption.attr('value') == '5') {
                fitReason = "small"
              } else {
                fitReason = "";
              }

              this.$container.replaceWith(`
              <div class="order-refund">
                <div class="order-refund-title mb-1">İptal/İade Talebi</div>
                <div class="order-refund-shipping">
                  <div class="mb-3">Sipariş Numarası: <span>${number}</span></div>
                  <div>İade etmek istediğiniz ürünlerinizi etiketleri üzerinde ve kullanılmamış olarak, kargoda zarar görmeyecek şekilde 10 gün içerisinde bize iletmelisiniz.</div>
                </div>
                <div class="order-refund-logo">
                  <img alt="Status Code" src="${window.URLS.static}img/Group-117.svg" class="mr-4">
                  <div class="order-refund-product">İade Talebiniz <br> Oluşturuldu</div>
                </div>
                <div class="order-refund-company">
                  <div>Kargo Firması</div>
                  <img src="${window.URLS.static}img/mng_logo.png" alt="mng_logo" class="">
                </div>
                <div class="order-refund-code"><span>Cari No: 334406491</span> <br> İade Kargo Kodu: <span>${ order_item[0].active_cancellation_request.easy_return.code }</span></div>
                <div class="order-refund-desc">İade kodunu kargo görevlisine söylemeniz yeterlidir. <br> İade adresi belirtmenize gerek yoktur.</div>
                <button><a href="/">Alışverişe Devam Et</a></button>
              </div>
              `)
            }

            updateSubmitButtonState();
          }
          catch(e){
            console.log(e);
          }
        });

        const analyticsItems = [];
        this.$cancellationProduct.each(async (_, product) => {
          try {
            const productId = $(product).attr('data-cancellation-id');
            const $selectedOption = $(product).find('.js-reason-select select option:selected');
            const cancellationAvailability = $(product).find('.js-order-cancellation-checkbox input').is(':checked') && $selectedOption.val();
            if (!cancellationAvailability) {
              return;
            }
            analyticsItems.push(JSON.parse($(`.js-data-analytics-wrapper[data-pk=${productId}]`).text()));
          }
          catch (error) {
            console.log(error)
          }
        });

        if (analyticsItems.length > 0) {
          const totalPrice = analyticsItems.reduce((acc, item) => acc + item?.item_last_price, 0);
          window.dataLayer.push({
            event: 'refund',
            ecommerce: {
              transaction_id: +this.$container.data('orderNumber'),
              tax: +this.$container.data('tax'),
              shipping: +this.$container.data('shippingAmount'),
              currency: "TRY",
              value: totalPrice,
              items: analyticsItems,
            }
          });
        }
        
        return false;
      },
      errorPlacement: (error, element) => {
        if (['checkbox', 'radio'].indexOf(element.attr('type')) + 1) {
          error.insertAfter(element.parent());
        } else {
          error.insertAfter(element);
        }
      },
    });
  }
}