import $ from 'jquery';
import AddToCartButton from '../../components/button-add-to-cart';
import { setAnalyticsProductToStorage } from '../../utils';
export default class NewSeasonLandingPage {
	constructor() {
		this.init();
	}
	init() {
		$('.product-list-wrapper').each((_, item) => {
			const productSlider = $(item).find('.product-list').lightSlider({
				item: 3,
				auto: true,
				slideMargin: 40,
				pause: 5000,
				loop: true,
				autoWidth: false,
				enableDrag: true,
				pager: false,
				responsive: [
					{
						breakpoint: 800,
						settings: {
							slideMargin: 20,
						}
					},
					{
						breakpoint: 390,
						settings: {
							slideMargin: 12,
						}
					}
				]
			});

			$(item).find('.prev').on('click', () => {
				productSlider.goToPrevSlide();
			});

			$(item).find('.next').on('click', () => {
				productSlider.goToNextSlide();
			});
		});

		$('.featured-slide-wrapper').each((_, item) => {
			const featuredSlider = $(item).find('.featured-slider').lightSlider({
				item: 5,
				auto: true,
				pause: 5000,
				loop: true,
				autoWidth: false,
				slideMove: 3,
				adaptiveHeight: true,
				enableDrag: true,
				pager: false
			});

			$(item).find('.prev').on('click', () => {
				featuredSlider.goToPrevSlide();
			});

			$(item).find('.next').on('click', () => {
				featuredSlider.goToNextSlide();
			});
		})


		this.addToCart()
	}

	addToCart() {

		$('.js-fast-buy').on('click', (e) => {
			e.preventDefault();
			e.stopPropagation();
		});

		document.addEventListener('click', (e) => {
			const targetElement = e.target;
			const isClickedProduct = targetElement.closest('.js-product-wrapper');
			if (!isClickedProduct) {
				const allFastElements = document.querySelectorAll('.js-add-fast-buy-mobile');
				allFastElements.forEach((el) => {
					el.classList.remove('-hidden');
					const fastBuyMobile = el.closest('.js-fast-buy');
					fastBuyMobile && (fastBuyMobile.classList.remove('-active'))
					fastBuyMobile.querySelector('.js-fast-buy-sizes') && (fastBuyMobile.querySelector('.js-fast-buy-sizes').classList.remove('-active'));
				});
			}
		});

		document.querySelectorAll('.js-add-fast-buy-mobile').forEach((el) => {
			el.addEventListener('click', (e) => {
				el.classList.add('-hidden');
				const fastBuyMobile = el.closest('.js-fast-buy');
				fastBuyMobile && (fastBuyMobile.classList.add('-active'))
				fastBuyMobile.querySelector('.js-fast-buy-sizes') && (fastBuyMobile.querySelector('.js-fast-buy-sizes').classList.add('-active'));
			});
		});


		$('.js-add-to-cart').each((_, el) => {
			const addToCartButton = AddToCartButton($(el));

			addToCartButton.success = () => {
				window.matchMedia("(max-width:768px)").matches ? $('.js-mini-basket-mobile').fadeIn('500') :
					$('.js-mini-basket-full-screen-content').css('display', 'block');

				const htmlBodySelector = $('html,body');
				htmlBodySelector.addClass('overflow-hidden');

				try {
					const baseCode = $(el).closest(`.js-product-wrapper`).data('basecode');
					if (!baseCode) {
						return;
					}
					const analyticsDataElement = $(`.js-analytics-product[data-basecode=${baseCode}]:not(.clone)`).first();
					if (!analyticsDataElement) {
						return;
					}
					const analyticsData = JSON.parse(analyticsDataElement.text());
					const productItem = analyticsData.items[0];

					setAnalyticsProductToStorage({
						pk: analyticsDataElement.data('pk'),
						idx: productItem.index,
						basecode: productItem.item_id,
						listIdx: productItem.item_list_id || location.pathname,
						listName: productItem.item_list_name
					});
				} catch (error) {
					console.log(error)
				}
			};
		});

		const $mobilBasketPopupClose = $('.js-mini-basket-mobile-continue');
		$mobilBasketPopupClose.on('click', () => {
			const htmlBodySelector = $('html,body');
			htmlBodySelector.removeClass('overflow-hidden');
			$('.js-mini-basket-mobile').fadeOut('500');
		});
	}
}