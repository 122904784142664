import 'jquery-validation';

import IMask from 'imask';
import 'imask/esm/masked/pattern';

import store from 'shop-packages/redux/store';
import observe, { getValue } from 'shop-packages/redux/connectSelector';
import {
  setBinNumber,
  setInstallment,
  completeCheckout,
} from 'shop-packages/redux/checkout/actions';
import {
  setCardCVV,
  setCardMonth,
  setCardNumber,
  setCardYear,
  setAgreement,
  setBinNumberLength
} from 'shop-packages/redux/checkout/reducer';
import {
  selectBinNumber,
  selectCardMonth,
  selectCardNumber,
  selectCardYear,
  selectCardCVV,
  selectCardTypeName,
  selectCardTypeImage,
  selectInstallments,
  selectCurrentInstallmentId,
  selectCurrency,
  selectAgreement,
  selectPending,
  selectErrors,
} from 'shop-packages/redux/checkout/selectors';
import { clearErrors } from 'shop-packages/redux/checkout/reducer';

import { templateRenderer } from '../../../utils';

class CreditCardOption {
  observers = [];
  binNumber = '';
  hasBinNumber = false;
  agreement = false;
  installments = [];
  selectedInstallmentId = null;
  currency = 'TL';
  validator = null;

  constructor() {
    store.dispatch(setBinNumberLength(8));
    this.installmentOptionTemplate = $(document.getElementById('CheckoutInstallmentItemTemplate')).html();

    this.$creditCardForm = $(document.getElementById('CreditCardForm'));
    this.$completeButton = $(document.getElementById('CheckoutCompleteButton'));

    this.$cardInput = $(document.getElementById('CheckoutCardNumber'));
    this.$cardMonthInput = $(document.getElementById('CheckoutCardMonth'));
    this.$cardYearInput = $(document.getElementById('CheckoutCardYear'));
    this.$cardCVVInput = $(document.getElementById('CheckoutCardCVV'));
    this.$agreementInput = $('.js-checkout-agreement-input');
    this.$checkoutCompleteError = $('.js-checkoutComplete-error');

    this.$cardInputMasked = IMask(this.$cardInput[0], {
      mask: '0000 0000 0000 0000',
      placeholderChar: '_',
      lazy: false,
    });

    this.$cardCVVMasked = IMask(this.$cardCVVInput[0], {
      mask: '000',
    });

    this.$cardImageContainer = $(document.getElementById('CheckoutCardImage'));
    this.$cardNameContainer = $(document.getElementById('CheckoutCardName'));
    this.$installmentsEmptyView = $(document.getElementById('CheckoutInstallmentsEmptyView'));
    this.$installmentsContainer = $(document.getElementById('CheckoutInstallmentsContainer'));
    this.$installmentsListContainer = $(document.getElementById('CheckoutInstallmentsList'));

    this.$cardInput.on('input.cardNumber', this.onCardInputChange.bind(this));
    this.$cardMonthInput.on('input.cardMonth', this.onCardMonthChange.bind(this));
    this.$cardYearInput.on('input.cardYear', this.onCardYearChange.bind(this));
    this.$cardCVVInput.on('input.cardCVV', this.onCardCVVChange.bind(this));
    this.$agreementInput.on('input.agreement', this.onAgreementChange.bind(this));

    this.currency = getValue(selectCurrency);
    this.binNumber = getValue(selectBinNumber);
    this.selectedInstallmentId = getValue(selectCurrentInstallmentId);

    this.initObservers();

    this.initCreditCardForm();
  }

  onCardInputChange(e) {
    store.dispatch(setCardNumber(this.$cardInputMasked.unmaskedValue));
  }

  onCardMonthChange(e) {
    store.dispatch(setCardMonth($(e.currentTarget).val()));
  }

  onCardYearChange(e) {
    store.dispatch(setCardYear($(e.currentTarget).val()));
  }

  onCardCVVChange(e) {
    store.dispatch(setCardCVV($(e.currentTarget).val()));
  }

  onAgreementChange(e) {
    store.dispatch(setAgreement($(e.currentTarget).is(':checked')));
  }

  onCardMonthUpdate(cardMonth) {
    this.$cardMonthInput.val(cardMonth);

    if (this.validator) {
      this.validator.element(this.$cardMonthInput);
    }
  }

  onCardNumberUpdate(cardNumber) {
    this.$cardInputMasked.value = cardNumber;
  }

  onCardYearUpdate(cardYear) {
    this.$cardYearInput.val(cardYear);

    if (this.validator) {
      this.validator.element(this.$cardYearInput);
    }
  }

  onCardCVVUpdate(cardCVV) {
    this.$cardCVVInput.val(cardCVV);
  }

  onCardTypeNameUpdate(typeName) {
    this.$cardNameContainer.html(typeName);
  }

  onCardTypeImageUpdate(imageSrc) {
    this.$cardImageContainer.html(`<img src="${imageSrc}">`);
  }

  onBinNumberUpdate(binNumber) {
    this.hasBinNumber = !!binNumber;

    if (!this.hasBinNumber) {
      this.$cardImageContainer.addClass('d-none');
      this.$installmentsEmptyView.addClass('d-flex');
      this.$installmentsContainer.removeClass('d-flex');

      return;
    }

    if (binNumber !== this.binNumber) {
      store.dispatch(setBinNumber(binNumber));
      this.binNumber = binNumber;
    }

    this.$cardImageContainer.removeClass('d-none');
    this.$installmentsEmptyView.removeClass('d-flex');
    this.$installmentsContainer.addClass('d-flex');
  }

  onInstallmentsUpdate(installments) {
    this.installments = installments;
    this.renderInstallments();
  }

  onSelectedInstallmentUpdate(selectedId) {
    this.selectedInstallmentId = selectedId;
    this.$installmentsListContainer
      .find(`.js-installment-input[value="${selectedId}"]`)
      .prop('checked', true);
  }

  onAgreementUpdate(agreement) {
    this.$agreementInput.prop('checked', agreement);
  }

  renderInstallments() {
    const content = this.installments.map(({
      pk,
      label,
      monthly_price_with_accrued_interest: monthly,
      price_with_accrued_interest: total
    }) => {
      const checked = this.selectedInstallmentId === pk ? 'checked' : '';
      const currency = this.currency;

      return templateRenderer(this.installmentOptionTemplate, { pk, label, monthly, total, checked, currency });
    });

    this.$installmentsListContainer.html(content.join(''));
    this.$installmentsListContainer
      .find('.js-installment-input')
      .on('input', (e) => {
        const selected = $(e.currentTarget).val();
        store.dispatch(setInstallment(selected));
      });
  }

  initCreditCardForm() {
    this.validator = this.$creditCardForm.validate({
      submitHandler: (_, event) => {
        event.preventDefault;

        store.dispatch(clearErrors());
        store.dispatch(completeCheckout());

        return false;
      },
      highlight: (element) => {
        const $element = $(element);
        const elementId = $element.attr('id');

        if ($element.hasClass('select2-hidden-accessible')) {
          const $select2Container = $(document.getElementById(`select2-${elementId}-container`));
          $select2Container.parent().addClass('has-errors');
        }

        if ($element.attr('type') === 'checkbox') {
          $element.parent().addClass('has-errors');
          this.$checkoutCompleteError.removeClass('d-none');
        }

        $element.addClass('has-errors');
      },
      unhighlight: (element) => {
        const $element = $(element);
        const elementId = $element.attr('id');

        if ($element.hasClass('select2-hidden-accessible')) {
          const $select2Container = $(document.getElementById(`select2-${elementId}-container`));
          $select2Container.parent().removeClass('has-errors');
        }

        if ($element.attr('type') === 'checkbox') {
          $element.parent().removeClass('has-errors');
          this.$checkoutCompleteError.addClass('d-none');
        }

        $element.removeClass('has-errors');
      },
      errorPlacement: (place, $element) => {
        if ($(document.getElementById('CheckoutCardCVV'))[0] == $element[0] && window.innerWidth > 768) {
          place.insertAfter($element);
        }

        return false;
      },
      rules: {
        card_number: {
          required: true,
          minlength: 16,
          normalizer: () => getValue(selectCardNumber),
        },
        card_month: {
          required: true,
          normalizer: () => getValue(selectCardMonth),
        },
        card_year: {
          required: true,
          normalizer: () => getValue(selectCardYear),
        },
        card_cvv: {
          required: true,
          minlength: 3,
          normalizer: () => getValue(selectCardCVV),
        },
        agreement: {
          required: true,
          normalizer: () => getValue(selectAgreement),
        },
      },
      messages: {
        card_cvv: {
          required: 'Lütfen Cvc Giriniz',
        }
      }
    });
  }

  onPendingUpdate(isPending) {
    !!isPending ?
      this.$completeButton
        .attr('disabled', 'disabled')
        .addClass('loading') :
      this.$completeButton
        .removeAttr('disabled')
        .removeClass('loading');
  }

  onErrorsUpdate(errors) {
    $('.js-error-*').text('').hide();
    for (const key in errors) {
      $(`.js-error-${key}`).text(errors[key]).show();
      $(`.js-error-${key}`).removeClass('d-none');
    }
  }

  initObservers() {
    this.observers = [
      observe(selectBinNumber).subscribe(this.onBinNumberUpdate.bind(this)),
      observe(selectCardMonth).subscribe(this.onCardMonthUpdate.bind(this)),
      observe(selectCardNumber).subscribe(this.onCardNumberUpdate.bind(this)),
      observe(selectCardYear).subscribe(this.onCardYearUpdate.bind(this)),
      observe(selectCardCVV).subscribe(this.onCardCVVUpdate.bind(this)),
      observe(selectCardTypeName).subscribe(this.onCardTypeNameUpdate.bind(this)),
      observe(selectCardTypeImage).subscribe(this.onCardTypeImageUpdate.bind(this)),
      observe(selectInstallments).subscribe(this.onInstallmentsUpdate.bind(this)),
      observe(selectCurrentInstallmentId).subscribe(this.onSelectedInstallmentUpdate.bind(this)),
      observe(selectAgreement).subscribe(this.onAgreementUpdate.bind(this)),
      observe(selectPending).subscribe(this.onPendingUpdate.bind(this)),
      observe(selectErrors).subscribe(this.onErrorsUpdate.bind(this)),
    ];
  }

  unmount() {
    this.$cardInput.off('input.cardNumber');
    this.$cardMonthInput.off('input.cardMonth');
    this.$cardYearInput.off('input.cardYear');
    this.$cardCVVInput.off('input.cardCVV');
    this.$agreementInput.off('input.agreement');

    store.dispatch(setCardNumber(''));
    store.dispatch(setCardMonth(''));
    store.dispatch(setCardYear(''));
    store.dispatch(setCardCVV(''));
    store.dispatch(setAgreement(false));

    this.validator.destroy();
    this.$cardInputMasked.destroy();
    this.$cardCVVMasked.destroy();
    
    for (const observer of this.observers) {
      observer.unsubscribe();
    }
  }
}

export default CreditCardOption;