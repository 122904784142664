const accountMenuListItem = () => {

  if ($('.js-account-menu-list li').length > 0) {
    const currentPath = location.pathname + location.search;
    $('.account-menu__item-list li').each(function () {
      if ($(this).find('a').attr('href') === currentPath) {
        $(this).find('span').addClass('active');
      }
    })
  }
}

export default accountMenuListItem;