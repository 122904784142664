import {
  ApiClient
} from 'shop-packages';
import store from 'shop-packages/redux/store';
import {
  setBasket,
  clearErrorBasket
} from 'shop-packages/redux/basket/reducer';

import Component from '../../components/dom/component';
import observe from 'shop-packages/redux/connectSelector';
import {
  basketSelector
} from 'shop-packages/redux/basket/selectors';


import ActionButton from '../../components/button-action';
export default class VoucherForm extends Component {
  static selector = '.js-basket-voucher';

  constructor(_$) {
    super(_$, VoucherForm.selector);

    this.$form = this._$.find('.js-basket-voucher-form');
    this.$code = this.$form.find('.js-basket-voucher-form-code');
    this.$submit = this.$form.find('.js-basket-voucher-form-submit');
    this.$remove = this.$form.find('.js-basket-voucher-form-remove');
    this.$error = this.$form.find('.js-basket-voucher-form-error');
    this.$openFormButton = this._$.find('.js-basket-voucher-form-open-button');

    this._submit = this.voucherSubmitButton(this.$submit, this.$code);
    this._remove = this.voucherRemoveButton(this.$remove, this.$code);
    
    this.$openFormButton.on('click', () => {
      this.$form.show();
      this.$openFormButton.hide();
    });

    this._submit.error = error => this.placeVoucherError(error?.non_field_errors);
    this._remove.error = error => this.placeVoucherError(error?.non_field_errors);

    observe(basketSelector).subscribe(({ voucher_code }) => this.initVoucherForm(voucher_code));
  }

  initVoucherForm = voucherCode => {
    const hasVoucherCode = voucherCode && voucherCode != null && voucherCode != '';
    hasVoucherCode && this.$code.val(voucherCode);
    this.setVoucherStatus(hasVoucherCode);
  }

  setVoucherStatus = hasVoucherCode => {
    this.$code.attr('disabled', hasVoucherCode);
    this.$submit.attr('hidden', hasVoucherCode);
    this.$remove.attr('hidden', !hasVoucherCode);
  }

  placeVoucherError = error => error && error != '' ?
    this.$error.attr('hidden', false).html(error) :
    this.$error.attr('hidden', true).html('');

  voucherSubmitButton = ($buttonElement, $codeElement) => {
    const button = new ActionButton($buttonElement);
    button.action = ApiClient.basket.setDiscount;

    button.beforeAction = () => button.actionParams = [
      $codeElement.val().trim()
    ];

    button.onSuccess = response => {
      store.dispatch(setBasket(response.data));
      store.dispatch(clearErrorBasket());
      button.success && button.success(response);
    };

    button.onError = err => button.error && button.error(err.responseJSON);

    return button;
  };

  voucherRemoveButton = ($element, $codeElement) => {
    const button = new ActionButton($element);
    button.action = ApiClient.basket.removeDiscount;

    button.onSuccess = response => {
      store.dispatch(setBasket(response.data));
      $codeElement.val('');
      button.success && button.success(response);
    };

    button.onError = err => button.error && button.error(err.responseJSON);

    return button;
  };
}
