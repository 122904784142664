import LoginForm from './forms/loginForm';

class Login {
  constructor() {
    const $form = $(document.getElementById('AuthLoginForm'));

    this.loginForm = new LoginForm($form);
    this.facebookLoginButton = document.querySelector('.js-facebook-login');
    if(!this.facebookLoginButton){
      return;
    }
    this.facebookLoginButton.addEventListener('click', function(e) {
      e.preventDefault();
      localStorage.setItem('loginAnalyticsData', JSON.stringify({ method:'facebook' }));
      location.href = this.href;
    });
  }
}

export default Login;