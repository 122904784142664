import ActionForm, { displayErrors } from '../../components/form-action';
import {ApiClient} from "shop-packages";

export default class accountContact {

  constructor() {

    this.subjectSelect = $('[name="subject"]');
    this.orderSelect = $('[name="order"]');
    this.modal = $('.js-success_modal');
    this.contactForm = $('.js-contact-form');
    this.phoneRadioInput = $('.js-phone-radio')[0];
    this.choices = $('input[type=radio]');
    this.selectedCommunicationType = $('input[type=radio]:checked')[0]?.value;
    this.textArea = document.querySelector('textarea');

    this.phoneRadioInput?.addEventListener('change', function (e) {
      if(e.target.form.querySelector('.js-phone-input').value === ""){
        this.checked = false;
        e.target.form.querySelector('.js-email-radio').checked = true;
        return;
      }
    })

    Array.from(this.choices).forEach(radio => {
      radio.addEventListener('change', (e) => {
        if (e.target.checked){
          this.selectedCommunicationType = e.target.value;
        }
      })
    })

    this.form = ActionForm(this.contactForm);

    this.form.action = ApiClient.contact.sendContactForm;

    this.form.beforeAction = () => {
      this.textArea.value += ` *** İletişim Tercihi: ${this.selectedCommunicationType} ***`;
      this.form.actionParams = [
        this.contactForm.serialize(),
      ];
    };

    this.form.onSuccess = (response) => {
      this.onSuccess(response);
    };

    this.form.onError = err => {
      this.onError(err);
    };


    this.init()
  }

  init(){
   this.renderSubjectOption();
   this.renderOrderOption() ;
   this.subjectSelectChange() ;
   this.modalAction() ;
  }


  onSuccess(response) {
    if (response.data && response.data.errors) {
      displayErrors(response.data.errors, this.form);
      return;
    }

    this.modal.removeClass('d-none');
    $('html,body').addClass('overflow-hidden')
  }

  onError(response) {
    console.log(response)
  }

  async getSubjectData() {
    let subjectData = await ApiClient.contact.fetchContactSubjects();
    return await subjectData.data;
  }

  async getOrderData() {
    let orderData = await ApiClient.order.fetchOrders();
    return await orderData.data;
  }

  modalAction(){
    this.modal.on('click',function () {
        $(this).addClass('d-none')
        $('html,body').removeClass('overflow-hidden')
    })
  }

  subjectSelectChange(){
    this.subjectSelect.on('change' , (e) => {
      const $this = e.currentTarget;
      const isOrderNeeded = $($this).find(':selected').data('order-needed');
      if(isOrderNeeded){
        this.orderSelect.parent().removeClass('d-none');
        return
      }
      this.orderSelect.parent().addClass('d-none')
    })
  }

  renderOrderOption() {
    this.getOrderData().then((data) => {
      let orderOptions = data.results.map(function (item) {
        return $(`<option value="${item.id}">
                    ${item.number}
                  </option>`);
      });

      orderOptions.unshift(`<option value=""></option>`);
      this.orderSelect.html(orderOptions);
    })
  }

  renderSubjectOption() {
    this.getSubjectData().then((data) => {
      const filteredData = this.contactForm.hasClass('js-spec-contact')
        ? data.filter((item) => !item.is_order_needed) : data;
      let subjectOptions = filteredData.map(function (item) {
        return $(`<option value="${item.id}" data-order-needed="${item.is_order_needed}">${item.text}</option>`);
      });

      subjectOptions.unshift(`<option value=""></option>`);
      this.subjectSelect.html(subjectOptions);
    })
  }
}