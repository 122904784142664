/**
 * Subscription module which can be modified by properties
 * @module Subscription
 */

import $ from 'jquery';

/** Class constructing subscription form actions */
class Subscription {
  /** @property {string} url - URL for POSTing subscription */
  url = '/email-subscription/';

  /** @property {string} formSelector - Main subscription form element selector */
  formSelector = '.js-subscription-form';

  /** @property {string} inputSelector - Mail input selector */
  inputSelector = '.js-subscription-mail';

  /** @property {string} checksSelector - Required checkbox list selector */
  checksSelector = '.js-subscription-contract';

  /** @property {string} title - Subscription modal title */
  title = 'E-BÜLTEN';

  /** @property {string} plsInformCheckText -
   *  _"Please check required fields"_ text
   *  for subscription modal
   */
  plsInformCheckText = 'Lütfen sözleşmeyi okuyup onaylayınız.';

  /** @property {string} subscriptedText -
   *  _"Success"_ text
   *  for subscription modal */
  subscriptedText = 'E-posta adresiniz sisteme kaydolmuştur.';

  /** @property {string} plsValidMailText -
   *  _"Invalid mail address"_ text
   *  for subscription modal */
  plsValidMailText = 'Lütfen geçerli bir e-posta adresi giriniz.';

  /** @property {string} plsTryAgainText -
   *  _"Unknown error"_ text
   *  for subscription modal */
  plsTryAgainText = 'Bir hata oluştu. Lütfen sonra tekrar deneyiniz.';

  /**
   * @private
   * @property {JQuery<HTMLElement>} $form - URL for POSTing subscription
   */
  $form = $(this.formSelector);

  /**
   * @private
   * Title getter
   * @type {string}
   */
  get titleText() {
    return `<p class="confirm-title">${this.title}</p>`;
  }

  /**
   * @method onSubmit - Submit action
   * @return {Subscription} Returns Subscription class
   */
  onSubmit = () => {
    const _this = this;

    this.$form.on('submit', function (e) {
      e.preventDefault();

      const emailVal = $(this).find(_this.inputSelector).val();
      const genderVal = $(this).find('input[type="radio"]').length > 0
        ? $(this).find('input[name="gender"]:checked').val() : '';

      const isInformChecked = $(this).find(_this.checksSelector).length > 0
        ? (() => {
          let boolean = true;

          $(this).find(_this.checksSelector).each(
            function () {
              if (!$(this).prop('checked')) {
                boolean = false;
                return false;
              }
            }
          );

          return boolean;
        })() : false;

      if (!isInformChecked) {
        $.ajax({
          url: _this.url,
          method: 'POST',
          beforeSend: xhr => {
            xhr.setRequestHeader(
              'X-CSRFToken',
              $(window.GLOBALS.csrf_token).val()
            );
          },
          data: {
            email: emailVal,
            gender: genderVal
          },
          success: function () {
            $.alert({
              title: _this.titleText,
              content: _this.subscriptedText,
              buttons: {
                ok: {
                  text: 'Tamam'
                }
              }
            });
            $('.js-subscription-mail').val('');
          },
          error: function (errors) {
            if (errors.responseJSON.email.length > 0) {
              $.alert({
                title: _this.titleText,
                content: _this.plsValidMailText,
                buttons: {
                  ok: {
                    text: 'Tamam'
                  }
                }
              });
            } else {
              $.alert({
                title: _this.titleText,
                content: _this.plsTryAgainText,
                buttons: {
                  ok: {
                    text: 'Tamam'
                  }
                }
              });
            }
          }
        });
      } else {
        $.alert({
          title: _this.titleText,
          content: _this.plsInformCheckText,
          buttons: {
            ok: {
              text: 'Tamam'
            }
          }
        });
      }
    });

    return this;
  }
}

/**
 * @exports Subscription
 */
export default Subscription;
