import formAction from '../formAction';
import IMask from 'imask';

export default class Smartshop {

  constructor() {
    this.init();
  }

  init() {
    formAction();
    this.applyPhoneMask();
    this.closeModal();
    this.openModal();
  }

  applyPhoneMask() {
    this.$phoneNumberInputMask = IMask($('input[name=phone]')[0], {
      mask: '\\0\\ {5}00 000 00 00',
      placeholderChar: '_',
      lazy: true
    });
  }

  openModal() {
    $('.js-modal').click(function(){
      $('.js-appointment-form').addClass('show');
      $('body').addClass('black-background');
    });
  }

  closeModal() {
    $(document).on('click', function (event) {
      if (!$(event.target).closest('.js-appointment-form').length && !$(event.target).closest('.js-modal').length) {
        $('.js-appointment-form').removeClass('show');
        $('body').removeClass('black-background');
      }
    });

    $('.js-close-modal').click(function(){
      $('.js-appointment-form').removeClass('show');
      $('body').removeClass('black-background');
    });
  }
}