$(function () {
  $("div.js-blog-list").slice(0, 8).show();
  $(".js-more-blog").on("click", function (e) {
    e.preventDefault();
    $("div.js-blog-list:hidden").slice(0, 2).slideDown();
    if ($("div.js-blog-list:hidden").length == 0) {
      $(".js-more-blog").fadeOut("slow");
    }
  });
});

$(document).ready(function() {
  $(".js-blog-banner").slice(0, 4).show();
  let bannerCount = $('.js-blog-banner-count .js-blog-banner').length - 4;
  $(".js-more-banner").on('click', function(e) {
    e.preventDefault();
    bannerCount -= 2;
    $("div.js-blog-banner:hidden").slice(0, 2).slideDown();
    $(".js-more-banner").html(`DAHA FAZLA (${bannerCount})`);
    if ($("div.js-blog-banner:hidden").length == 0) {
      $(".js-more-banner").fadeOut("slow");
    }
  })
})