export default class ProductVariants {
  onChange = () => {};

  constructor($container) {
    this.$container = $container;
    
    this.$container.find('.js-variant')
      .click(this.onVariantClick.bind(this));

    this.$container.find('.js-variants-select')
      .change(this.onVariantChange.bind(this));
  }

  onVariantChange() {
    this.fetchVariants(); 
  }

  onVariantClick(e) {
    e.preventDefault();

    const $el = $(e.currentTarget);
    if ($el.hasClass('selected') || $el.hasClass('disabled')) {
      return;
    }

    if ($el.hasClass('js-disabled-color') && $el.data('internete-ozel') == false) {
      return;
    }

    $el.siblings().removeClass('selected');
    $el.addClass('selected');

    if ($el.hasClass('disabled-color') && $el.hasClass('js-disabled-color') && $el.data('internete-ozel') == true) {
      this.$container.find('.js-add-to-cart-button').hide();
      const dataPk = $el.data('pk');
      this.$container.find('.js-product-get-notice').removeClass('d-none')
      this.$container.find('.js-product-get-notice').attr('data-product', dataPk);
      this.$container.find('.product__variant--title').remove()
    }
    else{
      this.fetchVariants();
    }

    return false;
  }

  fetchVariants() {
    const selectedVariants = new Map();
    this.$container
      .find('.js-variant.selected')
      .each((_, el) => {
        const $el = $(el);
        const key = $el.parents('.js-variants-container').data('variant-key');
        const value = $el.data('variant-value');
        selectedVariants.set(key, value);
      });

    this.$container
      .find('.js-variants-select')
      .each((_, el) => {
        const $el = $(el);
        const key = $el.attr('name');
        const value = $el.val();
        selectedVariants.set(key, value);
      });

    let params = [];

    for (let [key, value] of selectedVariants) {
      params.push(
        $.param({[key]: value})
      );
    }

    this.onChange(params);
  }
}