import LoginForm from '../auth/forms/loginForm';
import RegisterForm from '../auth/forms/registerForm';
import GuestForm from '../auth/forms/guestForm';

class CheckoutAuth {
  constructor() {
    this.initLoginForm();
    this.initRegisterForm();
    this.initGuestForm();
    this.otpLogin();
    this.otpLoginMobile();
    this.checkoutLogin();
    this.phoneNumber = $(document.querySelector('.js-otp-phone'));
    this.phoneNumberMobile = $(document.querySelector('.js-otp-phone-mobile'));
    
    IMask(this.phoneNumber[0], {
      mask: '\\0\\ {(500)} 000 00 00',
      placeholderChar: '_',
      lazy: false
    });
    
    IMask(this.phoneNumberMobile[0], {
      mask: '\\0\\ {(500)} 000 00 00',
      placeholderChar: '_',
      lazy: false
    });
    
  }

  checkoutLogin() {
    window.dataLayer.push({
      event: "checkout_login_view"
    })
  }

  initLoginForm() {
    const loginFormMobile = new LoginForm($(document.getElementById('LoginFormMobile')));
    loginFormMobile.onSuccess = () => {
      window.location.reload();
    };

    const loginFormDesktop = new LoginForm($(document.getElementById('LoginFormDesktop')));
    loginFormDesktop.onSuccess = () => {
      window.location.reload();
    };
  }

  initRegisterForm() {
    new RegisterForm($(document.getElementById('RegisterFormMobile')), true, '/orders/checkout/');
    new RegisterForm($(document.getElementById('RegisterFormDesktop')), true, '/orders/checkout/');
  }

  initGuestForm() {
    const guestFormMobile = new GuestForm($(document.getElementById('GuestFormMobile')));
    const guestFormDesktop = new GuestForm($(document.getElementById('GuestFormDesktop'))); 
  }

  otpLogin() {
    $('.js-otp-form').on('submit', function (e) {
      e.preventDefault();
      const form = $(document.querySelector('.js-otp-form'));
      const code = $(document.querySelector('.js-code-form'));
      const phoneNumber = $(document.querySelector('.js-otp-phone')).val();
      const phoneVal = phoneNumber.replaceAll(' ', '').replace('(', '').replace(')', '');
      
      $.ajax({
        url: '/users/otp-login/',
        method: 'POST',
        data: { phone: phoneVal, code: '' },
        success: function () {
          form.addClass('d-none');
          code.removeClass('d-none');
        },
        error: function (errors) {
          console.log(errors);
        }
      })
    })

    $('.js-code-form').on('submit', function (e) {
      e.preventDefault();
      const phoneNumber = $(document.querySelector('.js-otp-phone')).val();
      const phoneVal = phoneNumber.replaceAll(' ', '').replace('(', '').replace(')', '');
      const codeVal = $('.js-otp-code').val();
      
      $.ajax({
        url: '/users/otp-login/',
        method: 'POST',
        data: { phone: phoneVal, code: codeVal },
        success: function () {
          document.location.href = '/orders/checkout/';
        },
        error: function (errors) {
          $('.js-error-otp-code').html(errors.responseJSON.non_field_errors);
        }
      })
    })

    $('.js-error-code__resend').on('click', function (e) {
      e.preventDefault();
      const phoneNumber = $(document.querySelector('.js-otp-phone')).val();
      const phoneVal = phoneNumber.replaceAll(' ', '').replace('(', '').replace(')', '');

      $.ajax({
        url: '/users/otp-login/',
        method: 'POST',
        data: { phone: phoneVal, code:'' },
        success: function (response) {
          console.log(response)
        },
        error: function (errors) {
          console.log(errors);
        }
      })
    })
  }

  otpLoginMobile() {
    $('.js-otp-form-mobile').on('submit', function (e) {
      e.preventDefault();
      const form = $(document.querySelector('.js-otp-form-mobile'));
      const code = $(document.querySelector('.js-code-form-mobile'));
      const phoneNumber = $(document.querySelector('.js-otp-phone-mobile')).val();
      const phoneVal = phoneNumber.replaceAll(' ', '').replace('(', '').replace(')', '');
      
      $.ajax({
        url: '/users/otp-login/',
        method: 'POST',
        data: { phone: phoneVal, code: '' },
        success: function () {
          form.addClass('d-none');
          code.removeClass('d-none');
        },
        error: function (errors) {
          console.log(errors);
        }
      })
    })

    $('.js-code-form-mobile').on('submit', function (e) {
      e.preventDefault();
      const phoneNumber = $(document.querySelector('.js-otp-phone-mobile')).val();
      const phoneVal = phoneNumber.replaceAll(' ', '').replace('(', '').replace(')', '');
      const codeVal = $('.js-otp-code-mobile').val();
      
      $.ajax({
        url: '/users/otp-login/',
        method: 'POST',
        data: { phone: phoneVal, code: codeVal },
        success: function () {
          document.location.href = '/orders/checkout/';
        },
        error: function (errors) {
          $('.js-error-otp-code-mobile').html(errors.responseJSON.non_field_errors);
        }
      })
    })

    $('.js-error-code__resend-mobile').on('click', function (e) {
      e.preventDefault();
      const phoneNumber = $(document.querySelector('.js-otp-phone-mobile')).val();
      const phoneVal = phoneNumber.replaceAll(' ', '').replace('(', '').replace(')', '');

      $.ajax({
        url: '/users/otp-login/',
        method: 'POST',
        data: { phone: phoneVal, code:'' },
        success: function (response) {
          console.log(response)
        },
        error: function (errors) {
          console.log(errors);
        }
      })
    })
  }
}

export default CheckoutAuth;