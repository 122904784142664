import Component from "../../components/dom/component";
import List from "../../components/dom/list";
import Template from "../../components/dom/template";
import { basketSelector, discountsSelector } from "shop-packages/redux/basket/selectors";
import observe from "shop-packages/redux/connectSelector";

export class BasketSummary extends Component {
  static selector = ".js-basket-summary";

  constructor(_$) {
    super(_$, BasketSummary.selector);
    this.$totalQuantity = this._$.find(".js-basket-summary-total-quantity");
    this.$totalProductAmount = this._$.find(".js-basket-summary-total-product-amount");
    this.$totalAmount = this._$.find(".js-basket-summary-total-amount");
    this.$totalDiscount = this._$.find(".js-basket-summary-receipt-item-discount");
    this.$totalDiscountWrapper = this._$.find(".js-basket-summary-total-discount");
    
    this.basketSummaryReceiptList = new SummaryReceiptList(_$);

    observe(basketSelector).subscribe((basket) => {
      this.$totalQuantity.html(basket.total_quantity);
      this.$totalProductAmount.html(basket.total_product_amount);
      this.$totalAmount.html(basket.total_amount);

      if(typeof basket.total_discount_amount != "undefined" && parseFloat(basket.total_discount_amount) > 0) {
        this.$totalDiscount.html(basket.total_discount_amount);
        this.$totalDiscountWrapper.removeClass('d-none');
      }else {
        this.$totalDiscountWrapper.addClass('d-none');
      }
      
    });
  }
}

class SummaryReceiptList extends List {
  static selector = ".js-basket-summary";

  constructor(_$,ViewHolder) {
    super(_$, SummaryReceiptList.selector,ViewHolder);

    this.$campaignDescription = this._$.find(".js-campaign-description");
    this.$campaignDiscount = this._$.find(".js-campaign-discount");

    observe(discountsSelector).subscribe(discounts => {
      let _discounts = this.groupBy(discounts, 'description');

      this.setProps({ list: discounts || [] }).mount().render()
      
      if (_discounts && this.$campaignDescription.length && this.$campaignDiscount.length) {
        Object.keys(_discounts).forEach((key, i) => {
          let totalSum = _discounts[key].reduce((sum, item) => {
            return sum + parseFloat(item.discount);
          }, 0);
          
          let totalDiscountAmount = totalSum.toFixed(2);
          
          this.$campaignDescription[i].innerHTML = key;
          this.$campaignDiscount[i].innerHTML = `₺ ${totalDiscountAmount}`;
        });
      } else {
        this.$campaignDescription.text('');
        this.$campaignDiscount.text('');
      }
    });
  }

  groupBy(arr, key) {
    return arr?.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
}