import store from 'shop-packages/redux/store';
import observe from 'shop-packages/redux/connectSelector';
import { setTab } from 'shop-packages/redux/checkout/reducer';
import {
  selectTab,
  selectAddressAndShippingSet,
} from 'shop-packages/redux/checkout/selectors';
import { clearErrors } from 'shop-packages/redux/checkout/reducer';
import { completeCheckout } from 'shop-packages/redux/checkout/actions';
import {pushEvent} from "shop-packages/analytics";
import * as actionTypes  from "shop-packages/analytics/action-types";
import {
  selectCurrentAddress,
} from 'shop-packages/redux/checkout/selectors';

import AddressTab from './addressTab';
import PaymentTab from './paymentTab';

export default class Tabs {
  currentTab = 'address';

  constructor() {
    this.container = $(document.getElementById('CheckoutPage'));
    this.isDistrictAddress = false;
    this.isContinuedPayment = false;
    this.$tabs = this.container.find('.js-tab');
    this.$addressTab = this.$tabs.filter('[data-tab="address"]');
    this.$paymentTab = this.$tabs.filter('[data-tab="payment"]');
    
    this.$tabContents = this.container.find('.js-tab-content');
    this.$addressTabContent = this.$tabContents.filter('[data-tab="address"]');
    this.$paymentTabContent = this.$tabContents.filter('[data-tab="payment"]');
    
    this.$proceedButton = $(document.getElementById('CheckoutProceedButton'));
    observe(selectCurrentAddress).subscribe(this.onSelectedAddressUpdate.bind(this)),
    
    this.$tabs.click(this.onTabClick.bind(this));
    this.$proceedButton.click(this.onProceedClick.bind(this));

    observe(selectTab).subscribe(this.onTabChange.bind(this));
    observe(selectAddressAndShippingSet).subscribe(this.onAddressAndShippingSet.bind(this));


  }

  addressControllerInit() {
    this.addressController = new AddressTab();
  }

  paymentControllerInit() {
    this.paymentController = new PaymentTab();
  }

  unmountController(tab) {
    if (this[`${tab}Controller`]) {
      this[`${tab}Controller`].unmount();
      this[`${tab}Controller`] = undefined;
    }
  }

  onTabChange(tab) {
    store.dispatch(clearErrors());
    this.currentTab = tab;

    $('html, body').scrollTop(0);
    this.$tabs.removeClass('active');
    this[`$${this.currentTab}Tab`].addClass('active');
    this.$tabContents.removeClass('d-flex');
    this[`$${this.currentTab}TabContent`].addClass('d-flex');

    if (this.currentTab === 'address') {
      this.unmountController('payment');
    }

    if (this.currentTab === 'payment') {
      this.unmountController('address');
    }

    this[`${this.currentTab}ControllerInit`]();
  }

  pushAnalyticsData(activeTab) {
    $('.analytics-data').each(function() {
      const analyticsData = JSON.parse($(this).text());
      if (!analyticsData.type) return;

      if (analyticsData.type === actionTypes.checkoutStepViewed){
        if ( activeTab === 'address'){
          pushEvent(analyticsData);
          return;
        }

        if (activeTab === 'payment'){
          analyticsData.type = actionTypes.checkoutStepViewedPayment;
          pushEvent(analyticsData);
        }
      }
    });
  }


  onSelectedAddressUpdate(selectedAddress) {
    if (selectedAddress && selectedAddress.district.pk) {
      this.isDistrictAddress = true
    } else {
      this.isDistrictAddress = false      
    }
  }

  onAddressAndShippingSet(isSet) {
    this.$proceedButton.prop('disabled', !isSet);
    this.$paymentTab.prop('disabled', !isSet);
  }

  isShippingSelected() {
    const isCheckShipping = [];

    document.querySelectorAll('.js-shipping-input').forEach((inp) => {
      if (inp.checked) {
        isCheckShipping.push(true);
      } else {
        isCheckShipping.push(false);
      }
    });

    if (isCheckShipping.includes(true)) {
      $('.checkout-shipping-error').addClass('d-none');
      return true;
    } else {
      $('.checkout-shipping-error').removeClass('d-none');
      return false;
    }
  }

  onTabClick(e) {
    const target = $(e.currentTarget);

    if (!this.isShippingSelected()) {
      return false;
    }

    if ((!this.isDistrictAddress && target.data('tab') === 'payment') && (!$('.click-collect').hasClass('-active'))) {
      $('.js-address-input:checked').closest('.js-address-box').find('.js-update-address').trigger('click');
      $('.checkout-district-error').removeClass('d-none')
      return false;
    } else {
      $('.checkout-district-error').addClass('d-none')
    }

    if (!target.hasClass('active')) {
      store.dispatch(setTab(target.data('tab')));
    }

    if (target.data('tab') === 'address') {
      this.pushAnalyticsData('address');
      localStorage.setItem('isContinuedPayment', false)

    }

    if (target.data('tab') === 'payment') {
      this.pushAnalyticsData('payment');
      localStorage.setItem('isContinuedPayment', true)
    }
  }

  onProceedClick() {
    if (!this.isShippingSelected()) {
      return false;
    }

    if (!this.isDistrictAddress && !$('.click-collect').hasClass('-active')) {
      $('.js-address-input:checked').closest('.js-address-box').find('.js-update-address').trigger('click');
      $('.checkout-district-error').removeClass('d-none')
      return false;
    } else {
      $('.checkout-district-error').addClass('d-none')
    }

    store.dispatch(setTab('payment'));
    this.pushAnalyticsData('payment');
    localStorage.setItem('isContinuedPayment', true)


  }
};
