import qs from 'query-string';
import { ApiClient } from 'shop-packages';

import ActionForm from '../../components/form-action';
import * as actionTypes from "shop-packages/analytics/action-types";
import {pushEvent} from "shop-packages/analytics";
import IMask from 'imask';

class RegisterForm {
  constructor($form,redirect, redirectUrl) {
    const form = ActionForm($form);
    this.redirect = redirect;
    this.redirectUrl = redirectUrl;
    const $phoneNumber = $form.find('.js-phone-number'); 

    $('.js-register-sms-resend').click(function() {
      form.action = ApiClient.user.registerUser;
      form.beforeAction = () => {
        form.actionParams = [
          $form.serialize(),
        ];
      };
      
      $('.js-sms-code').attr('name','');
      $form.submit();
    })

    $('.js-send-verification-code ').click(function(){
      const smsCodeInput = $('.js-sms-code-trigger');
      const smsCodeError = $('.js-error-code');

      if ($('.js-sms-code-trigger').val().length > 4) {
        smsCodeError.text('');
        smsCodeInput.removeClass('border-danger');

        form.action = ApiClient.user.registerUser;
        form.beforeAction = () => {
          form.actionParams = [
            $form.serialize(),
          ];
        };

        let $activeForm;

        if($form.length > 0){
          $activeForm = $form;
        }else if (window.innerWidth < 768){
          $activeForm = $('#RegisterFormMobile');
        }else{
          $activeForm = $('#RegisterFormDesktop');
        }
        $activeForm.submit();
      } else {
        smsCodeInput.focus();
        smsCodeInput.addClass('border-danger');
        smsCodeError.text('Lütfen doğrulama kodunu kontrol ediniz!');
      }
    });

    $phoneNumber[0] && IMask($phoneNumber[0], {
      mask: '\\0\\ {(500)} 000 00 00',
      placeholderChar: '_',
      lazy: false
    });
  

    $(document).on('keyup', '.js-phone-number', function(){
      var phoneLength = $(this).val().replace(/[-()_/n/ /]/g,'');
      $('.js-phone-number2').val(phoneLength)
    });

    $(document).on('keyup', '.js-sms-code-trigger', function(){
      $('.js-sms-code').val($(this).val())
    });

    form.action = ApiClient.user.registerUser;
    form.beforeAction = () => {
      form.actionParams = [
        $form.serialize(),
      ];
    };

    form.onSuccess = (response) => {
      if(response.xhr.status == 202){
        this.smsModal()
      }else if(response.xhr.status == 201) {
        localStorage.setItem('registerAnalyticsData', JSON.stringify({ method:'email' }));
        const next = qs.parse(location.search.replace('?', '')).next;
        if (next) {
          document.location.href = next;
        } else{
          document.location.href = this.redirectUrl || document.location.origin;
        }
      }

      this.onSuccess();
    };

    form.onError = err => {
      if(err.status == 406) {
        $(document).find('.js-sms-code-error').text('Girdiğiniz doğrulama kodu hatalıdır.');
        this.smsModal();
      }else {
        form.showErrors(err);
      }
    };

    this.$passwordInput = $form.find('.js-password-input');
    this.$showPassword = $form.find('.js-show-password');
    this.$hidePassword = $form.find('.js-hide-password');

    this.$showPassword.click(() => {
      this.$passwordInput.attr('type', 'text');
      this.$showPassword.hide();
      this.$hidePassword.show();
    });

    this.$hidePassword.click(() => {
      this.$passwordInput.attr('type', 'password');
      this.$hidePassword.hide();
      this.$showPassword.show();
    });

    this.timerCounter = 0;
  }

  onSuccess() {
    pushEvent({type : actionTypes.signUpAction });
  }

  smsTimer() {
    if( this.timerCounter == 0 ) {
      const countdown =  document.querySelector(".js-register-timer");
      const resendBtn = document.querySelector('.js-register-sms-resend');
      let seconds = 60;
      const registerTimer = window.setInterval(() => {
        if(seconds > 0) {
          seconds--;
          if(seconds > 9 ) {
            countdown.innerHTML = `0 : ${seconds}`;
          } else {
            countdown.innerHTML = `0 : 0${seconds}`;
          }
          resendBtn.style.color = '';
          resendBtn.style.pointerEvents = 'none';
          this.timerCounter = 1;
        } else {
          countdown.innerHTML = '';
          resendBtn.style.color = 'black';
          resendBtn.style.pointerEvents = 'auto';
          clearInterval(registerTimer);
          this.timerCounter = 0;
        }
      }, 1000);
    }

    resendBtn.addEventListener('click', () => {
      this.smsTimer();
    })
  }

  smsModal() {
    const mgContentContainer = $('#smscommunicationsModal');

    $('.js-sms-code').attr('name','code');

    mgContentContainer
      .modal('show')
      .css('display','block')

    this.smsTimer();
  }
}

export default RegisterForm;