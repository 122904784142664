import Component from "../components/dom/component";
import { BasketSummary } from "./summary";
import BasketListItem from "./basket-list-item";
import BasketList from "./basket-list";
import { basketPendingSelector, basketReducer } from "shop-packages/redux/basket/selectors";
import observe from "shop-packages/redux/connectSelector";
import VoucherForm from "./voucher-form";
import BasketGiftPackage from "./basket-gift-package";
import { getAnalyticsProductFromLocalStorage } from '../utils';

export default class Basket extends Component {
  static selector = ".js-basket";

  constructor() {
    super($(Basket.selector));
    this.basketList = new BasketList(this._$).setViewHolder(BasketListItem);
    this.basketSummary = new BasketSummary(this._$);
    this.basketVoucherForm = new VoucherForm(this._$);
    new BasketGiftPackage();
    this.$totalQuantity = this._$.find('.js-basket-total-quantity');
    this.$emptyBasket = this._$.find('.js-empty-basket');
    this.$basketContent = this._$.find('.js-basket-content');
    this.$loader = this._$.find('.js-basket-loader');
    this.$upsellMessage = this._$.find('.js-basket-upsell-message');
    this.$continueShoppingLink = this._$.find('.js-continue-shopping-link');
    this.$upsellMessageContent = this._$.find('.js-basket-upsell-message-content');
    this.$basketSubmitButton = this._$.find('.js-basket-continue-checkout-button');

    observe(basketReducer).subscribe(({ basket, pending }) => {
      if (!basket || pending) return;
      const { basketitem_set } = basket;
      let hasBasketItem = basketitem_set && basketitem_set.length > 0;

      this.$basketContent.attr('hidden', !hasBasketItem);
      this.$emptyBasket.attr('hidden', hasBasketItem);

      if (hasBasketItem) {
        this.$totalQuantity.html(basket.total_quantity);
      }

      if(basket.upsell_details && basket.upsell_details.length) {
        this.$upsellMessage?.html(basket.upsell_details[0].message);
        this.$continueShoppingLink[0].style.top = '45px';  
        this.$upsellMessageContent?.removeClass('hide-upsell-message');
      }
      
      if (basket.upsell_details.length === 0) {
        this.$upsellMessageContent?.addClass('hide-upsell-message');
        this.$upsellMessage?.html('');
      }

      const basketAnalytics = basketitem_set.map((item, index) => {
        const itemDiscountAmount = (+item.discount_amount / item.quantity).toFixed(2);
        const discountRate = ((itemDiscountAmount / +item.retail_price) * 100).toFixed(2);
        const discountStatus = +item.discount_amount > 0 ? 'İndirimli' : 'İndirimsiz';
        const filterMaterial = item.product.attributes_kwargs?.integration_karisim?.label?.replaceAll('%', '')?.replaceAll('/', '')?.replaceAll('-', '');
        const lastPrice = (+item.retail_price - (itemDiscountAmount)).toFixed(2);
        const analyticProduct = getAnalyticsProductFromLocalStorage(item.product.base_code);
      
        return {
          item_id: item.product.base_code,
          item_name: this.capitalizeWords(item.product.name),
          currency: 'TRY',
          discount: +itemDiscountAmount,
          index: index + 1,
          item_brand: this.capitalizeWords(item.product.attributes_kwargs.integration_marka.label),
          item_category: this.capitalizeWords(item.product.attributes.integration_ust_kategori),
          item_category2: this.capitalizeWords(item.product.attributes.filterable_kategori),
          item_category3: this.capitalizeWords(item.product.attributes.integration_alt_marka),
          item_list_id: analyticProduct?.listIdx || '',
          item_list_name: this.capitalizeWords(analyticProduct?.listName) || this.capitalizeWords(item.product.attributes.integration_alt_kategori),
          item_list_location:"Basket",
          item_variant: this.capitalizeWords(item.product.attributes_kwargs.integration_renk.label),
          item_sku: item.product.sku,
          item_season: this.capitalizeWords(item.product.attributes.integration_season),
          item_material: this.capitalizeWords(filterMaterial),
          item_discount_status: discountStatus,
          item_size: item.product.attributes.integration_beden1,
          price: +item.price,
          quantity: item.quantity,
          item_last_price: +lastPrice,
          stock_status: 'in_stock',
          discount_rate: +discountRate,
          coupon: basket?.voucher_code || "",
          new_item_group_id: item.product.attributes.integration_urunkodu || ""
        }
      })

      window.dataLayer.push({ ecommerce: null }); 
      window.dataLayer.push({
        'event': 'view_cart',
        'ecommerce': {
          'currency': 'TRY',
          'value': +basket.total_amount,
          'items': basketAnalytics
        }
      })

      const pushBeginCheckoutEvent = () => {
        localStorage.basketAnalytics = JSON.stringify(basketAnalytics);
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          'event': 'begin_checkout',
          'ecommerce': {
            'currency': 'TRY',
            'value': +basket.total_amount,
            'items': basketAnalytics
          }
        });
      };

      this.$basketSubmitButton.off('click.pushBeginCheckoutEvent');
      this.$basketSubmitButton.on('click.pushBeginCheckoutEvent', pushBeginCheckoutEvent);
    });

    observe(basketPendingSelector).subscribe(pending => {
      this.$loader.css("display", pending ? 'block' : 'none');
    });

    this.basketDiv();
  }
  capitalizeWords(string) {
    if (!string) return "";
    return string.split(' ').map(word => 
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    ).join(' ');
  }
  basketDiv(){
    this._$.css('height', 'auto !important');
  }
  
}
