import {
  ApiClient
} from 'shop-packages';
import store from 'shop-packages/redux/store';
import {
  errorBasket,
  setBasket,
} from 'shop-packages/redux/basket/reducer';
import ActionButton from '../button-action';

const toggleGiftNoteButton = ($element, isActive, defaultGiftNoteMessage) => {
  const button = new ActionButton($element);
  button.action = ApiClient.basket.setGiftNote;
  button.beforeAction = () => {
    button.actionParams = [
      $element.data('basket-item'),
      isActive ? '' : defaultGiftNoteMessage
    ];
  };

  button.onSuccess = response => {
    store.dispatch(setBasket(response.data));
    button.success && button.success(response);
  };

  button.onError = err => {
    store.dispatch(errorBasket({
      pk: $element.data('pk'),
      error: err.responseJSON,
    }));
    button.error && button.error(err);
  };

  return button;
};

export default toggleGiftNoteButton;
