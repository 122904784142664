import qs from 'query-string';
import { ApiClient } from 'shop-packages';

import ActionForm from '../../components/form-action';
import * as actionTypes from "shop-packages/analytics/action-types";
import {pushEvent} from "shop-packages/analytics";

class LoginForm {
  constructor($form) {
    const $email = $form.find('input[name="email"]');
    const $password = $form.find('input[name="password"]');
    const form = ActionForm($form);
    
    form.action = ApiClient.user.loginUser;
    form.beforeAction = () => {
      form.actionParams = [
        $email.val(),
        $password.val(),
      ];
    };

    form.onSuccess = () => {
      this.onSuccess();
    };

    form.onError = err => {
      form.showErrors(err);
    }

    this.checkAkifastContracts();
  }

  onSuccess() {
    localStorage.setItem('loginAnalyticsData', JSON.stringify({ method:'email' }));
    const next = qs.parse(location.search).next;
    pushEvent({type : actionTypes.loginAction });

    if (next) {
      document.location.href = next;
      return;
    }

    document.location.href = location.origin;
  }

  checkAkifastContracts() {
    const akifastInputsDesktop = document.querySelectorAll('#loginFormTabDesktop .js-akifast-input');
    const akifastInputsMobile = document.querySelectorAll('#loginFormTab .js-akifast-input');
    const akifastInputs = document.querySelectorAll('.js-akifast-input');
    
    const akifastLoginDesktop = document.querySelector('#loginFormTabDesktop .js-akifast-wrapper');
    const akifastLoginMobile = document.querySelector('#loginFormTab .js-akifast-wrapper');
    const akifastLogin = document.querySelector('.js-akifast-wrapper');
    function checkInputsAndToggleClass(inputs, loginContainer) {
      inputs.forEach((input) => {
        input.addEventListener('change', () => {
          const areAllChecked = Array.from(inputs).every((akifastInput) => akifastInput.checked);
          if (areAllChecked) {
            loginContainer.classList.remove('disabled');
          } else {
            loginContainer.classList.add('disabled');
          }
        });
      });
    }
    
    checkInputsAndToggleClass(akifastInputsDesktop, akifastLoginDesktop);
    checkInputsAndToggleClass(akifastInputsMobile, akifastLoginMobile);
    checkInputsAndToggleClass(akifastInputs, akifastLogin);
  }
}

export default LoginForm;