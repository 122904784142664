import { ApiClient } from 'shop-packages';

import ActionForm, { displayErrors } from '../../components/form-action';

class GuestForm {
  constructor($form) {
    this.$form = $form;
    const $email = $form.find('input[name="user_email"]');

    const form = ActionForm($form);

    form.action = ApiClient.user.guestLogin;
    form.beforeAction = () => {
      form.actionParams = [
        $email.val(),
      ];
    };

    form.onSuccess = (response) => {
      this.onSuccess(response);
    };

    form.onError = err => {
      this.onError(err);
    }
  }

  onSuccess(response) {
    if (response.data && response.data.errors) {
      displayErrors(response.data.errors, this.$form);
      return;
    }

    window.location.reload();
  }

  onError() {

  }
}

export default GuestForm;