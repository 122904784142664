const isAndroid = /Android/i.test(navigator.userAgent);
const isIos = /iPhone|iPad|iPod/i.test(navigator.userAgent);

import $ from 'jquery';

export default function() {
  getLocalStorageRedirectApp();
  setLocalStorageRedirectApp();
}

function getLocalStorageRedirectApp(){
  const redirectAppStatu = localStorage.getItem('redirectAppStatu');

  if(!redirectAppStatu & (isAndroid || isIos)){
    $('.js-redirect-popup').addClass('active');
    $('.js-index-wrapper').addClass('redirect-popup-active');
    redirectUrl();
  }
}

function setLocalStorageRedirectApp(){
  $(document).on('click','.js-redirect-app-close', function(){
    localStorage.setItem('redirectAppStatu', true);
    $('.js-redirect-popup').removeClass('active');
    $('.js-index-wrapper').removeClass('redirect-popup-active');
  })
}

function redirectUrl(){
  let redirectUrl = '';

  if(isAndroid){
    redirectUrl = ' https://play.google.com/store/apps/details?id=com.akinon.dsdamat';
  }else if(isIos){
    redirectUrl = ' https://apps.apple.com/tr/app/ds-damat/id1536544856?l=tr';
  }

  $('.js-redirect-url').attr('href',redirectUrl);
}