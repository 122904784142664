import List from "../../../../components/dom/list";
import { basketListSelector } from "shop-packages/redux/basket/selectors";
import observe from "shop-packages/redux/connectSelector";

export default class MiniBasketList extends List {
  static selector = ".js-mini-basket-list-container";

  constructor(_$) {
    super(_$, MiniBasketList.selector);
    observe(basketListSelector).subscribe(({ list }) => {
      this.setProps({ list }).mount().render()
    });
  }
}
