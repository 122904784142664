import 'jquery-validation';

import ActionElement from '../element-action';
import ActionButton from '../button-action';

export const displayErrors = (err, $form) => {
  for (const input in err) {
    const label = $(document.getElementById(`${input}-error`));
    const labelClassSelector = $(`.${input}-error`);

    if ( labelClassSelector.length){
      $(`.${input}-error`)
        .text(err[input])
        .show();
      return;
    }

    if (label.length) {
      $(document.getElementById(`${input}-error`))
        .text(err[input])
        .show();

      return;
    }

    $(`<label />`, {
      "class": `error`,
      id: `${input}-error`,
      for: input,
      text: err[input],
    }).insertAfter($form.find(`input[name="${input}"]`));
  }
}

const ActionForm = $form => {
  const submitButton = new ActionButton($form.find('button[type=submit]'));
  const form = new ActionElement($form);

  $form.validate({
    submitHandler: async (_, event) => {
      event.preventDefault();

      $(document.getElementById('non_field_errors-error'))
        .text('')
        .hide();

      submitButton.disabled = true;
      submitButton.spin = true;

      await form.runAction();

      submitButton.disabled = false;
      submitButton.spin = false;

      return false;
    },
    errorPlacement: (error, element) => {
      if (['checkbox', 'radio'].indexOf(element.attr('type')) + 1) {
        error.insertAfter(element.parent());
      } else {
        error.insertAfter(element);
      }
    },
  });

  form.showErrors = err => {
    if (err && err.responseJSON) {
      displayErrors(err.responseJSON, $form);
    }
  }

  return form;
};

export default ActionForm;
