import 'lightslider'


const sliderWrappers = $('.js-flatpage-slider');

sliderWrappers.each((_, slider) => {
  const $slider = $(slider);
  const length = $slider.attr('data-length');

  if (length > 3) {
    $slider.lightSlider({
      item: 4,
      loop:false,
      autoWidth:false,
      enableDrag: true,
      pager:false,
      slideMargin:40,
      controls:false,
      prevHtml: '<i class="icon-chevron-left"></i>',
      nextHtml: '<i class="icon-chevron-right"></i>',
      responsive: [{
        breakpoint: 1024,
        settings: {
          item: 3,
          slideMargin: 10
        }
      }]
    })
  } else {
    const produtItems = $slider.find('.js-collection-product');
    produtItems.each((_, item) => {
      $(item).addClass('non-slider-item');
    })
    $slider.each((_, item) => {
      $(item).addClass('non-slider-item-two');
    })
  }
})