import {
  ApiClient
} from 'shop-packages';

export class Benefits {
  constructor() {
    this.items = [];
  }

  add(benefit) {
    this.items.push(benefit);
  }

  toBasketItems() {
    let data = [];

    this.items.forEach(product => {
      product.items.forEach((benefit) => {
        data.push({
          product: benefit.product.pk,
          quantity: 1
        });
      });
    });

    return data;
  }
}

export class BenefitItem {
  

  constructor($el) {
    this.$el = $el;
    this.items = [];

    this.getItems().then(items => {
      this.items = items;
    });
  }

  getItems() {
    return new Promise((resolve, reject) => {
      const pk = this.$el.dataset.pk;
      const basketOffersUrl = `/basket-offers/${ pk }`;
      ApiClient
        .request('GET', basketOffersUrl)
        .then((resp) => {
          resolve(resp.data.benefit_items);
        }).catch((err) => {
          reject(err);
        });
    });
  }
}
